import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/wallpapers/wallpaper1.png'
import _imports_1 from '@/assets/images/wallpapers/wallpaper2.png'
import _imports_2 from '@/assets/images/wallpapers/wallpaper3.png'


const _hoisted_1 = { class: "in-app" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "main-content" }
const _hoisted_5 = {
  class: "title",
  "data-test": "ia.preview.configbundleconfirm.title"
}
const _hoisted_6 = {
  key: 0,
  class: "message",
  "data-test": "ia.preview.configbundleconfirm.message"
}
const _hoisted_7 = {
  key: 0,
  class: "sub-container"
}
const _hoisted_8 = {
  key: 0,
  class: "subtitle",
  "data-test": "ia.preview.configbundleconfirm.subtitle"
}
const _hoisted_9 = {
  key: 1,
  class: "submessage",
  "data-test": "ia.preview.configbundleconfirm.submessage"
}
const _hoisted_10 = { class: "buttons-container" }
const _hoisted_11 = { "data-test": "ia.preview.configbundleconfirm.cancelText" }
const _hoisted_12 = {
  key: 0,
  class: "confirm-button",
  "data-test": "ia.preview.configbundleconfirm.confirmtext"
}

import { ImageMetadata } from "@/api/image/typedefs";
import inAppPreviewBackgroundUrl from "@/assets/images/inapp-preview-background.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewConfigBundleConfirm',
  props: {
    title: {},
    message: {},
    subtitle: {},
    submessage: {},
    avatarImage: {},
    confirmText: {},
    cancelText: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.avatarImage !== null)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "avatar",
              src: _ctx.avatarImage.url
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
            (_ctx.message !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.message), 1))
              : _createCommentVNode("", true)
          ]),
          (_ctx.subtitle !== null || _ctx.submessage !== null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.subtitle !== null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.subtitle), 1))
                  : _createCommentVNode("", true),
                (_ctx.submessage !== null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.submessage), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", {
          class: "wallpapers",
          "data-test": "ia.preview.configbundleconfirm.wallpapers"
        }, [
          _createElementVNode("img", { src: _imports_0 }),
          _createElementVNode("img", { src: _imports_1 }),
          _createElementVNode("img", { src: _imports_2 })
        ], -1)),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.cancelText), 1),
          (_ctx.confirmText)
            ? (_openBlock(), _createElementBlock("button", _hoisted_12, _toDisplayString(_ctx.confirmText), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})