import { computed, Ref } from "vue";

import { ShallowReadonlyRef, WithId } from "@/lib/typing";

import { expressionValidate } from "../expressionValidate";
import { SegmentConfig } from "./segmentConfig";
import { SegmentType } from "./segmentType";
import { ExprItem, ExprSegmentError } from "./typedefs";
import { ExprWithIds } from "./useExpressionInput";

export type ExprSegmentIdToErrors = Record<string, ExprSegmentError[]>;

export interface UseExpressionInputErrorsOptions {
  exprWithIds: ShallowReadonlyRef<ExprWithIds>;
  segmentTypeToConfigs: Ref<Map<SegmentType, SegmentConfig>>;
}

export function useExpressionInputErrors({
  exprWithIds,
  segmentTypeToConfigs,
}: UseExpressionInputErrorsOptions) {
  // Computed.
  const exprSegmentIdToErrors = computed<ExprSegmentIdToErrors | null>(() => {
    return expressionValidate(exprWithIds.value, segmentTypeToConfigs.value);
  });

  // Methods.
  const getExprSegmentErrors = (id: string): string[] =>
    exprSegmentIdToErrors?.value?.[id] ?? [];

  const hasExprErrors = (expr: WithId<ExprItem>[]) => {
    return expr.some((segment) => getExprSegmentErrors(segment.id).length > 0);
  };

  return {
    hasExprErrors,
    getExprSegmentErrors,
  };
}
