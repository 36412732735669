import { httpClient, throwIfBadResponse } from "@/lib/http";

export async function deleteInApp(
  id: number,
  productLineId: number,
  reason: string
): Promise<void> {
  const response = await httpClient.post(
    `/api/inapps/message/delete/${productLineId}`,
    {
      // NOTE: Casting to string as BE requires stringified integer.
      id: String(id),
      reason,
    }
  );

  await throwIfBadResponse(response);
}
