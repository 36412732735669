import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "operator" }

import { ExprOperatorType } from "./typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpressionOperator',
  props: {
    operator: {}
  },
  setup(__props: any) {

// TODO(PNS-1994): Implement select/edit when there are multiple options.
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(props.operator), 1))
}
}

})