import {
  SelectTypePerFrequency,
  SelectTypePerFrequencyLabels,
} from "./matching/perFrequency";

// TODO(PNS-1994): add other select types.
export type SelectTypeId = "per_frequency";

export const selectTypeIdToOptions: Record<SelectTypeId, string[]> = {
  per_frequency: Object.values(SelectTypePerFrequency),
};

export const selectTypeIdToLabels: Record<
  SelectTypeId,
  Record<string, string>
> = {
  per_frequency: SelectTypePerFrequencyLabels,
};
