import { httpClient, throwIfBadResponse } from "@/lib/http";

import { LottieMetadata, LottieResponse } from "./typedefs";

function convertLotties(data: unknown): LottieResponse {
  const lottiesData = data as {
    results: Array<LottieMetadata>;
    next: string;
  };
  return { next: lottiesData.next, results: lottiesData.results };
}

export async function fetchLotties(
  query: string,
  page: number,
  perPage: number
): Promise<LottieResponse> {
  const queryParams = {
    q: query,
    per_page: String(perPage),
    page: String(page),
  };

  const response = await httpClient.get(
    "/api/lottie-list?" + new URLSearchParams(queryParams).toString()
  );

  await throwIfBadResponse(response);

  const result = await response.json();
  return convertLotties(result);
}
