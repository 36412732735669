import { computed, ref, watch } from "vue";
import { RouteLocationRaw } from "vue-router";

import { InAppType } from "@/api/inapps";
import {
  isExistingInApp,
  NewOrExistingInApp,
} from "@/api/inapps/typedefs/inApp";
import { getInAppType, InvalidInAppTypeIdError } from "@/lib/inapps/inAppType";
import {
  getProductLine,
  InvalidProductLineIdError,
  ProductLine,
} from "@/lib/productLine";
import { Composable, ReadonlyComputedRef, ReadonlyRef } from "@/lib/typing";

interface UseInAppRouteSyncReturn {
  errorMessage: ReadonlyComputedRef<string | null>;
  productLine: ReadonlyComputedRef<ProductLine | null>;
}

interface UseInAppRouteSyncOptions {
  productId: ReadonlyRef<number>;
  productLineId: ReadonlyRef<number>;
  inAppId: ReadonlyRef<number | null>;
  inAppTypeId: ReadonlyRef<number | null>;
  inApp: ReadonlyRef<NewOrExistingInApp>;
  changeInAppCallback: (inAppId: number, productLineId: number) => unknown;
  setNewInAppCallback: (inAppType: InAppType) => unknown;
  routeReplaceCallback: (to: RouteLocationRaw) => void;
}

export const useInAppRouteSync: Composable<
  UseInAppRouteSyncOptions,
  UseInAppRouteSyncReturn
> = (options) => {
  const errorMessage = ref<string | null>(null);
  const productLine = ref<ProductLine | null>(null);

  watch(
    [options.inAppId, options.inAppTypeId, options.productLineId],
    async () => {
      try {
        productLine.value = getProductLine(options.productLineId.value);
      } catch (err) {
        if (err instanceof InvalidProductLineIdError) {
          errorMessage.value = err.message;
          return;
        } else if (err instanceof Error) {
          errorMessage.value = err.message;
          throw err;
        } else {
          throw err;
        }
      }
      if (options.inAppId.value !== null) {
        // Case when inApp is open for edit.
        options.changeInAppCallback(
          options.inAppId.value,
          options.productLineId.value
        );
      } else if (options.inAppTypeId.value !== null) {
        let inAppType;
        try {
          inAppType = getInAppType(options.inAppTypeId.value);
        } catch (err: unknown) {
          if (err instanceof InvalidInAppTypeIdError) {
            errorMessage.value = err.message;
            return;
          } else if (err instanceof Error) {
            errorMessage.value = err.message;
            throw err;
          } else {
            throw err;
          }
        }

        // Case where a new inApp form is opened.
        options.setNewInAppCallback(inAppType);
      } else {
        errorMessage.value =
          "Cannot render InApp form. Component is missing inAppTypeId.";
      }
    },
    { immediate: true }
  );

  watch(
    () => options.inApp.value,
    (inApp) => {
      if (isExistingInApp(inApp) && options.inAppId.value === null) {
        options.routeReplaceCallback({
          name: "InApp",
          params: {
            inAppId: inApp.id,
            productLine: options.productLineId.value,
            product: options.productId.value,
          },
        });
      }
    }
  );

  return {
    errorMessage: computed(() => errorMessage.value),
    productLine: computed(() => productLine.value),
  };
};
