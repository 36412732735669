import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { toRefs, watch } from "vue";

import ExpressionInputAddSegment from "./ExpressionInputAddSegment.vue";
import ExpressionOperator from "./ExpressionOperator.vue";
import ExpressionSegment from "./ExpressionSegment.vue";
import { SegmentType } from "./segmentType";
import { Expr, ExprOperatorType } from "./typedefs";
import { ExprSegmentType } from "./typedefs";
import { useExpressionInput } from "./useExpressionInput";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpressionInput',
  props: {
    modelValue: {},
    label: {},
    availableSegmentTypes: {},
    availableOperators: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

function validateProps() {
  if (props.availableOperators.length > 0) return;
  console.warn("Need at least one item in operatorOptions to addExprItem.");
}

watch(
  () => props,
  () => validateProps(),
  { immediate: true }
);

const emit = __emit;

const {
  hasExprErrors,
  updateExprSegment,
  deleteExprSegment,
  getExprSegmentErrors,
  addExprSegment,
  getSegmentConfig,
  exprSplitByAndOperator,
  segmentConfigOptions,
} = useExpressionInput({
  ...toRefs(props),
  onModelValueUpdate: (expr: Expr) => emit("update:modelValue", expr),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(exprSplitByAndOperator), (orExpr, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: String(orExpr)
      }, [
        (index !== 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(ExpressionOperator, {
                operator: _unref(ExprOperatorType).AND
              }, null, 8, ["operator"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["expression-group", _unref(hasExprErrors)(orExpr) ? 'error' : ''])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orExpr, (exprItem) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: exprItem.id
            }, [
              (exprItem.type === _unref(ExprOperatorType).OR)
                ? (_openBlock(), _createBlock(ExpressionOperator, {
                    key: 0,
                    operator: _unref(ExprOperatorType).OR
                  }, null, 8, ["operator"]))
                : (exprItem.type === _unref(ExprSegmentType))
                  ? (_openBlock(), _createBlock(ExpressionSegment, {
                      key: 1,
                      "is-disabled": _ctx.isDisabled,
                      config: _unref(getSegmentConfig)(exprItem.segmentType),
                      segment: exprItem,
                      errors: _unref(getExprSegmentErrors)(exprItem.id),
                      onDelete: ($event: any) => (_unref(deleteExprSegment)(exprItem.id)),
                      onUpdate: ($event: any) => (_unref(updateExprSegment)(exprItem, $event))
                    }, null, 8, ["is-disabled", "config", "segment", "errors", "onDelete", "onUpdate"]))
                  : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ], 2)
      ], 64))
    }), 128)),
    _createVNode(ExpressionInputAddSegment, {
      label: _ctx.label,
      options: _unref(segmentConfigOptions),
      "is-disabled": _ctx.isDisabled,
      onAdd: _unref(addExprSegment)
    }, null, 8, ["label", "options", "is-disabled", "onAdd"])
  ], 64))
}
}

})