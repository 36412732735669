import { isValidMessageUrl } from "@/lib/url";

import { castString } from "../casts";

const PROTOCOL_HTTPS = "https:";

function isValidUrl(value: unknown): true | string {
  if (typeof value !== "string") {
    return "URL must be string";
  }

  try {
    new URL(value);
  } catch (error) {
    return "This value is not a valid URL";
  }

  return true;
}

export function isValidHttpsUrl(value: unknown): true | string {
  const errorsOrIsValid = isValidUrl(value);

  if (errorsOrIsValid !== true) {
    return errorsOrIsValid;
  }

  const url = new URL(castString(value));

  if (url.protocol !== PROTOCOL_HTTPS) {
    return "URL protocol must be https";
  }

  return true;
}

export function isValidActionUrl(value: string | null): true | string {
  if (typeof value === "string") {
    return isValidMessageUrl(value) || "URL format is invalid";
  }
  return true;
}
