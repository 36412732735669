import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/wallpapers/wallpaper1.png'
import _imports_1 from '@/assets/images/wallpapers/wallpaper2.png'
import _imports_2 from '@/assets/images/wallpapers/wallpaper3.png'


const _hoisted_1 = { class: "in-app" }
const _hoisted_2 = {
  class: "title",
  "data-test": "ia.preview.wallpapersheetbasic.title"
}
const _hoisted_3 = {
  key: 0,
  class: "text",
  "data-test": "ia.preview.wallpapersheetbasic.message"
}
const _hoisted_4 = { class: "buttons-container" }
const _hoisted_5 = {
  key: 0,
  class: "secondary-button",
  "data-test": "ia.preview.wallpapersheetbasic.secondarytext"
}
const _hoisted_6 = {
  key: 1,
  class: "primary-button",
  "data-test": "ia.preview.wallpapersheetbasic.primarytext"
}

import inAppPreviewBackgroundUrl from "@/assets/images/inapp-preview-background.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewWallpaperSheetBasic',
  props: {
    title: {},
    message: {},
    primaryText: {},
    secondaryText: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.message !== null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true),
        _cache[0] || (_cache[0] = _createElementVNode("div", {
          class: "wallpapers",
          "data-test": "ia.preview.wallpapersheetbasic.wallpapers"
        }, [
          _createElementVNode("img", { src: _imports_0 }),
          _createElementVNode("img", { src: _imports_1 }),
          _createElementVNode("img", { src: _imports_2 })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.secondaryText)
            ? (_openBlock(), _createElementBlock("button", _hoisted_5, _toDisplayString(_ctx.secondaryText), 1))
            : _createCommentVNode("", true),
          (_ctx.primaryText)
            ? (_openBlock(), _createElementBlock("button", _hoisted_6, _toDisplayString(_ctx.primaryText), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})