import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import FormAction from "@/components/shared/formaction/FormAction.vue";

import { InAppAction, InAppActionsConfig } from "../typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppFormActions',
  props: {
    actionsConfig: {}
  },
  emits: ["action"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const actionButtons: InAppAction[] = [
  "Preview",
  "Validate",
  "Clone",
  "Activate",
  "Pause",
  "Reactivate",
  "Finish",
  "Delete",
  "Save",
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(actionButtons, (action) => {
    return _createVNode(FormAction, {
      key: action,
      "data-test": `ia.action.${action.toLowerCase()}`,
      config: props.actionsConfig[action],
      onClick: ($event: any) => (emit('action', action))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(action), 1)
      ]),
      _: 2
    }, 1032, ["data-test", "config", "onClick"])
  }), 64))
}
}

})