import { computed, watch } from "vue";

import { fetchInAppTarget } from "@/api/inapps/target";
import {
  isExistingInApp,
  NewOrExistingInApp,
} from "@/api/inapps/typedefs/inApp";
import { validateInAppTarget } from "@/api/inapps/validation";
import { castExists } from "@/lib/casts";
import { ProductLine } from "@/lib/productLine";
import { ReadonlyRef } from "@/lib/typing";

import { useInAppTargetFlowMachine } from "./useInAppTargetFlowMachine";
import { useInAppTargetFlowVueMachine } from "./useInAppTargetFlowVueMachine";

interface UseInAppTargetOptions {
  inApp: ReadonlyRef<NewOrExistingInApp>;
  productLine: ReadonlyRef<ProductLine | null>;
}

export function useInAppTarget({ inApp, productLine }: UseInAppTargetOptions) {
  const { inAppTargetFlowMachine } = useInAppTargetFlowMachine({
    validateTargetCallback: async () => {
      if (!isExistingInApp(inApp.value)) {
        throw new Error("Cannot validate new InApp");
      }

      return validateInAppTarget(
        inApp.value,
        castExists(productLine.value?.id)
      );
    },
  });

  const targetStateMachine = useInAppTargetFlowVueMachine(
    inAppTargetFlowMachine
  );

  const loadTarget = async (targetId: number) => {
    const target = await fetchInAppTarget(
      castExists(productLine.value?.id),
      targetId
    );
    targetStateMachine.send({ type: "LOAD_TARGET", target: target });
  };

  const clearTarget = () => targetStateMachine.send({ type: "CLEAR_TARGET" });

  watch(
    () => inApp.value.target,
    async () => {
      if (inApp.value.target === null) {
        clearTarget();
      } else {
        loadTarget(inApp.value.target);
      }
    },
    { immediate: true }
  );

  const target = computed(() => targetStateMachine.state.value.context.target);
  const inAppTargetErrorMessage = computed(
    () => targetStateMachine.state.value.context.errorMessage
  );

  return {
    target,
    inAppTargetErrorMessage,
    targetStateMachine,
    loadTarget,
  };
}
