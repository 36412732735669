import { WithId } from "@/lib/typing";

import { SegmentConfig } from "./expression/segmentConfig";
import { SegmentType } from "./expression/segmentType";
import { segmentValidate } from "./expression/segmentValidate";
import { ExprItem } from "./expression/typedefs";

type ExprSegmentId = string;
type ExprSegmentErrors = string[];
type ExprErrors = Record<ExprSegmentId, ExprSegmentErrors>;

const ERROR_UNKNOWN_SEGMENT = "Unknown segment";

export function expressionValidate(
  expr: readonly WithId<ExprItem>[],
  configs: Map<SegmentType, SegmentConfig>
): ExprErrors | null {
  const errors: ExprErrors = {};
  for (const item of expr) {
    if (item.type !== "value") {
      continue;
    }

    const config = configs.get(item.segmentType);
    if (config === undefined) {
      errors[item.id] = [ERROR_UNKNOWN_SEGMENT];
      continue;
    }

    const segmentErrors = segmentValidate(config, item.value);
    if (segmentErrors.length === 0) {
      continue;
    }

    errors[item.id] = segmentErrors;
  }

  if (Object.keys(errors).length === 0) {
    return null;
  }

  return errors;
}
