// This type is extracted from old FE. It was also made slightly more restrictive.
// It is a format of data we receive from BE.
import { SegmentType } from "./segmentType";
import { SelectTypeId } from "./selectType";

export interface SegmentRawConfig {
  type: "number" | ""; // TODO(PNS-1994): rename type "" to "simple" after implementing targeting.
  handler_type?: "input_limiter";
  matching?: SelectTypeId;
  label: string;
  limiter_min?: number;
  size?: number;
  matching_at_end?: boolean;
}

// TODO(PNS-1994): Configs should be fetched from BE.
export const SEGMENT_TYPE_ID_TO_RAW_CONFIGS: Record<
  SegmentType,
  SegmentRawConfig
> = {
  [SegmentType.LIMIT_FREQUENCY]: {
    handler_type: "input_limiter",
    label: "Up to",
    limiter_min: 1,
    matching: "per_frequency",
    matching_at_end: true,
    size: 3,
    type: "number",
  },
  [SegmentType.USER_ENTERED_START_PAGE]: {
    label: "User entered start page",
    type: "",
  },
  [SegmentType.USER_ENTERED_START_PAGE_CLASSIC_NEWSFEED]: {
    label: "User entered start page in classic mode with newsfeed",
    type: "",
  },
  [SegmentType.USER_STARTS_APP]: {
    label: "User starts app",
    type: "",
  },
  [SegmentType.USER_STARTS_RESUMES_APP]: {
    label: "User starts or resumes app",
    type: "",
  },
  [SegmentType.USER_RESUMED_APP_ON_WEB_PAGE]: {
    label: "User resumed app on web page",
    type: "",
  },
  [SegmentType.USER_APPLIED_CONFIG_BUNDLE]: {
    label: "User applied config bundle",
    type: "",
  },
  [SegmentType.USER_READY_FOR_CONFIG_BUNDLE]: {
    label: "User ready for config bundle",
    type: "",
  },
};
