import useQuasar from 'quasar/src/composables/use-quasar.js';;

import InAppDeleteDialog from "./InAppDeleteDialog.vue";

interface InAppDeleteDialogOptions {
  onOk: (rationale: string) => void;
}

export function useInAppDeleteDialog(options: InAppDeleteDialogOptions) {
  const $q = useQuasar();
  const openDialog = () => {
    $q.dialog({
      component: InAppDeleteDialog,
    }).onOk((payload: unknown) => {
      const rationale = (payload as { rationale?: unknown }).rationale;
      options.onOk(typeof rationale === "string" ? rationale : "");
    });
  };

  return {
    openDialog,
  };
}
