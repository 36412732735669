import { InAppMachineStates } from "@/views/inapps/useInAppFlowMachine";

export const SAVE_VISIBLE_STATES: InAppMachineStates[] = [
  "draft.modified",
  "new.modified",
  "paused.modified",
];
export const CLONE_VISIBLE_STATES: InAppMachineStates[] = [
  "draft",
  "pending",
  "active",
  "paused",
  "finished",
  "deleted",
];
export const PREVIEW_VISIBLE_STATES: InAppMachineStates[] = [
  "draft",
  "finished",
  "paused",
  "active",
  "deleted",
  "pending",
];
export const VALIDATE_VISIBLE_STATES: InAppMachineStates[] = [
  "draft.unmodified",
];
export const ACTIVATE_VISIBLE_STATES: InAppMachineStates[] = [
  "draft.unmodified",
];
export const PAUSE_VISIBLE_STATES: InAppMachineStates[] = ["pending", "active"];
export const REACTIVATE_VISIBLE_STATES: InAppMachineStates[] = [
  "paused.unmodified",
];
export const FINISH_VISIBLE_STATES: InAppMachineStates[] = ["paused"];
export const DELETE_VISIBLE_STATES: InAppMachineStates[] = ["draft"];
