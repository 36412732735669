import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "segment" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = {
  key: 0,
  class: "error"
}

import QInput from 'quasar/src/components/input/QInput.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;

import { WithId } from "@/lib/typing";

import ExpressionSegmentMatching from "./ExpressionSegmentMatching.vue";
import { SegmentConfig } from "./segmentConfig";
import { ExprSegment, ExprSegmentData, ExprSegmentError } from "./typedefs";

// Props.

export default /*@__PURE__*/_defineComponent({
  __name: 'ExpressionSegment',
  props: {
    segment: {},
    config: {},
    errors: {},
    isDisabled: { type: Boolean }
  },
  emits: ["delete", "update"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

// Emits.
const emit = __emit;

// Methods.
const updateExprValue = (newValue: string | number | null) => {
  emit("update", {
    value: newValue === null ? undefined : newValue,
    matching: props.segment.matching,
  });
};

const updateExprMatching = (newMatching: string) => {
  emit("update", {
    value: props.segment.value,
    matching: newMatching,
  });
};

useFormChild({
  validate: () => {
    const hasAnyError = props.errors.length > 0;
    const isValid = !hasAnyError;
    return isValid;
  },
  requiresQForm: false,
});

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(props?.config?.label ?? props.segment.segmentType), 1),
      (props.config && props.config.inputConfig !== undefined)
        ? (_openBlock(), _createBlock(_unref(QInput), {
            key: 0,
            "model-value": props.segment.value,
            "data-test": "expressionsegment.input.value",
            class: "q-input",
            dense: "",
            type: props.config.inputConfig.inputType,
            disable: _ctx.isDisabled,
            "onUpdate:modelValue": updateExprValue
          }, null, 8, ["model-value", "type", "disable"]))
        : _createCommentVNode("", true),
      (
          props.config &&
          props.config.matchingConfig !== undefined &&
          props.segment.matching !== undefined
        )
        ? (_openBlock(), _createBlock(ExpressionSegmentMatching, {
            key: 1,
            "is-disabled": _ctx.isDisabled,
            "model-value": props.segment.matching,
            "data-test": "expressionsegment.input.matching",
            class: "matching",
            config: props.config.matchingConfig,
            "onUpdate:modelValue": updateExprMatching
          }, null, 8, ["is-disabled", "model-value", "config"]))
        : _createCommentVNode("", true),
      (!_ctx.isDisabled)
        ? (_openBlock(), _createBlock(_component_q_icon, {
            key: 2,
            "data-test": "expressionsegment.button.delete",
            class: "cursor-pointer",
            name: "close",
            color: "negative",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('delete')))
          }))
        : _createCommentVNode("", true)
    ]),
    (props.errors.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.errors.join(", ")), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})