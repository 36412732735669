import { omit } from "lodash";
import { Component, computed, nextTick, ref } from "vue";

import { clearInvalidInAppDataProps } from "@/api/inapps/typedefs/helpers";
import {
  InAppData,
  InAppDataGuard,
  InAppDataPartial,
  isExistingInApp,
  NewOrExistingInApp,
} from "@/api/inapps/typedefs/inApp";
import { InAppDataLocalizationUploadResult } from "@/api/inapps/typedefs/inAppData";
import { fuzzyObjectAssign } from "@/lib/fuzzy";
import { getNewInAppLocalizationData } from "@/lib/inapps/inAppDataTypes";
import { cast, ReadonlyRef } from "@/lib/typing";

const localizationSymbol = "localization";
const dataLocalizationAttribute = `data-${localizationSymbol}`;
const CampaignAMGUrlPrefix = "https://amg.opera.com/campaign";

interface UseInAppFormOptions {
  inApp: ReadonlyRef<NewOrExistingInApp>;
  handleInAppUpdate: (result: NewOrExistingInApp) => unknown;
}

interface ComponentWithNativeElement {
  getNativeElement(): HTMLElement;
}

export function inAppDataComparator(a: InAppData, b: InAppData): number {
  if (a.langName === "default") {
    return -1; // -1 < 0 => sort a before b
  } else if (b.langName === "default") {
    return 1; // 1 > 0 => sort a after b
  } else {
    return a.langName.localeCompare(b.langName);
  }
}

function getInitialSelectedLocalization(
  inApp: NewOrExistingInApp
): string | null {
  if (!inApp.inAppData || !inApp.inAppData.length) {
    return null;
  }

  return (
    inApp.inAppData.find((l) => l.langCode === "default")?.langCode ??
    inApp.inAppData[0].langCode
  );
}

/**
 * This method extracts native element from component and tries to find
 * element with localization data attribute in its ancestors chain and
 * return that localization data value.
 */
const getLocalizationForComponent = (component: Component): string | null => {
  // Pick native element from component.
  const nativeElement = (
    component as Partial<ComponentWithNativeElement>
  )?.getNativeElement?.();

  if (!nativeElement) return null;

  // Find root of in app data - it has dataset[localization].
  let parentElement = nativeElement.parentElement;
  while (
    !parentElement?.dataset?.[localizationSymbol] &&
    parentElement !== null
  ) {
    parentElement = parentElement.parentElement;
  }

  if (parentElement === null) return null;

  return parentElement.dataset[localizationSymbol] ?? null;
};

export function useInAppForm({
  inApp,
  handleInAppUpdate,
}: UseInAppFormOptions) {
  // TODO(PNS-2447): Move InApp localization logic to separate component/composable
  // InApp localization
  const selectedLocalization = ref<string | null>(
    getInitialSelectedLocalization(inApp.value)
  );

  const addInAppLocalization = (langCode: string, langName: string): void => {
    if (inApp.value.inAppData === undefined) {
      return;
    }
    const newLocalization = getNewInAppLocalizationData(
      inApp.value.inAppType,
      langCode,
      langName
    );

    const result: NewOrExistingInApp = {
      ...inApp.value,
      inAppData: [...inApp.value.inAppData, newLocalization],
    };

    selectedLocalization.value = langCode;

    handleInAppUpdate(result);
  };

  const updateInAppLocalization = (
    originalLangCode: string,
    targetLangCode: string,
    targetLangName: string
  ): void => {
    if (!inApp.value.inAppData) {
      return;
    }
    inApp.value.inAppData.forEach((inAppData: InAppData) => {
      if (inAppData.langCode === originalLangCode) {
        inAppData.langCode = targetLangCode;
        inAppData.langName = targetLangName;
      }
    });
    selectedLocalization.value = targetLangCode;

    handleInAppUpdate(inApp.value);
  };

  const deleteInAppLocalization = (localization: string): void => {
    if (inApp.value.inAppData === undefined) {
      return;
    }

    const updatedInApp = {
      ...inApp.value,
      inAppData: inApp.value.inAppData.filter(
        (inAppDataItem: InAppData) => inAppDataItem.langCode !== localization
      ),
    };

    if (selectedLocalization.value === localization) {
      selectedLocalization.value = getInitialSelectedLocalization(updatedInApp);
    }

    handleInAppUpdate(updatedInApp);
  };

  const acceptLocalizationUploadResult = (
    result: InAppDataLocalizationUploadResult
  ): void => {
    const updatedInApp: NewOrExistingInApp = { ...inApp.value };

    if (updatedInApp.inAppData === undefined) {
      return;
    }

    for (const [lang, freeformLocalization] of Object.entries(result)) {
      const langCode = lang.toLowerCase();
      const langName = `${langCode} (.XLSX)`;

      const localizationPrototype = getNewInAppLocalizationData(
        inApp.value.inAppType,
        langCode,
        langName
      );

      // Some properties of single data entry can never be set via the file
      // upload from the free-form data object. These are mostly the fields
      // that allow us to identify the entry (language) and type discriminator
      const uploadable: Record<string, unknown> = omit(localizationPrototype, [
        "type",
        "langCode",
        "langName",
      ]);

      // note: the upload result (e.g. XLSX file) may not match
      //       exactly the field names so let's do some fuzzy matching here
      fuzzyObjectAssign(
        uploadable,
        freeformLocalization,
        // note: description/message confusion is common enough to use
        //       an alias for it
        { description: "message" }
      );

      // `uploadable` is not a complete InAppData object, hence we need to merge
      // it back with our prototype value to fill out missing properties.
      // Moreover, some properties may require more complex data types (e.g. for URLs).
      // Unfortunately, we don't support them yet so we need to clear any potential
      // invalid values. These will be replaced by null values
      const newLocalization = clearInvalidInAppDataProps({
        ...localizationPrototype,
        ...uploadable,
      });

      // note: "upserting" so we don't end up with tab duplicates
      const filteredInAppData: InAppDataPartial["inAppData"] =
        updatedInApp.inAppData.filter(
          (item: InAppData) => item.langCode !== langCode
        );

      updatedInApp.inAppData = [...filteredInAppData, newLocalization];
    }
    handleInAppUpdate(updatedInApp);
  };

  const updateInAppData = (updatedInAppData: InAppData) => {
    const updatedInApp = {
      ...inApp.value,
      inAppData: inApp.value.inAppData
        ? inApp.value.inAppData.map((inAppData) =>
            inAppData.langCode === updatedInAppData.langCode
              ? { ...updatedInAppData }
              : inAppData
          )
        : undefined,
    };
    handleInAppUpdate(updatedInApp);
  };

  const selectedInAppData = computed<InAppData | null>(() => {
    if (!inApp.value.inAppData) {
      return null;
    }
    for (const iteratedInAppData of inApp.value.inAppData) {
      if (iteratedInAppData.langCode === selectedLocalization.value) {
        return iteratedInAppData;
      }
    }
    return null;
  });

  async function maybeSelectLocalizationWithError(
    componentWithError: Component
  ) {
    const localizationWithError =
      getLocalizationForComponent(componentWithError);
    if (localizationWithError === null) return;
    if (!selectedInAppData.value) return;

    const openLocalization = selectedInAppData.value.langCode;
    if (openLocalization !== localizationWithError) {
      selectedLocalization.value = localizationWithError;
    }

    // Wait so selectedLocalization changes are reflected in the DOM.
    await nextTick();
  }

  const inAppDataForPreview = computed<InAppData | null>(() => {
    if (inApp.value.inAppData === undefined) {
      return null;
    }
    const defaultInAppData = inApp.value.inAppData.find(
      (data) => data.langCode === "default"
    );

    if (defaultInAppData === undefined) return null;
    if (selectedInAppData.value === null) return null;

    const dataForPreview: Record<string, unknown> = {};
    for (const rawKey of Object.keys(defaultInAppData)) {
      const key = rawKey as keyof InAppData;
      dataForPreview[key] =
        selectedInAppData.value[key] || defaultInAppData[key];
    }
    return cast(InAppDataGuard, dataForPreview);
  });

  const sortedInAppData = computed<InAppData[] | undefined>(() => {
    if (inApp.value.inAppData === undefined) {
      return undefined;
    }
    return [...inApp.value.inAppData].sort(inAppDataComparator);
  });

  const campaignAmgUrl = computed(() => {
    if (isExistingInApp(inApp.value) && inApp.value.campaignId !== null) {
      return `${CampaignAMGUrlPrefix}/${inApp.value.campaignId}/`;
    }
    return null;
  });

  return {
    addInAppLocalization,
    updateInAppLocalization,
    deleteInAppLocalization,
    acceptLocalizationUploadResult,
    updateInAppData,
    selectedInAppData,
    selectedLocalization,
    inAppDataForPreview,
    maybeSelectLocalizationWithError,
    dataLocalizationAttribute,
    sortedInAppData,
    campaignAmgUrl,
  };
}
