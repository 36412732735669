/**
 * Adapter module for read-excel-file library providing some shortcuts for working with
 * localization spreadsheets.
 *
 * We don't have well defined localization spreadsheet format yet. For now, we just try
 * to do our best by assuming some typical structure and apply a couple heuristics.
 *
 * Typical format spreadsheet format is as follows:
 *
 *    || A              | B            | C    | D            | E    |              |
 *  1 ||                | EN           | Lens | ES           | Lens | BR-PT        |
 *  2 || Title          | (translated) | ...  | (translated) | ...  | (translated) |
 *  3 || Message        | (translated) | ...  | (translated) | ...  | (translated) |
 *  4 || Primary Text   | (translated) | ...  | (translated) | ...  | (translated) |
 *  5 || Secondary Text | (translated) | ...  | (translated) | ...  | (translated) |
 *
 * Notes:
 * - We use ISO 639-1 codes but input documents may use uppercase two-segment
 *   locale codes (ISO 3166-1 + ISO 639-1), so we have to apply some normalization
 *   heuristics
 * - `Lens` specifies length of localization string but is is completely irrelevant
 */

import readXlsxFile, { Row } from "read-excel-file";
import { Cell } from "read-excel-file/types";
import {
  Cell as ExcelInputCell,
  Columns as ExcelInputColumns,
  Row as ExcelInputRow,
  default as writeXlsxFile,
} from "write-excel-file";

import { InAppData, InAppDataGuard } from "@/api/inapps/typedefs/inApp";
import { InAppDataLocalizationUploadResult } from "@/api/inapps/typedefs/inAppData";

/* Note: it matches both simple ISO 639-1 (alpha2) codes and locale codes consisting of
 * ISO 3166-1 country and ISO 3166-1 language code:
 * - If last group is present, then it is locale code and last group is ISO 639-1
 * - If only first group is present then it is ISO 639-1
 */
const CI_LOCALE_RE = /^\w{2}(-(\w{2}))?$/;
/* Note: we don't want to export some of the non-editable inapp data metadata fields.
 * Probably there's a smarter way to annotate them somehow in InAppDataGuard but
 * simple blacklist is much more pragmatic now.
 */
const SKIP_INAPP_EXPORT_FIELDS = ["type", "langCode", "langName"];
export type XlsxInput = File | Blob | ArrayBuffer;
/**
 * Verify if cell is a string
 *
 * This is just to have some type guard for TypeScript as read-excel-file lib provides
 * very rudimentary interface. Weed to do some unelegant filtering of the reaceived
 * values and having such guard makes TS happy.
 *
 * @param input
 */
function isStringCell(input: Cell): input is string {
  return typeof input === "string";
}

/**
 * Map leftmost non-empty strings values to their X indices in sheet matrix
 * @param rows
 */
function mapLeadingRowValuesToIdx(rows: Row[]): Record<string, number> {
  const rowIdx = rows
    .map((row: Row, idx: number) => [row[0], idx])
    .filter(([cell, ,]) => isStringCell(cell));
  return Object.fromEntries(rowIdx);
}

/**
 * Map topmost "language-code-alike" string values to their Y indices in sheet matrix
 * @param rows
 */
function mapLeadingColumnValuesToIdx(rows: Row[]): Record<string, number> {
  const colIdx = rows[0]
    .map((cell: Cell, idx: number) => [cell, idx])
    .filter(([cell, ,]) => isStringCell(cell))
    .filter(([lead, ,]) => typeof lead === "string" && CI_LOCALE_RE.test(lead));
  return Object.fromEntries(colIdx);
}

/**
 * Normalize given language code to uppercase ISO 639-1 code format
 *
 * Can accept string that looks like either locale code (two-component locale string)
 * or plain ISO 639-1 code (alpha2):
 * - locale code: extracts ISO 639-1 language component and forces it to uppercase
 * - plain ISO 639-1: forces it to uppercase
 * - other: returns null
 *
 * Imporant: this is only format level normalization. This function does not consult
 * any ISO code database and is not able to check whether given input is a valid
 * language/locale target.
 * @param code
 */
export function normalizeLanguageCode(code: string): string | null {
  const match = code.trim().match(CI_LOCALE_RE);
  return (match && (match[2] || match[0]).toUpperCase()) || null;
}

/**
 * Read "free-form" localizaton data from given XLSX file by applying some heuristics.
 *
 * The example result format is as follows:
 *
 * {
 *     "EN": {
 *         "Title": "New notification",
 *         "Primary Button": "Accept"
 *     },
 *     "PL": {
 *         "Title": "Nowe powiadomienie",
 *         "Primary Button": "Akceptuj"
 *     }
 * }
 *
 * Each localization set is keyed by ISO 639-1 (note: uppercase) language code.
 * The inner (per-languages) properties are guaranteed to be consistent across
 * languyages and each language stanza will have exactly the same set of properties.
 * This will be true even if values for given language entry are null or empty.
 *
 * Only languages found in the spreadsheet will be included in the file.
 *
 * @param file input XLSX file handle
 */
export async function freeformLocalizationFromXlsx(
  file: XlsxInput
): Promise<InAppDataLocalizationUploadResult> {
  const rows = await readXlsxFile(file);

  const idxByRow: Record<string, number> = mapLeadingRowValuesToIdx(rows);
  const idxByCol: Record<string, number> = mapLeadingColumnValuesToIdx(rows);

  const localizations: InAppDataLocalizationUploadResult = {};
  for (const [langOrLocale, langColIdx] of Object.entries(idxByCol)) {
    const lang = normalizeLanguageCode(langOrLocale);
    if (lang === null) {
      continue;
    }

    localizations[lang] = {};

    for (const [field, fieldRowIdx] of Object.entries(idxByRow)) {
      localizations[lang][field as string] = rows[fieldRowIdx][
        langColIdx
      ] as string;
    }
  }
  return localizations;
}

function localizableInAppProps(inAppData: InAppData): (keyof InAppData)[] {
  const fields: string[] = [];

  for (const type of InAppDataGuard.types) {
    if (type.is(inAppData)) {
      fields.push(
        ...Object.entries(type.props)
          // make sure we take only string values
          .filter(([, type]) => type.is(""))
          // but ones that are not part of common inapp "metadata"
          .filter(([key]) => !SKIP_INAPP_EXPORT_FIELDS.includes(key))
          // and convert it into
          .map(([key]) => key)
      );
    }
  }
  return fields as (keyof InAppData)[];
}

/**
 * Convert zero-based (row, col) indices into A1-form address.
 *
 * @param colIdx zero-based column index (B3 address has colIdx of 1)
 * @param rowIdx zero-based row index (B3 address has rowIdx of 2)
 */
export function cellAddress(colIdx: number, rowIdx: number): string {
  return `${colName(colIdx)}${rowIdx + 1}`;
}

/**
 * Translate zero-based column index into alphabetical A1-form column reference
 *
 * @param colIdx zero-based column index (B3 address has colIdx of 1)
 */
function colName(colIdx: number): string {
  let columnName = "";
  const charACode = "A".charCodeAt(0);
  const charNumber = "Z".charCodeAt(0) - "A".charCodeAt(0) + 1;

  while (colIdx >= 0) {
    const remainder = colIdx % charNumber;
    columnName = String.fromCharCode(charACode + remainder) + columnName;
    colIdx = Math.floor(colIdx / charNumber) - 1;
  }

  return columnName;
}

/**
 * Calculate column XLSX column width to accomodate given text
 * @param text string content to fit
 * @param atLeast minimal width of the column regardless of textcontent
 */
function textToWidth(text: string, atLeast: number): number {
  if (text) {
    const required = text.length;
    if (atLeast !== undefined && atLeast !== null) {
      return Math.max(atLeast, required);
    }
    return required;
  }
  return atLeast;
}

/**
 * Construct auto-size column definitions from given XLSX rows
 * @param rows
 */
function columnsFromRows(rows: ExcelInputRow[]): ExcelInputColumns {
  const cols: ExcelInputColumns = [];
  const defaultWidth = 5;

  for (const row of rows) {
    for (let i = 0; i < row.length; i++) {
      const cell = row[i];
      const col = cols.at(i) || { width: defaultWidth };
      const text = (cell?.value || "").toString();
      col.width = textToWidth(text, col.width || defaultWidth);
      cols[i] = col;
    }
  }
  return cols;
}

export function localizationXlsxTemplateRowsFromInapp(
  inAppData: InAppData[]
): ExcelInputRow[] {
  const defaultInApp = inAppData[0];
  const localizedInApp = inAppData.slice(1);

  const languages = localizedInApp.map((i) => i.langCode);

  const props = localizableInAppProps(defaultInApp);
  const defaults = props.map((prop) => defaultInApp[prop]);
  const headerStyle: ExcelInputCell = {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  };
  const textStyle: ExcelInputCell = {
    backgroundColor: "#D0E0CB",
    color: "#000000",
    borderStyle: "thin",
  };

  const headerRow: ExcelInputRow = [
    { value: "" },
    { value: "Default", ...headerStyle },
  ];

  headerRow.push(
    ...languages.map((lang) => ({ value: lang.toUpperCase(), ...headerStyle }))
  );

  const dataRows: ExcelInputRow[] = [];

  for (const [index, prop] of props.entries()) {
    dataRows.push([
      { value: prop, ...headerStyle },
      { value: defaults[index], ...textStyle },
      ...languages.map((_, colIdx) => ({
        value: localizedInApp[colIdx][prop],
        ...textStyle,
      })),
    ]);
  }
  return [headerRow, ...dataRows];
}

export async function localizationXlsxTemplateFromInapp(
  inAppData: InAppData[]
): Promise<Blob> {
  const rows = localizationXlsxTemplateRowsFromInapp(inAppData);
  return writeXlsxFile(rows, { columns: columnsFromRows(rows) });
}
