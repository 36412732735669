import { pipe } from "fp-ts/function";
import { mapLeft } from "fp-ts/These";

import { InAppData, InAppDataGuard } from "@/api/inapps/typedefs/inApp";

/**
 * Take potentially invalid InAppData object and clear any fields that
 * would fail validation in hopes it becomes a valid object.
 *
 * @param unverified
 */
export const clearInvalidInAppDataProps = (
  unverified: InAppData
): InAppData => {
  const clone = { ...unverified };
  pipe(
    InAppDataGuard.validate(clone, []),
    mapLeft((errors) =>
      errors.forEach((err) => {
        err.context
          .filter((ctx) => Object.prototype.hasOwnProperty.call(clone, ctx.key))
          .forEach((ctx) => Object.assign(clone, { [ctx.key]: null }));
      })
    )
  );

  // note: at this point clone should be conforming but nobody can be 100% sure.
  // We can't do anything (¯\_(ツ)_/¯) so let's just log the error in hopes we
  // will find out when it happens.
  pipe(
    InAppDataGuard.validate(clone, []),
    mapLeft(() =>
      console.error(
        `Object still does not conform with InAppData[${clone?.type}] despite cleansing`,
        clone
      )
    )
  );
  return clone;
};
