/**
 *  Utilities for fuzzy pattern and object matching
 */

/**
 * Calculate Levenstein distance between two strings
 *
 * Levenstein distance (see: https://en.wikipedia.org/wiki/Levenshtein_distance) is
 * a simple similarity metric to compare look-alike strings. Low value means two
 * strings very similar and it would take only such many edits on either of them
 * to make them both the same value.
 *
 * @param a one of the strings to compare
 * @param b one of the strings to compare
 *
 * Note: initial code was sourced from https://gist.github.com/andrei-m/982927 and
 * adapted to our needs
 */
export function levensteinDistance(a: string, b: string): number {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  // note: this is classic dynamic programming technique hence working the martix
  const matrix: number[][] = [];

  // increment along the first column of each row
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1
          )
        ); // deletion
      }
    }
  }

  return matrix[b.length][a.length];
}

/**
 * Assing object properties to target from source by fuzzy-matching property names.
 *
 * This function may be useful when applying changes from free-form dictionary of keys
 * that not necessarily has 1:1 parity of property names. This is a "best-effort"
 * procedure that allows for simple bulk property update from external sources (e.g.
 * data imported from files) where it would be hard to provide exhaustive field
 * translation.
 *
 * @param target object to modify (in place)
 * @param source object to read properties from
 * @param aliases optional property name aliases to use when calculating edit distance
 *
 * Result of this function isn't guaranteed to be ever correct and can manifest
 * following behaviours:
 * - Same property may be updated multiple times if target propery ties for best match
 *   with multiple source properties. Order of resolving such ties is unspecified.
 * - If multiple target properties tie for best name match for a single source property,
 *   only the one will be updated. It is unpsecified which one will it be.
 */
export function fuzzyObjectAssign<
  T extends Record<string, unknown>,
  U extends Record<string, unknown>
>(
  target: T,
  source: U,
  aliases: Record<Lowercase<string>, string> | null = null
): T {
  for (const sourceField in source) {
    const value = source[sourceField];
    if (!value) {
      continue;
    }

    const sourceFieldAlias =
      aliases && aliases[sourceField.toLowerCase() as Lowercase<string>];
    const scoredMatches = Object.keys(target)
      .map(
        (name) =>
          [
            // note: aliases are another heuristic so sometimes may actually have
            //       larger edit distance from the field we actually want to modify.
            //       So, check both original and alias name, then use lowest value
            Math.min(
              levensteinDistance(sourceFieldAlias || sourceField, name),
              levensteinDistance(sourceField, name)
            ),
            name,
          ] as [number, string]
      )

      .sort((a, b) => {
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      });

    const bestFieldMatch = scoredMatches[0][1];
    Object.assign(target, { [bestFieldMatch]: value });
  }
  return target;
}
