import { ExprItem, ExprOperatorType } from "./typedefs";

export function splitByOperator<T extends ExprItem>(
  expr: Array<T>,
  operator: ExprOperatorType
): Array<T[]> {
  if (expr.length === 0) return [];

  const groupedBy: Array<T[]> = [];
  let group: T[] = [];
  for (const item of expr) {
    if (item.type === operator) {
      groupedBy.push(group);
      group = [];
    } else {
      group.push(item);
    }
  }

  if (group.length > 0) {
    groupedBy.push(group);
  }

  return groupedBy;
}
