import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col header" }
const _hoisted_2 = ["src"]

import { ProductLine } from "@/lib/productLine";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppHeader',
  props: {
    productLine: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.productLine.iconUrl
    }, null, 8, _hoisted_2),
    _createTextVNode(" " + _toDisplayString(props.productLine.productName) + " / " + _toDisplayString(props.productLine.name) + " / In App ", 1)
  ]))
}
}

})