import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h6 q-mb-sm" }
const _hoisted_2 = {
  key: 0,
  class: "row q-col-gutter-sm"
}
const _hoisted_3 = { class: "col-5" }
const _hoisted_4 = { class: "col-5" }

import QCard from 'quasar/src/components/card/QCard.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';;
import { computed, toRefs } from "vue";

import { NewOrExistingInApp } from "@/api/inapps/typedefs/inApp";
import { rolloutStrategyOptions } from "@/api/inapps/typedefs/inAppTypes";
import NumberInput from "@/components/shared/NumberInput.vue";
import { bindPropToRef } from "@/lib/propToRef";
import { isNonEmpty } from "@/lib/rules/objectValidator";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppRolloutStrategy',
  props: {
    inApp: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:inApp"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function onInAppUpdate(newValue: NewOrExistingInApp) {
  emit("update:inApp", newValue);
}
const inAppLocal = bindPropToRef<NewOrExistingInApp>(
  () => props.inApp,
  onInAppUpdate
);

const { isDisabled } = toRefs(props);

const isStrategyEnabled = computed<boolean>((): boolean => {
  return props.inApp.rolloutStrategyType !== null;
});

const onToggleRolloutStrategy = (value: boolean) => {
  if (value) {
    inAppLocal.value.rolloutStrategyType = "Day match only";
    inAppLocal.value.rolloutStrategyWindowSize = 7;
  } else {
    inAppLocal.value.rolloutStrategyType = null;
    inAppLocal.value.rolloutStrategyWindowSize = null;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_unref(QCard), null, {
    default: _withCtx(() => [
      _createVNode(_unref(QCardSection), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createTextVNode(" Rollout strategy ")),
            _createVNode(_component_q_toggle, {
              modelValue: isStrategyEnabled.value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((isStrategyEnabled).value = $event)),
                onToggleRolloutStrategy
              ],
              disable: _unref(isDisabled),
              "data-test": "ia.rollout_strategy.toggle"
            }, null, 8, ["modelValue", "disable"])
          ]),
          (isStrategyEnabled.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_q_select, {
                    modelValue: _unref(inAppLocal).rolloutStrategyType,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(inAppLocal).rolloutStrategyType) = $event)),
                    options: _unref(rolloutStrategyOptions),
                    disable: _unref(isDisabled),
                    filled: "",
                    label: "Strategy type",
                    hint: "Strategy type employed when rolling out the in-app",
                    "data-test": "ia.rollout_strategy.type"
                  }, null, 8, ["modelValue", "options", "disable"])
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-2" }, null, -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(NumberInput, {
                    modelValue: _unref(inAppLocal).rolloutStrategyWindowSize,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(inAppLocal).rolloutStrategyWindowSize) = $event)),
                    disable: _unref(isDisabled),
                    label: "Delivery window size (in days)",
                    hint: "Number of days for which the rollout strategy will be performed",
                    filled: "",
                    min: "1",
                    max: "30",
                    "data-test": "ia.rollout_strategy.window_size",
                    rules: _unref(isNonEmpty)
                  }, null, 8, ["modelValue", "disable", "rules"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})