import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "selected-categories" }
const _hoisted_3 = { class: "inline-list" }
const _hoisted_4 = { key: 1 }

import { computed } from "vue";

import { Tag } from "@/api/tags/typedefs";

interface Props {
  modelValue: Tag[] | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TagsCategories',
  props: {
    modelValue: {}
  },
  setup(__props: any) {

const props = __props;
const filterCategories = computed(() => {
  if (!props.modelValue) return [];
  let categoriesList = new Set(
    props.modelValue.filter((tag) => tag.category).map((tag) => tag.category)
  );
  return Array.from(categoriesList);
});

return (_ctx: any,_cache: any) => {
  const _component_q_badge = _resolveComponent("q-badge")!

  return (filterCategories.value.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "Selected Categories:", -1)),
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterCategories.value, (category) => {
              return (_openBlock(), _createElementBlock("li", { key: category }, [
                _createVNode(_component_q_badge, { "data-test": "form.category.selected" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(category), 1)
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
        _createElementVNode("p", { "data-test": "form.category.missing" }, "No categories selected.", -1)
      ])))
}
}

})