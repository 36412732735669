import { httpClient } from "@/lib/http";

import { convertExistingInApp } from "./converters";
import { ExistingInApp } from "./typedefs/inApp";

export async function reactivateInApp(
  productLineId: number,
  inAppId: number
): Promise<ExistingInApp> {
  const response = await httpClient.post(
    `/api/inapps/reactivate-inapp/${productLineId}/${inAppId}`
  );
  const data = await response.json();
  return convertExistingInApp(data);
}
