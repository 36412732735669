import { computed, Ref } from "vue";

import { ShallowReadonlyRef } from "@/lib/typing";
import { WithId } from "@/lib/typing";

import { SegmentType } from "./segmentType";
import { splitByOperator } from "./splitByOperator";
import { Expr, ExprItem, ExprOperatorType } from "./typedefs";
import { useExpressionInputConfig } from "./useExpressionInputConfig";
import { useExpressionInputEdit } from "./useExpressionInputEdit";
import { useExpressionInputErrors } from "./useExpressionInputErrors";

export type ExprWithIds = WithId<ExprItem>[];

export interface UseExpressionInputOptions {
  availableSegmentTypes: ShallowReadonlyRef<SegmentType[]>;
  // Possible operators (AND/OR) between segments.
  availableOperators: ShallowReadonlyRef<ExprOperatorType[]>;
  modelValue: Ref<Expr>;
  onModelValueUpdate: (expr: Expr) => void;
}

export function useExpressionInput(options: UseExpressionInputOptions) {
  // Computed.
  const exprWithIds = computed<ExprWithIds>(() => {
    let id = 0;
    const items: ExprWithIds = [];
    for (const item of options.modelValue.value) {
      items.push({ ...item, id: String(id) });
      id++;
    }
    return items;
  });

  const exprSplitByAndOperator = computed(() =>
    splitByOperator(exprWithIds.value, ExprOperatorType.AND)
  );

  // Subcomposables.
  const { segmentConfigOptions, segmentTypeToConfigs, getSegmentConfig } =
    useExpressionInputConfig({
      availableSegmentTypes: options.availableSegmentTypes,
    });

  const { deleteExprSegment, updateExprSegment, addExprSegment } =
    useExpressionInputEdit({
      modelValue: options.modelValue,
      exprWithIds,
      availableOperators: options.availableOperators,
      onModelValueUpdate: (expr) => options.onModelValueUpdate(expr),
    });

  const { hasExprErrors, getExprSegmentErrors } = useExpressionInputErrors({
    exprWithIds,
    segmentTypeToConfigs,
  });

  return {
    hasExprErrors,
    getExprSegmentErrors,
    updateExprSegment,
    addExprSegment,
    deleteExprSegment,
    getSegmentConfig,
    exprWithIds,
    segmentConfigOptions,
    exprSplitByAndOperator,
  };
}
