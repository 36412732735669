import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-gutter-sm" }

import QInput from 'quasar/src/components/input/QInput.js';;
import { computed, ref } from "vue";

import {
  InAppButtonAction,
  InAppButtonActionTypeGuard,
  InAppButtonActionTypeOptions,
} from "@/api/inapps/typedefs/inAppButtonAction";
import { castString } from "@/lib/casts";
import { ValidationRule } from "@/lib/rules/typedefs.js";
import { cast } from "@/lib/typing";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppButtonActionInput',
  props: {
    modelValue: {},
    typeOptions: {},
    isDisabled: { type: Boolean },
    label: {},
    rules: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const actionTypeLabel = computed(() => `${props.label} button type`);

const onlyOneTypeOption = computed(() => props.typeOptions.length === 1);

const selectedActionType = ref(props.modelValue?.type ?? props.typeOptions[0]);

const actionTypeValueLabel = (option: unknown) => {
  if (typeof option !== "string") return "No option selected";
  return (
    InAppButtonActionTypeOptions.find((o) => o.value === option)?.label ??
    "Unknown option selected"
  );
};

const onValueUpdate = (rawValue: string | number | null) => {
  const value = castString(rawValue);
  if (value === "") {
    emits("update:modelValue", null);
  } else {
    const newValue = {
      type: selectedActionType.value,
      value,
    };
    emits("update:modelValue", newValue);
  }
};

const onTypeUpdate = (rawValue: string | number | null) => {
  const value = castString(rawValue);
  const type = cast(InAppButtonActionTypeGuard, value);
  selectedActionType.value = type;

  // Do not emit update when value is empty.
  if (!props.modelValue?.value) return;

  emits("update:modelValue", {
    ...props.modelValue,
    type,
  });
};

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(QInput), {
      "model-value": _ctx.modelValue?.value,
      filled: "",
      label: _ctx.label,
      hint: "URL that is being hit when button is clicked",
      disable: _ctx.isDisabled,
      rules: _ctx.rules,
      "data-test": "ia.buttonaction.value",
      "onUpdate:modelValue": onValueUpdate
    }, null, 8, ["model-value", "label", "disable", "rules"]),
    (!onlyOneTypeOption.value)
      ? (_openBlock(), _createBlock(_component_q_select, {
          key: 0,
          "model-value": selectedActionType.value,
          filled: "",
          hint: "Action which is performed when button is clicked",
          label: actionTypeLabel.value,
          "data-test": "ia.buttonaction.type",
          options: _unref(InAppButtonActionTypeOptions),
          "emit-value": true,
          "display-value": actionTypeValueLabel(selectedActionType.value),
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": onTypeUpdate
        }, null, 8, ["model-value", "label", "options", "display-value", "disable"]))
      : _createCommentVNode("", true)
  ]))
}
}

})