import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "in-app" }
const _hoisted_2 = {
  class: "title",
  "data-test": "ia.preview.title"
}
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "dismiss" }

import inAppPreviewBackgroundUrl from "@/assets/images/inapp-preview-background.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewAlert',
  props: {
    title: {},
    message: {},
    dismissText: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.dismissText), 1)
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})