import { addDays } from "date-fns";

import { InApp, InAppData, InAppPriority } from "@/api/inapps/typedefs/inApp";
import {
  InAppDataAlert,
  InAppDataBottomSheet,
  InAppDataCenterDialog,
  InAppDataConfigBundleConfirm,
  InAppDataConfigBundleSheet,
  InAppDataConfirm,
  InAppDataMaintenanceAction,
  InAppDataSheet,
  InAppDataStartPageCard,
  InAppDataSubscriptionSheet,
  InAppDataWallpaperSheet,
  InAppDataWallpaperSheetBasic,
  InAppDataWebviewPanel,
} from "@/api/inapps/typedefs/inAppData";
import { InAppDisplayWhen } from "@/api/inapps/typedefs/inAppDisplayWhen";
import { InAppType, InAppTypeGuard } from "@/api/inapps/typedefs/inAppTypes";
import { dateObjToString } from "@/lib/dateTime/dateTime";
import { assertNever } from "@/lib/typing";

const IN_APP_END_DATE_DAYS_SHIFT = 30;

export class InAppBuildError extends Error {}

export function buildInAppDataAlert(): InAppDataAlert {
  return {
    type: InAppTypeGuard.keys.Alert.value,
    langCode: "default",
    langName: "default",
    title: null,
    message: null,
    dismissText: null,
    dismissAction: null,
  };
}

function buildInAppDataConfirm(): InAppDataConfirm {
  return {
    type: InAppTypeGuard.keys.Confirm.value,
    langCode: "default",
    langName: "default",
    title: null,
    message: null,
    acceptText: null,
    acceptAction: null,
    cancelText: null,
    cancelAction: null,
  };
}
function buildInAppDataWebviewPanel(): InAppDataWebviewPanel {
  return {
    type: InAppTypeGuard.keys["WebView Panel"].value,
    langCode: "default",
    langName: "default",
    url: null,
    panelSize: "S",
  };
}

export function buildInAppDataSheet(): InAppDataSheet {
  return {
    type: InAppTypeGuard.keys.Sheet.value,
    langCode: "default",
    langName: "default",
    title: null,
    message: null,
    primaryButtonText: null,
    primaryButtonAction: null,
    secondaryButtonText: null,
    secondaryButtonAction: null,
    image: null,
    lottieAnimationFile: null,
  };
}

export function buildInAppDataBottomSheet(): InAppDataBottomSheet {
  return {
    type: InAppTypeGuard.keys["Bottom Sheet"].value,
    langCode: "default",
    langName: "default",
    title: null,
    message: null,
    primaryButtonText: null,
    primaryButtonAction: null,
    secondaryButtonText: null,
    secondaryButtonAction: null,
    image: null,
    lottieAnimationFile: null,
  };
}

function buildInAppDataMaintenanceAction(): InAppDataMaintenanceAction {
  return {
    type: InAppTypeGuard.keys["Maintenance Action"].value,
    langCode: "default",
    langName: "default",
    mainAction: null,
  };
}

function buildInAppDataWallpaperSheet(): InAppDataWallpaperSheet {
  return {
    type: InAppTypeGuard.keys["Wallpaper Sheet"].value,
    langCode: "default",
    langName: "default",
    message: null,
    title: null,
    wallpaperIds: null,
    themeIds: null,
    primaryButtonText: null,
    secondaryButtonText: null,
    secondaryButtonAction: null,
    avatarImage: null,
    avatarImageDark: null,
    centerText: null,
    image: null,
  };
}

function buildInAppDataWallpaperSheetBasic(): InAppDataWallpaperSheetBasic {
  return {
    type: InAppTypeGuard.keys["Wallpaper Sheet Basic"].value,
    langCode: "default",
    langName: "default",
    message: null,
    title: null,
    wallpaperIds: null,
    primaryButtonText: null,
    secondaryButtonText: null,
    secondaryButtonAction: null,
  };
}

function buildInAppDataConfigBundleSheet(): InAppDataConfigBundleSheet {
  return {
    type: InAppTypeGuard.keys["Config Bundle Sheet"].value,
    langCode: "default",
    langName: "default",
    message: null,
    title: null,
    primaryButtonText: null,
    avatarImage: null,
  };
}
function buildInAppDataConfigBundleConfirm(): InAppDataConfigBundleConfirm {
  return {
    type: InAppTypeGuard.keys["Config Bundle Confirm"].value,
    langCode: "default",
    langName: "default",
    title: null,
    message: null,
    subtitle: null,
    submessage: null,
    confirmButtonText: null,
    cancelButtonText: null,
    avatarImage: null,
  };
}
function buildInAppDataCenterDialog(): InAppDataCenterDialog {
  return {
    type: InAppTypeGuard.keys["Center Dialog"].value,
    langCode: "default",
    langName: "default",
    title: null,
    message: null,
    badgeImage: null,
    centerImage: null,
    primaryButtonText: null,
    secondaryButtonText: null,
    primaryButtonAction: null,
    secondaryButtonAction: null,
    lottieAnimationFile: null,
  };
}

export function buildInAppDataSubscriptionSheet(): InAppDataSubscriptionSheet {
  return {
    type: InAppTypeGuard.keys["Subscription Sheet"].value,
    langCode: "default",
    langName: "default",
    title: null,
    message: null,
    primaryButtonText: null,
    primaryButtonAction: null,
    secondaryButtonText: null,
    secondaryButtonAction: null,
    image: null,
    offers: [],
  };
}

export function buildInAppDataStartPageCard(): InAppDataStartPageCard {
  return {
    type: InAppTypeGuard.keys["Start Page Card"].value,
    langCode: "default",
    langName: "default",
    message: null,
    primaryButtonText: null,
    primaryButtonAction: null,
    image: null,
    dismissIntervals: null,
    order: null,
  };
}

export function buildInAppData(inAppType: InAppType): InAppData {
  switch (inAppType) {
    case InAppTypeGuard.keys.Alert.value:
      return buildInAppDataAlert();
    case InAppTypeGuard.keys.Confirm.value:
      return buildInAppDataConfirm();
    case InAppTypeGuard.keys["WebView Panel"].value:
      return buildInAppDataWebviewPanel();
    case InAppTypeGuard.keys.Sheet.value:
      return buildInAppDataSheet();
    case InAppTypeGuard.keys["Bottom Sheet"].value:
      return buildInAppDataBottomSheet();
    case InAppTypeGuard.keys["Maintenance Action"].value:
      return buildInAppDataMaintenanceAction();
    case InAppTypeGuard.keys["Wallpaper Sheet"].value:
      return buildInAppDataWallpaperSheet();
    case InAppTypeGuard.keys["Wallpaper Sheet Basic"].value:
      return buildInAppDataWallpaperSheetBasic();
    case InAppTypeGuard.keys["Config Bundle Sheet"].value:
      return buildInAppDataConfigBundleSheet();
    case InAppTypeGuard.keys["Config Bundle Confirm"].value:
      return buildInAppDataConfigBundleConfirm();
    case InAppTypeGuard.keys["Center Dialog"].value:
      return buildInAppDataCenterDialog();
    case InAppTypeGuard.keys["Subscription Sheet"].value:
      return buildInAppDataSubscriptionSheet();
    case InAppTypeGuard.keys["Start Page Card"].value:
      return buildInAppDataStartPageCard();
    default:
      throw new Error("Unsupported type: " + inAppType);
  }
}

export function buildEmptyInAppDisplayWhen(): InAppDisplayWhen {
  return {
    triggers: {},
    limits: {},
  };
}

export function buildEmptyInApp(
  inAppType: InAppType,
  dateNow = new Date()
): InApp {
  const inAppBaseFields = {
    name: "",
    priority: InAppPriority.LOW,
    startTime: null,
    endTime: dateObjToString(addDays(dateNow, IN_APP_END_DATE_DAYS_SHIFT)),
    isRuntime: false,
    forceNonRuntime: false,
    displayWhen: buildEmptyInAppDisplayWhen(),
    target: null,
    campaignId: null,
    tags: [],
    rolloutStrategyType: null,
    rolloutStrategyWindowSize: null,
  };
  switch (inAppType) {
    case InAppTypeGuard.keys["Rate Dialog"].value:
      return {
        ...inAppBaseFields,
        inAppType: "Rate Dialog",
        inAppData: undefined,
      };
  }
  const inAppData = buildInAppData(inAppType);

  switch (inAppData.type) {
    case "Alert":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Confirm":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "WebView Panel":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Sheet":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Bottom Sheet":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Maintenance Action":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Wallpaper Sheet":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Wallpaper Sheet Basic":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Config Bundle Sheet":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Config Bundle Confirm":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Center Dialog":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Subscription Sheet":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    case "Start Page Card":
      return {
        ...inAppBaseFields,
        inAppData: [inAppData],
        inAppType: inAppData.type,
      };
    default: {
      assertNever(inAppData);
    }
  }
}

export const DEFAULT_NEW_INAPP_TYPE: InAppType = "Alert";
