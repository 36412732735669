export enum SelectTypePerFrequency {
  EVER = "ever",
  PER_SESSION = "per_session",
  PER_SECOND = "per_second",
  PER_MINUTE = "per_minute",
  PER_HOUR = "per_hour",
  PER_DAY = "per_day",
  PER_WEEK = "per_week",
  PER_MONTH = "per_month",
}

export const SelectTypePerFrequencyLabels: Record<
  SelectTypePerFrequency,
  string
> = {
  [SelectTypePerFrequency.EVER]: "time(s) ever",
  [SelectTypePerFrequency.PER_SESSION]: "time(s) per session",
  [SelectTypePerFrequency.PER_SECOND]: "time(s) per second",
  [SelectTypePerFrequency.PER_MINUTE]: "time(s) per minute",
  [SelectTypePerFrequency.PER_HOUR]: "time(s) per hour",
  [SelectTypePerFrequency.PER_DAY]: "time(s) per day",
  [SelectTypePerFrequency.PER_WEEK]: "time(s) per week",
  [SelectTypePerFrequency.PER_MONTH]: "time(s) per month",
};
