import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { LottieMetadata } from "@/api/lottie/typedefs";
import { ValidationRule } from "@/lib/rules/typedefs";

import LottieSelect from "./LottieSelect.vue";
import { useLottieSelect } from "./useLottieSelect";


export default /*@__PURE__*/_defineComponent({
  __name: 'LottieSelectWrapper',
  props: {
    modelValue: {},
    hint: { default: null },
    label: { default: null },
    disable: { type: Boolean, default: false },
    rules: { default: () => [] },
    isAddLottieButtonVisible: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const {
  tryToUploadLottie,
  lottieOptions,
  fetchLottieOptions,
  fetchMoreLottieOptions,
} = useLottieSelect();

const uploadSelectedFile = async (file: File) => {
  const lottie = await tryToUploadLottie(file);
  if (lottie) emit("update:modelValue", lottie);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LottieSelect, _mergeProps(_ctx.$attrs, {
    "model-value": props.modelValue,
    label: props.label,
    hint: props.hint,
    disable: props.disable,
    options: _unref(lottieOptions),
    rules: props.rules,
    "is-add-lottie-button-visible": props.isAddLottieButtonVisible,
    onFilter: _unref(fetchLottieOptions),
    onVirtualScroll: _unref(fetchMoreLottieOptions),
    onFileSelected: uploadSelectedFile,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }), null, 16, ["model-value", "label", "hint", "disable", "options", "rules", "is-add-lottie-button-visible", "onFilter", "onVirtualScroll"]))
}
}

})