import { httpClient, throwIfBadResponse } from "@/lib/http";

import { convertExistingInApp } from "./converters";
import { ExistingInApp } from "./typedefs/inApp";

export async function activateInApp(
  productLine: number,
  id: number
): Promise<ExistingInApp> {
  const response = await httpClient.post(
    `/api/inapps/activate-inapp/${productLine}/${id}`
  );

  await throwIfBadResponse(response);

  const data = await response.json();
  return convertExistingInApp(data);
}
