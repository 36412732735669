import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-center-dialog-data" }

import { computed } from "vue";

import { InAppButtonAction } from "@/api/inapps/typedefs/inAppButtonAction";
import { InAppDataCenterDialog } from "@/api/inapps/typedefs/inAppData";
import InAppButtonActionInput from "@/components/inapps/detail/InAppButtonActionInput.vue";
import { buildInAppDataInputRules } from "@/components/inapps/detail/inappdata/dataRules";
import ImageSelect from "@/components/shared/imageselect/ImageSelect.vue";
import LottieSelectWrapper from "@/components/shared/lottieselect/LottieSelectWrapper.vue";
import { bindPropToRef } from "@/lib/propToRef";
import {
  isNonEmptyString,
  isNonWhiteCharOnlyString,
} from "@/lib/rules/stringValidatior";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataCenterDialog',
  props: {
    modelValue: {},
    isDisabled: { type: Boolean },
    isAddBadgeImageVisible: { type: Boolean },
    isAddCenterImageVisible: { type: Boolean },
    isAddLottieButtonVisible: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const POSSIBLE_ACTION_TYPES: InAppButtonAction["type"][] = [
  "url",
  "report_sd",
  "delete_sd",
];

function onInAppUpdate(changedData: Partial<InAppDataCenterDialog>) {
  emit("update:model-value", { ...props.modelValue, ...changedData });
}

const inAppDataLocal = bindPropToRef<InAppDataCenterDialog>(
  () => props.modelValue,
  onInAppUpdate
);

// Input validation
const messageInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);
const titleInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);
const primaryButtonTextInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[9] || (_cache[9] = _createElementVNode("div", {
          "data-test": "ia.centerdialogdata.header",
          class: "text-h6 q-mb-sm"
        }, " Center Dialog data ", -1)),
        _createVNode(_component_q_input, {
          modelValue: _unref(inAppDataLocal).title,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(inAppDataLocal).title) = $event)),
          filled: "",
          label: "Title",
          hint: "Title of your message",
          "data-test": "ia.centerdialog.title",
          disable: _ctx.isDisabled,
          rules: _unref(titleInputRules)
        }, null, 8, ["modelValue", "disable", "rules"]),
        _createVNode(_component_q_input, {
          modelValue: _unref(inAppDataLocal).message,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(inAppDataLocal).message) = $event)),
          filled: "",
          label: "Message",
          hint: "Text inside your message",
          "data-test": "ia.centerdialog.message",
          disable: _ctx.isDisabled,
          rules: _unref(messageInputRules)
        }, null, 8, ["modelValue", "disable", "rules"]),
        _createVNode(ImageSelect, {
          modelValue: _unref(inAppDataLocal).badgeImage,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(inAppDataLocal).badgeImage) = $event)),
          "data-test": "ia.centerdialog.badgeimage",
          "image-constraint-names": ['center_dialog_badge_image'],
          "is-add-image-button-visible": _ctx.isAddBadgeImageVisible,
          hint: "A little badge image in upper right corner",
          label: "Badge image",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-image-button-visible", "disable"]),
        _createVNode(ImageSelect, {
          modelValue: _unref(inAppDataLocal).centerImage,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(inAppDataLocal).centerImage) = $event)),
          "data-test": "ia.centerdialog.centerimage",
          "image-constraint-names": ['center_dialog_center_image'],
          "is-add-image-button-visible": _ctx.isAddCenterImageVisible,
          hint: "The big image in the center of InApp",
          label: "Center image",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-image-button-visible", "disable"]),
        _createVNode(LottieSelectWrapper, {
          modelValue: _unref(inAppDataLocal).lottieAnimationFile,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(inAppDataLocal).lottieAnimationFile) = $event)),
          "is-add-lottie-button-visible": _ctx.isAddLottieButtonVisible,
          "data-test": "ia.centerdialog.lottie",
          hint: "Lottie for your message",
          label: "Lottie",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-lottie-button-visible", "disable"]),
        _createVNode(_component_q_input, {
          modelValue: _unref(inAppDataLocal).primaryButtonText,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(inAppDataLocal).primaryButtonText) = $event)),
          filled: "",
          label: "Primary text",
          hint: "Text of the primary button",
          "data-test": "ia.centerdialog.primarytext",
          disable: _ctx.isDisabled,
          rules: _unref(primaryButtonTextInputRules)
        }, null, 8, ["modelValue", "disable", "rules"]),
        _createVNode(_component_q_input, {
          modelValue: _unref(inAppDataLocal).secondaryButtonText,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(inAppDataLocal).secondaryButtonText) = $event)),
          filled: "",
          label: "Secondary text",
          hint: "Text of the secondary button",
          "data-test": "ia.centerdialog.secondarytext",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "disable"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppDataLocal).primaryButtonAction,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(inAppDataLocal).primaryButtonAction) = $event)),
          label: "Primary Action",
          "data-test": "ia.centerdialog.primaryaction",
          "type-options": POSSIBLE_ACTION_TYPES,
          "is-disabled": _ctx.isDisabled
        }, null, 8, ["modelValue", "is-disabled"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppDataLocal).secondaryButtonAction,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(inAppDataLocal).secondaryButtonAction) = $event)),
          label: "Secondary Action",
          "data-test": "ia.centerdialog.secondaryaction",
          "type-options": POSSIBLE_ACTION_TYPES,
          "is-disabled": _ctx.isDisabled
        }, null, 8, ["modelValue", "is-disabled"])
      ]),
      _: 1
    })
  ]))
}
}

})