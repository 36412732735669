import { convertPreviewResult } from "@/api/preview/converters";
import { PreviewResult } from "@/api/preview/typedefs";
import { httpClient, throwIfBadResponse } from "@/lib/http";

import { convertInAppDataForBackend } from "./converters";
import { NewOrExistingInApp } from "./typedefs/inApp";

export async function inAppPreview(
  productLineId: number,
  inApp: NewOrExistingInApp,
  localization: string
): Promise<PreviewResult> {
  const response = await httpClient.post(
    `/api/inapps/preview-inapp/${productLineId}/${inApp.inAppType}/${localization}`,
    inApp.inAppData ? convertInAppDataForBackend(inApp.inAppData) : undefined
  );
  await throwIfBadResponse(response);

  const data = await response.json();
  return convertPreviewResult(data);
}
