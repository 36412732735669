import { SegmentConfig } from "./segmentConfig";
import { ExprSegment, ExprSegmentType } from "./typedefs";

interface SegmentDefaultData {
  matching?: string;
  value?: string | number;
}

export function segmentDefaultExprSegment(config: SegmentConfig): ExprSegment {
  return {
    type: ExprSegmentType,
    segmentType: config.segmentType,
    ...getDefaultData(config),
  };
}

function getDefaultData(config: SegmentConfig): SegmentDefaultData {
  const data: SegmentDefaultData = {};

  if (config.inputConfig?.inputType === "number") {
    if (config.inputConfig.limiterMin !== undefined) {
      data.value = config.inputConfig.limiterMin;
    } else {
      data.value = 0;
    }
  }

  if (config.matchingConfig !== undefined) {
    data.matching = config.matchingConfig.matchingOptions[0];
  }

  return data;
}
