import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-alert-data" }

import QInput from 'quasar/src/components/input/QInput.js';;
import { computed } from "vue";

import { InAppDataConfirm } from "@/api/inapps/typedefs/inAppData";
import { nonEmptyStringOrNull } from "@/lib/converters";
import { bindPropToRef } from "@/lib/propToRef";
import {
  isNonEmptyString,
  isNonWhiteCharOnlyString,
} from "@/lib/rules/stringValidatior";
import { isValidActionUrl } from "@/lib/rules/urlValidator";

import InAppButtonActionInput from "../InAppButtonActionInput.vue";
import { buildInAppDataInputRules } from "./dataRules";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataConfirm',
  props: {
    modelValue: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function onInAppUpdate(changedData: Partial<InAppDataConfirm>) {
  emit("update:model-value", { ...props.modelValue, ...changedData });
}
const inAppLocal = bindPropToRef<InAppDataConfirm>(
  () => props.modelValue,
  onInAppUpdate
);

const messageInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const titleInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const buttonActionsRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [],
  [isValidActionUrl]
);

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("div", {
          "data-test": "ia.confirmdata.header",
          class: "text-h6 q-mb-sm"
        }, " Confirm data ", -1)),
        _createVNode(_unref(QInput), {
          ref: "titleInputRef",
          "model-value": _unref(inAppLocal).title,
          filled: "",
          label: "Title",
          hint: "Title of your message",
          "data-test": "ia.confirmdata.title",
          disable: _ctx.isDisabled,
          rules: _unref(titleInputRules),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(inAppLocal).title = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(_unref(QInput), {
          ref: "messageInputRef",
          "model-value": _unref(inAppLocal).message,
          filled: "",
          label: "Message",
          hint: "Text inside your message",
          "data-test": "ia.confirmdata.message",
          disable: _ctx.isDisabled,
          rules: _unref(messageInputRules),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(inAppLocal).message = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).acceptText,
          filled: "",
          label: "Accept text",
          hint: "Text of the 'accept' button",
          "data-test": "ia.confirmdata.accepttext",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (
          _unref(inAppLocal).acceptText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).cancelText,
          filled: "",
          label: "Cancel text",
          hint: "Text of the 'cancel' button",
          "data-test": "ia.confirmdata.canceltext",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (
          _unref(inAppLocal).cancelText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppLocal).acceptAction,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(inAppLocal).acceptAction) = $event)),
          "data-test": "ia.confirmdata.acceptaction",
          label: "Accept action",
          "is-disabled": _ctx.isDisabled,
          "type-options": ['url'],
          rules: _unref(buttonActionsRules)
        }, null, 8, ["modelValue", "is-disabled", "rules"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppLocal).cancelAction,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(inAppLocal).cancelAction) = $event)),
          "data-test": "ia.confirmdata.cancelaction",
          label: "Cancel action",
          "is-disabled": _ctx.isDisabled,
          "type-options": ['url'],
          rules: _unref(buttonActionsRules)
        }, null, 8, ["modelValue", "is-disabled", "rules"])
      ]),
      _: 1
    })
  ]))
}
}

})