import { httpClient, throwIfBadResponse } from "@/lib/http";

import { Tag } from "../tags/typedefs";
import { convertExistingInApp, convertInAppDataForBackend } from "./converters";
import { ExistingInApp } from "./typedefs/inApp";

export async function updateInApp(
  productLine: number,
  inApp: ExistingInApp,
  isSuperuserCall = false
): Promise<ExistingInApp> {
  const standardRequestData = {
    name: inApp.name,
    in_app_type: inApp.inAppType,
    priority: inApp.priority,
    start_time: inApp.startTime,
    end_time: inApp.endTime,
    // TODO(PNS-2581): Drop JSON.stringify after changes on backend.
    display_when: JSON.stringify(inApp.displayWhen),
    in_app_data: inApp.inAppData
      ? convertInAppDataForBackend(inApp.inAppData)
      : undefined,
    target: inApp.target,
    campaign_id: inApp.campaignId,
    tags: inApp.tags.map((tag: Tag) => tag.id),
    rollout_strategy_type: inApp.rolloutStrategyType,
    rollout_strategy_window_size: inApp.rolloutStrategyWindowSize,
  };
  const superuserRequestData = {
    ...standardRequestData,
    force_non_runtime: inApp.forceNonRuntime,
  };
  const response = await httpClient.put(
    `/api/inapps/product-lines/${productLine}/inapps/${inApp.id}/`,
    isSuperuserCall ? superuserRequestData : standardRequestData
  );

  await throwIfBadResponse(response);

  return convertExistingInApp(await response.json());
}
