
import QSelect from 'quasar/src/components/select/QSelect.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;
import { defineComponent, onMounted, PropType, Ref, ref } from "vue";

import { LottieMetadata } from "@/api/lottie/typedefs";
import { FilterEventPayload, ScrollEventPayload } from "@/api/typedefs";
import { OnFilter, OnFilterAbortFn, OnFilterDoneFn } from "@/lib/quasar";
import { ValidationRule } from "@/lib/rules/typedefs.js";

const lottieOptionLabel = (lottie: LottieMetadata) => {
  return lottie.name;
};

export default defineComponent({
  props: {
    modelValue: {
      type: null as unknown as PropType<LottieMetadata | null>,
      required: true,
    },
    hint: {
      type: null as unknown as PropType<string | null>,
      required: false,
      default: null,
    },
    label: {
      type: null as unknown as PropType<string | null>,
      required: false,
      default: null,
    },
    rules: {
      type: Array as PropType<ValidationRule<string | null>[]>,
      required: false,
      default: () => [],
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Object as PropType<LottieMetadata[]>,
      required: true,
    },
    isAddLottieButtonVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    "update:modelValue": (v: LottieMetadata | null) => {
      return typeof v === "object" || v === null;
    },
    filter: (v: FilterEventPayload) =>
      v.value === null || typeof v.value === "string",
    virtualScroll: (v: ScrollEventPayload) =>
      v.ref !== undefined && v.to !== undefined,
    fileSelected: (v: File) => typeof v === "object",
  },
  setup(props, { emit }) {
    const fileUploadRef: Ref<HTMLInputElement | null> = ref(null);
    const selectRef: Ref<QSelect | null> = ref(null);

    onMounted(() => {
      if (!fileUploadRef.value) return;
      fileUploadRef.value.oninput = async () => {
        const files = fileUploadRef.value?.files;
        if (!files?.length || files?.length < 1) return;
        emit("fileSelected", files[0]);
        if (fileUploadRef.value) fileUploadRef.value.value = "";
      };
    });

    const openFilePicker = () => {
      fileUploadRef.value?.dispatchEvent(new MouseEvent("click"));
    };

    const onScroll = ({ to, ref }: { to: number; ref: QSelect }) => {
      emit("virtualScroll", { to, ref });
    };

    const onFilter: OnFilter = (
      inputValue: string,
      doneFn: OnFilterDoneFn,
      abortFn: OnFilterAbortFn
    ) => {
      emit("filter", {
        value: inputValue,
        onSuccess: () => doneFn(() => undefined),
        onError: abortFn,
      });
    };

    useFormChild({
      validate: () => selectRef.value?.validate?.() ?? false,
      resetValidation: () => selectRef.value?.resetValidation(),
      requiresQForm: false,
    });

    return {
      selectRef,
      fileUploadRef,
      openFilePicker,
      onFilter,
      onScroll,
      lottieOptionLabel,
    };
  },
});
