import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "segment-add" }

import { SegmentConfig } from "./segmentConfig";
import { segmentDefaultExprSegment } from "./segmentDefaultExprSegment";
import { ExprSegment } from "./typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpressionInputAddSegment',
  props: {
    label: {},
    options: {},
    isDisabled: { type: Boolean }
  },
  emits: ["add"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const onSegmentConfigSelect = (config: SegmentConfig | null) => {
  if (config === null) return;
  emit("add", segmentDefaultExprSegment(config));
};

const optionLabel = (option: SegmentConfig) => {
  return option.label;
};

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_q_select, {
      "model-value": null,
      class: "select",
      dense: "",
      label: props.label,
      options: _ctx.options,
      "option-label": optionLabel,
      disable: _ctx.isDisabled,
      "onUpdate:modelValue": onSegmentConfigSelect
    }, null, 8, ["label", "options", "disable"])
  ]))
}
}

})