import {
  buildSegmentInputConfig,
  SegmentInputConfig,
} from "./segmentInputConfig";
import {
  buildSegmentMatchingConfig,
  SegmentMatchingConfig,
} from "./segmentMatchingConfig";
import { SEGMENT_TYPE_ID_TO_RAW_CONFIGS } from "./segmentRawConfig";
import { SegmentType } from "./segmentType";

export function buildSegmentConfig(segmentType: SegmentType): SegmentConfig {
  const config = SEGMENT_TYPE_ID_TO_RAW_CONFIGS[segmentType];
  const matchingConfig = buildSegmentMatchingConfig(config);
  const inputConfig = buildSegmentInputConfig(config);

  const label = config.label;

  return {
    segmentType,
    label,
    matchingConfig,
    inputConfig,
  };
}

/**
 * SegmentConfig defines:
 *  - UI configuration (how segments are rendered)
 *  - (optional) segment matching UI configuration
 *  - (optional) segment input UI configuration
 *  - (optional) segment input value constraints
 */
export interface SegmentConfig {
  segmentType: SegmentType;
  label: string;

  matchingConfig?: SegmentMatchingConfig;
  inputConfig?: SegmentInputConfig;
}
