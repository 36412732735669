import { computed, watch } from "vue";

import { APIError } from "@/lib/errors";
import { ReadonlyRef } from "@/lib/typing";
import { UseInAppRouteParams } from "@/views/inapps/typedefs";

import { InAppFlowVueMachineState } from "./useInAppFlowVueMachine";
import { UseInAppPermissions } from "./useInAppPermissions";

export type UseInAppOptions = {
  flowState: ReadonlyRef<InAppFlowVueMachineState["value"]>;
  useInAppPermissions: UseInAppPermissions;
  useInAppRouteParams: UseInAppRouteParams;
  notifyErrorCallback: (error: string) => void;
};

export const useInApp = ({
  flowState,
  useInAppPermissions,
  useInAppRouteParams,
  notifyErrorCallback,
}: UseInAppOptions) => {
  watch(
    () => flowState.value.context.error,
    (value) => {
      if (value instanceof APIError) {
        notifyErrorCallback(value.message);
      }
    }
  );

  const isLoading = computed(() => {
    return (
      flowState.value.matches("fetched.loading") ||
      flowState.value.matches("save.loading")
    );
  });

  const errorMessage = computed(() => {
    const error = flowState.value.context.error;
    if (!error) {
      return null;
    } else if (error instanceof APIError) {
      return null;
    } else {
      return error.message;
    }
  });

  const { productLineId } = useInAppRouteParams({});
  const { permissions } = useInAppPermissions({ productLineId });

  const isTagEditable = computed(() => {
    return flowState.value.matches("new") || flowState.value.matches("draft");
  });

  return {
    isLoading,
    isTagEditable,
    errorMessage,
    permissions,
  };
};
