import { computed, watch } from "vue";
import { LocationQuery } from "vue-router";

import { NewOrExistingInApp } from "@/api/inapps/typedefs/inApp";
import { ReadonlyRef } from "@/lib/typing";

const CAMPAIGN_ID_QUERY_PARAM = "campaign_id";

interface UseInAppCampaignIdOptions {
  routeQuery: ReadonlyRef<LocationQuery>;
  inApp: ReadonlyRef<NewOrExistingInApp>;
  onInAppUpdate: (inApp: NewOrExistingInApp) => void;
  onRouteQueryUpdate: (newQuery: LocationQuery) => void;
}

export function useInAppCampaignId({
  routeQuery,
  inApp,
  onInAppUpdate,
  onRouteQueryUpdate,
}: UseInAppCampaignIdOptions) {
  const routerCampaignId = computed(() => {
    const rawCampaignId = routeQuery.value[CAMPAIGN_ID_QUERY_PARAM];
    const campaignId = Number(rawCampaignId);
    if (Number.isInteger(campaignId)) return campaignId;
    return null;
  });

  const inAppCampaignId = computed(() => inApp.value.campaignId);

  watch(
    routerCampaignId,
    () => {
      if (inAppCampaignId.value === routerCampaignId.value) return;
      onInAppUpdate({ ...inApp.value, campaignId: routerCampaignId.value });
    },
    { immediate: true }
  );

  watch(inAppCampaignId, () => {
    if (inAppCampaignId.value === routerCampaignId.value) return;

    const query = { ...routeQuery.value };
    if (inAppCampaignId.value !== null) {
      query[CAMPAIGN_ID_QUERY_PARAM] = String(inAppCampaignId.value);
    } else {
      delete query[CAMPAIGN_ID_QUERY_PARAM];
    }

    onRouteQueryUpdate(query);
  });
}
