import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "display-when" }
const _hoisted_2 = { class: "expression" }

import QCard from 'quasar/src/components/card/QCard.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';;
import { computed, toRefs } from "vue";

import { NewOrExistingInApp } from "@/api/inapps/typedefs/inApp";
import { InAppDisplayWhen } from "@/api/inapps/typedefs/inAppDisplayWhen";
import ExpressionInput from "@/components/shared/expression/ExpressionInput.vue";
import {
  convertExprToJsonLogic,
  convertJsonLogicToExpr,
  JsonLogic,
} from "@/components/shared/expression/jsonLogic";
import {
  Expr,
  ExprOperatorType,
} from "@/components/shared/expression/typedefs";
import { ProductLine } from "@/lib/productLine";

import { useInAppSegmentTypes } from "./useInAppSegmentTypes";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDisplayWhen',
  props: {
    inApp: {},
    productLine: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:in-app"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const { productLine, inApp, isDisabled } = toRefs(props);

const { segmentTypes: triggerSegmentTypes } = useInAppSegmentTypes({
  productLine,
  kind: "trigger",
});

const triggerExpr = computed<Expr>(() =>
  // TODO(PNS-2552): Unsafe casting using `as` due to lack of JsonLogicGuard.
  convertJsonLogicToExpr(inApp.value.displayWhen.triggers as JsonLogic)
);
const onTriggerExprUpdate = (value: Expr) => {
  onDisplayWhenUpdate({
    ...inApp.value.displayWhen,
    triggers: convertExprToJsonLogic(value),
  });
};

const { segmentTypes: limitSegmentTypes } = useInAppSegmentTypes({
  productLine,
  kind: "limit",
});

const limitExpr = computed<Expr>(() =>
  // TODO(PNS-2552): Unsafe casting using `as` due to lack of JsonLogicGuard.
  convertJsonLogicToExpr(inApp.value.displayWhen.limits as JsonLogic)
);

const onLimitExprUpdate = (value: Expr) => {
  onDisplayWhenUpdate({
    ...inApp.value.displayWhen,
    limits: convertExprToJsonLogic(value),
  });
};

const onDisplayWhenUpdate = (displayWhen: InAppDisplayWhen) => {
  emits("update:in-app", {
    ...inApp.value,
    displayWhen,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(QCard), null, {
      default: _withCtx(() => [
        _createVNode(_unref(QCardSection), null, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-h6 q-mb-sm" }, "Display when", -1)),
            _createElementVNode("div", _hoisted_2, [
              (_unref(triggerSegmentTypes).length > 0)
                ? (_openBlock(), _createBlock(ExpressionInput, {
                    key: 0,
                    "is-disabled": _unref(isDisabled),
                    "model-value": triggerExpr.value,
                    label: "Trigger",
                    "available-segment-types": _unref(triggerSegmentTypes),
                    "available-operators": [_unref(ExprOperatorType).OR],
                    "onUpdate:modelValue": onTriggerExprUpdate
                  }, null, 8, ["is-disabled", "model-value", "available-segment-types", "available-operators"]))
                : _createCommentVNode("", true),
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "operator" }, " AND ONLY ", -1)),
              (_unref(limitSegmentTypes).length > 0)
                ? (_openBlock(), _createBlock(ExpressionInput, {
                    key: 1,
                    "is-disabled": _unref(isDisabled),
                    "model-value": limitExpr.value,
                    label: "Limit",
                    "available-segment-types": _unref(limitSegmentTypes),
                    "available-operators": [_unref(ExprOperatorType).AND],
                    "onUpdate:modelValue": onLimitExprUpdate
                  }, null, 8, ["is-disabled", "model-value", "available-segment-types", "available-operators"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})