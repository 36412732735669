import { fetchInAppTarget } from "@/api/inapps/target";
import { ExistingInApp, InAppTarget } from "@/api/inapps/typedefs/inApp";
import { requestInAppValidation } from "@/api/inapps/validationRequest";
import { ValidationStatus } from "@/api/target/typedefs";
import { wait } from "@/lib/wait";

export class TargetValidationError extends Error {}

export async function validateInAppTarget(
  inApp: ExistingInApp,
  productLineId: number,
  statusCheckIntervalMs = 1000,
  maxRetries = 10
): Promise<InAppTarget> {
  await requestInAppValidation(inApp.id, productLineId);

  if (inApp.target === null) {
    throw new TargetValidationError(
      "Cannot validate target of inApp with no target selected"
    );
  }

  let currentRetry = 1;

  while (currentRetry <= maxRetries) {
    const target = await fetchInAppTarget(productLineId, inApp.target);

    await wait(statusCheckIntervalMs);

    if (target.status !== ValidationStatus.VALIDATING) {
      return target;
    }

    currentRetry++;
  }

  throw new TargetValidationError("Failed to validate in app target.");
}
