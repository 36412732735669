import useQuasar from 'quasar/src/composables/use-quasar.js';;

import { fetchLotties } from "@/api/lottie/list";
import { uploadLottie } from "@/api/lottie/upload";
import { usePaginatedSelect } from "@/composables/usePaginatedSelect2";

export function useLottieSelect() {
  const { fetchOptions, fetchMoreOptions, options } = usePaginatedSelect({
    paginatedFetchMethod: (query, page, perPage) =>
      fetchLotties(query, page, perPage),
  });

  const $q = useQuasar();
  const tryToUploadLottie = async (file: File) => {
    try {
      const lottie = await uploadLottie(file);
      $q.notify({
        type: "positive",
        message: "Lottie successfully uploaded",
      });
      return lottie;
    } catch (error: unknown) {
      if (error instanceof Error) {
        $q.notify({
          type: "negative",
          message: error.message,
        });
      } else {
        throw error;
      }
    }
  };

  return {
    fetchLottieOptions: fetchOptions,
    fetchMoreLottieOptions: fetchMoreOptions,
    lottieOptions: options,
    tryToUploadLottie,
  };
}
