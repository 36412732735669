import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';;
import { computed, ref, Ref } from "vue";

import {
  fetchLocalizations,
  LOCALIZATIONS_PER_PAGE,
} from "@/api/localizations/list";
import { Localization } from "@/api/localizations/typedefs";
import { Composable, ReadonlyComputedRef, ReadonlyRef } from "@/lib/typing";

export interface UseInAppLocalizationListOptions {
  scrollRef: ReadonlyRef<QInfiniteScroll | null>;
  localizationClickedCallback: (clickedLocalization: Localization) => void;
  alreadyUsedLocalizations: ReadonlyRef<Localization[]>;
}

interface UseInAppLocalizationListReturn {
  filterText: Ref<string>;
  availableLocalizations: ReadonlyComputedRef<Localization[]>;
  onLocalizationClick: (clickedLocalization: Localization) => void;
  updateFilterText: (newText: string | number | null) => void;
  fetchAvailableLocalizations: (
    index: number,
    done: (stop?: boolean | undefined) => void
  ) => Promise<void>;
}

export const useInAppLocalizationList: Composable<
  UseInAppLocalizationListOptions,
  UseInAppLocalizationListReturn
> = ({ scrollRef, localizationClickedCallback, alreadyUsedLocalizations }) => {
  const filterText = ref("");
  const fetchedLocalizations: Ref<Localization[]> = ref([]);
  const availableLocalizations = computed<Localization[]>(() => {
    return fetchedLocalizations.value.filter((fetchedLocalization) => {
      const alreadyUsedLocalization = alreadyUsedLocalizations.value.find(
        (alreadyUsedLocalization) =>
          alreadyUsedLocalization.id === fetchedLocalization.id
      );
      return !alreadyUsedLocalization;
    });
  });

  const fetchAvailableLocalizations = async (
    index: number,
    done: (stop?: boolean | undefined) => void
  ): Promise<void> => {
    do {
      let newLocalizations: Localization[] = [];
      try {
        newLocalizations = await fetchLocalizations(index, filterText.value);
      } catch (e) {
        done();
        throw e;
      }

      if (!newLocalizations.length) {
        done(true);
        return;
      }

      for (const loc of newLocalizations) {
        if (!fetchedLocalizations.value.includes(loc)) {
          fetchedLocalizations.value.push(loc);
        }
      }

      index++;
    } while (availableLocalizations.value.length < LOCALIZATIONS_PER_PAGE);

    done();
  };

  const onLocalizationClick = (clickedLocalization: Localization) => {
    scrollRef.value?.poll();
    localizationClickedCallback(clickedLocalization);
  };

  const updateFilterText = async (newText: string | number | null) => {
    if (newText === null) {
      newText = "";
    }
    filterText.value = newText.toString();
    fetchedLocalizations.value = [];
    await fetchAvailableLocalizations(1, () => undefined);
    scrollRef.value?.setIndex(1);
    scrollRef.value?.resume();
  };

  return {
    filterText,
    onLocalizationClick,
    updateFilterText,
    availableLocalizations,
    fetchAvailableLocalizations,
  };
};
