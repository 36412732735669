import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-config-bundle-sheet-data" }

import QInput from 'quasar/src/components/input/QInput.js';;
import { computed } from "vue";

import { ImageMetadata } from "@/api/image/typedefs";
import { InAppDataConfigBundleSheet } from "@/api/inapps/typedefs/inAppData";
import ImageSelect from "@/components/shared/imageselect/ImageSelect.vue";
import { nonEmptyStringOrNull } from "@/lib/converters";
import { bindPropToRef } from "@/lib/propToRef";
import {
  isNonEmptyString,
  isNonWhiteCharOnlyString,
} from "@/lib/rules/stringValidatior";

import { buildInAppDataInputRules } from "./dataRules";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataConfigBundleSheet',
  props: {
    modelValue: {},
    isDisabled: { type: Boolean },
    isAddImageButtonVisible: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function onInAppUpdate(changedData: Partial<InAppDataConfigBundleSheet>) {
  emit("update:model-value", { ...props.modelValue, ...changedData });
}
const inAppLocal = bindPropToRef<InAppDataConfigBundleSheet>(
  () => props.modelValue,
  onInAppUpdate
);

const messageInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const titleInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const primaryButtonTextInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const avatarImageInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [(val: ImageMetadata | null) => val !== null || "Avatar image must be set"]
);

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("div", {
          "data-test": "ia.configbundlesheetdata.header",
          class: "text-h6 q-mb-sm"
        }, " Config Bundle Sheet data ", -1)),
        _createVNode(_unref(QInput), {
          ref: "titleInputRef",
          "model-value": _unref(inAppLocal).title,
          filled: "",
          label: "Title",
          hint: "Title of your message",
          "data-test": "ia.configbundlesheetdata.title",
          disable: _ctx.isDisabled,
          rules: _unref(titleInputRules),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(inAppLocal).title = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(_unref(QInput), {
          ref: "messageInputRef",
          "model-value": _unref(inAppLocal).message,
          filled: "",
          label: "Message",
          hint: "Text inside your message",
          "data-test": "ia.configbundlesheetdata.message",
          disable: _ctx.isDisabled,
          rules: _unref(messageInputRules),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(inAppLocal).message = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(_unref(QInput), {
          ref: "primaryButtonTextInputRef",
          "model-value": _unref(inAppLocal).primaryButtonText,
          filled: "",
          label: "Primary text",
          hint: "Text of the primary button",
          "data-test": "ia.configbundlesheetdata.primarytext",
          disable: _ctx.isDisabled,
          rules: _unref(primaryButtonTextInputRules),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (
          _unref(inAppLocal).primaryButtonText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(ImageSelect, {
          modelValue: _unref(inAppLocal).avatarImage,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(inAppLocal).avatarImage) = $event)),
          "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
          "data-test": "ia.configbundlesheetdata.avatarimage",
          "image-constraint-names": ['config_bundle_sheet_avatar'],
          hint: "Avatar image for your message",
          label: "Image",
          disable: _ctx.isDisabled,
          rules: _unref(avatarImageInputRules)
        }, null, 8, ["modelValue", "is-add-image-button-visible", "disable", "rules"])
      ]),
      _: 1
    })
  ]))
}
}

})