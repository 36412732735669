import { computed, ref } from "vue";

import { inAppPreview } from "@/api/inapps/preview";
import { NewOrExistingInApp } from "@/api/inapps/typedefs/inApp";
import { useTestDevices } from "@/composables/useTestDevices";
import { getErrorMessage } from "@/lib/errors";
import { ProductLine } from "@/lib/productLine";
import { QuasarNotify } from "@/lib/quasar";
import { ReadonlyRef } from "@/lib/typing";

interface InAppPreviewOptions {
  $q: QuasarNotify;
  productLine: ReadonlyRef<ProductLine | null>;
}

export interface PreviewConfig {
  isLoading: boolean;
  hasUserTestDevices: boolean;
}

export function useInAppPreview({ $q, productLine }: InAppPreviewOptions) {
  const isLoading = ref(false);
  const requestInAppPreview = async (
    productLineId: number,
    inApp: NewOrExistingInApp,
    localization: string
  ) => {
    isLoading.value = true;
    try {
      const response = await inAppPreview(productLineId, inApp, localization);
      $q.notify({
        type: response.status === "OK" ? "positive" : "negative",
        message: response.message,
      });
    } catch (error) {
      $q.notify({
        type: "negative",
        message: getErrorMessage(error),
      });
      throw error;
    } finally {
      isLoading.value = false;
    }
  };

  const { hasUserTestDevices } = useTestDevices({ productLine });

  const previewConfig = computed<PreviewConfig>(() => ({
    hasUserTestDevices: hasUserTestDevices.value,
    isLoading: isLoading.value,
  }));

  return {
    requestInAppPreview,
    previewConfig,
  };
}
