import script from "./TagsCategories.vue?vue&type=script&lang=ts&setup=true"
export * from "./TagsCategories.vue?vue&type=script&lang=ts&setup=true"

import "./TagsCategories.vue?vue&type=style&index=0&id=54635418&lang=css"

const __exports__ = script;

export default __exports__
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBadge});
