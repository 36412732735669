import { useMachine } from "@xstate/vue";

import { parseEnvBool } from "@/lib/env";

import { InAppFlowMachine } from "./useInAppFlowMachine";

export function useInAppFlowVueMachine(inAppFlowMachine: InAppFlowMachine) {
  const stateMachine = useMachine(inAppFlowMachine, {
    devTools: parseEnvBool(process.env.VUE_APP_DEBUG_XSTATE),
  });
  return stateMachine;
}

export type InAppFlowVueMachine = ReturnType<typeof useInAppFlowVueMachine>;
export type InAppFlowVueMachineState = InAppFlowVueMachine["state"];
