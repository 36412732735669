import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { InAppData, NewOrExistingInApp } from "@/api/inapps/typedefs/inApp";
import { InAppTypeGuard } from "@/api/inapps/typedefs/inAppTypes";
import InAppDataAlertComponent from "@/components/inapps/detail/inappdata/InAppDataAlert.vue";
import InAppDataBottomSheetComponent from "@/components/inapps/detail/inappdata/InAppDataBottomSheet.vue";
import InAppDataCenterDialogComponent from "@/components/inapps/detail/inappdata/InAppDataCenterDialog.vue";
import InAppDataConfigBundleConfirmComponent from "@/components/inapps/detail/inappdata/InAppDataConfigBundleConfirm.vue";
import InAppDataConfigBundleSheetComponent from "@/components/inapps/detail/inappdata/InAppDataConfigBundleSheet.vue";
import InAppDataConfirmComponent from "@/components/inapps/detail/inappdata/InAppDataConfirm.vue";
import InAppDataMaintenanceActionComponent from "@/components/inapps/detail/inappdata/InAppDataMaintenanceAction.vue";
import InAppDataSheetComponent from "@/components/inapps/detail/inappdata/InAppDataSheet.vue";
import InAppDataStartPageCardComponent from "@/components/inapps/detail/inappdata/InAppDataStartPageCard.vue";
import InAppDataSubscriptionSheetComponent from "@/components/inapps/detail/inappdata/InAppDataSubscriptionSheet.vue";
import InAppDataWallpaperSheetComponent from "@/components/inapps/detail/inappdata/InAppDataWallpaperSheet.vue";
import InAppDataWallpaperSheetBasicComponent from "@/components/inapps/detail/inappdata/InAppDataWallpaperSheetBasic.vue";
import InAppDataWebviewPanelComponent from "@/components/inapps/detail/inappdata/InAppDataWebviewPanel.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppData',
  props: {
    isDisabled: { type: Boolean },
    isAddImageButtonVisible: { type: Boolean },
    isAddLottieButtonVisible: { type: Boolean },
    inAppData: {},
    inApp: {}
  },
  emits: ["updateInAppData"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const updateInAppData = (data: InAppData) => emit("updateInAppData", data);

return (_ctx: any,_cache: any) => {
  return (_ctx.inAppData?.type === _unref(InAppTypeGuard).keys.Alert.value)
    ? (_openBlock(), _createBlock(InAppDataAlertComponent, {
        key: 0,
        "data-test": "ia.alertdata",
        "model-value": _ctx.inAppData,
        "is-disabled": _ctx.isDisabled,
        "onUpdate:modelValue": updateInAppData
      }, null, 8, ["model-value", "is-disabled"]))
    : (_ctx.inAppData?.type === _unref(InAppTypeGuard).keys.Confirm.value)
      ? (_openBlock(), _createBlock(InAppDataConfirmComponent, {
          key: 1,
          "data-test": "ia.confirmdata",
          "model-value": _ctx.inAppData,
          "is-disabled": _ctx.isDisabled,
          "onUpdate:modelValue": updateInAppData
        }, null, 8, ["model-value", "is-disabled"]))
      : (_ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Center Dialog'].value)
        ? (_openBlock(), _createBlock(InAppDataCenterDialogComponent, {
            key: 2,
            "data-test": "ia.centerdialog",
            "model-value": _ctx.inAppData,
            "is-disabled": _ctx.isDisabled,
            "is-add-lottie-button-visible": _ctx.isAddLottieButtonVisible,
            "is-add-center-image-visible": _ctx.isAddImageButtonVisible,
            "is-add-badge-image-visible": _ctx.isAddImageButtonVisible,
            "onUpdate:modelValue": updateInAppData
          }, null, 8, ["model-value", "is-disabled", "is-add-lottie-button-visible", "is-add-center-image-visible", "is-add-badge-image-visible"]))
        : (_ctx.inAppData?.type === _unref(InAppTypeGuard).keys.Sheet.value)
          ? (_openBlock(), _createBlock(InAppDataSheetComponent, {
              key: 3,
              "data-test": "ia.sheetdata",
              "model-value": _ctx.inAppData,
              "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
              "is-add-lottie-button-visible": _ctx.isAddLottieButtonVisible,
              "is-disabled": _ctx.isDisabled,
              "onUpdate:modelValue": updateInAppData
            }, null, 8, ["model-value", "is-add-image-button-visible", "is-add-lottie-button-visible", "is-disabled"]))
          : (_ctx.inAppData?.type === _unref(InAppTypeGuard).keys['WebView Panel'].value)
            ? (_openBlock(), _createBlock(InAppDataWebviewPanelComponent, {
                key: 4,
                "data-test": "ia.webviewpaneldata",
                "model-value": _ctx.inAppData,
                "is-disabled": _ctx.isDisabled,
                "onUpdate:modelValue": updateInAppData
              }, null, 8, ["model-value", "is-disabled"]))
            : (_ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Bottom Sheet'].value)
              ? (_openBlock(), _createBlock(InAppDataBottomSheetComponent, {
                  key: 5,
                  "data-test": "ia.bottomsheetdata",
                  "model-value": _ctx.inAppData,
                  "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
                  "is-add-lottie-button-visible": _ctx.isAddLottieButtonVisible,
                  "is-disabled": _ctx.isDisabled,
                  "onUpdate:modelValue": updateInAppData
                }, null, 8, ["model-value", "is-add-image-button-visible", "is-add-lottie-button-visible", "is-disabled"]))
              : (
      _ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Maintenance Action'].value
    )
                ? (_openBlock(), _createBlock(InAppDataMaintenanceActionComponent, {
                    key: 6,
                    "data-test": "ia.maintenanceactiondata",
                    "model-value": _ctx.inAppData,
                    "is-disabled": _ctx.isDisabled,
                    "onUpdate:modelValue": updateInAppData
                  }, null, 8, ["model-value", "is-disabled"]))
                : (_ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Wallpaper Sheet'].value)
                  ? (_openBlock(), _createBlock(InAppDataWallpaperSheetComponent, {
                      key: 7,
                      "data-test": "ia.wallpapersheet",
                      "model-value": _ctx.inAppData,
                      "is-add-avatar-image-button-visible": _ctx.isAddImageButtonVisible,
                      "is-add-avatar-image-dark-button-visible": _ctx.isAddImageButtonVisible,
                      "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
                      "is-disabled": _ctx.isDisabled,
                      "onUpdate:modelValue": updateInAppData
                    }, null, 8, ["model-value", "is-add-avatar-image-button-visible", "is-add-avatar-image-dark-button-visible", "is-add-image-button-visible", "is-disabled"]))
                  : (
      _ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Wallpaper Sheet Basic'].value
    )
                    ? (_openBlock(), _createBlock(InAppDataWallpaperSheetBasicComponent, {
                        key: 8,
                        "data-test": "ia.wallpapersheetbasic",
                        "model-value": _ctx.inAppData,
                        "is-disabled": _ctx.isDisabled,
                        "onUpdate:modelValue": updateInAppData
                      }, null, 8, ["model-value", "is-disabled"]))
                    : (
      _ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Config Bundle Sheet'].value
    )
                      ? (_openBlock(), _createBlock(InAppDataConfigBundleSheetComponent, {
                          key: 9,
                          "data-test": "ia.configbundlesheet",
                          "model-value": _ctx.inAppData,
                          "is-disabled": _ctx.isDisabled,
                          "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
                          "onUpdate:modelValue": updateInAppData
                        }, null, 8, ["model-value", "is-disabled", "is-add-image-button-visible"]))
                      : (
      _ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Subscription Sheet'].value
    )
                        ? (_openBlock(), _createBlock(InAppDataSubscriptionSheetComponent, {
                            key: 10,
                            "data-test": "ia.subscriptionsheet",
                            "model-value": _ctx.inAppData,
                            "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
                            "is-disabled": _ctx.isDisabled,
                            "in-app": _ctx.inApp,
                            "onUpdate:modelValue": updateInAppData
                          }, null, 8, ["model-value", "is-add-image-button-visible", "is-disabled", "in-app"]))
                        : (_ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Start Page Card'].value)
                          ? (_openBlock(), _createBlock(InAppDataStartPageCardComponent, {
                              key: 11,
                              "data-test": "ia.startpagecard",
                              "model-value": _ctx.inAppData,
                              "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
                              "is-disabled": _ctx.isDisabled,
                              "in-app": _ctx.inApp,
                              "onUpdate:modelValue": updateInAppData
                            }, null, 8, ["model-value", "is-add-image-button-visible", "is-disabled", "in-app"]))
                          : (
      _ctx.inAppData?.type === _unref(InAppTypeGuard).keys['Config Bundle Confirm'].value
    )
                            ? (_openBlock(), _createBlock(InAppDataConfigBundleConfirmComponent, {
                                key: 12,
                                "data-test": "ia.configbundleconfirm",
                                "model-value": _ctx.inAppData,
                                "is-disabled": _ctx.isDisabled,
                                "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
                                "onUpdate:modelValue": updateInAppData
                              }, null, 8, ["model-value", "is-disabled", "is-add-image-button-visible"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 13 }, [
                                _createTextVNode("Unexpected in app data type.")
                              ], 64))
}
}

})