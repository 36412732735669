import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-center q-my-md" }

import debounce from 'quasar/src/utils/debounce.js';import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';;
import { Ref, ref, toRef } from "vue";

import { Localization } from "@/api/localizations/typedefs";
import { useInAppLocalizationList } from "@/components/inapps/detail/useInAppLocalizationList";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppLocalizationList',
  props: {
    alreadyUsedLocalizations: {}
  },
  emits: ["localization-clicked", "filter-changed"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const scroll: Ref<QInfiniteScroll | null> = ref(null);

const {
  onLocalizationClick,
  updateFilterText,
  availableLocalizations,
  filterText,
  fetchAvailableLocalizations,
} = useInAppLocalizationList({
  scrollRef: scroll,
  localizationClickedCallback: (clickedLocalization) =>
    emit("localization-clicked", clickedLocalization),
  alreadyUsedLocalizations: toRef(props, "alreadyUsedLocalizations"),
});

const debouncedUpdateFilterText = debounce(updateFilterText, 500);

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_spinner_tail = _resolveComponent("q-spinner-tail")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_input, {
      "model-value": _unref(filterText),
      label: "Filter by name",
      outlined: "",
      "data-test": "ia.localization.dropdown__search",
      autofocus: "",
      "onUpdate:modelValue": _unref(debouncedUpdateFilterText),
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(_component_q_list, null, {
      default: _withCtx(() => [
        _createVNode(_unref(QInfiniteScroll), {
          ref_key: "scroll",
          ref: scroll,
          offset: 50,
          onLoad: _unref(fetchAvailableLocalizations)
        }, {
          loading: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_q_spinner_tail)
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(availableLocalizations), (dropdownLocalization) => {
              return (_openBlock(), _createBlock(_component_q_item, {
                key: dropdownLocalization.id,
                "data-test": "ia.localization.select-item",
                clickable: "",
                onClick: ($event: any) => (_unref(onLocalizationClick)(dropdownLocalization))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(dropdownLocalization.text), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["onLoad"])
      ]),
      _: 1
    })
  ]))
}
}

})