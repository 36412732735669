import { useMachine } from "@xstate/vue";

import { parseEnvBool } from "@/lib/env";

import { InAppTargetFlowMachine } from "./useInAppTargetFlowMachine";

export function useInAppTargetFlowVueMachine(
  inAppTargetFlowMachine: InAppTargetFlowMachine
) {
  const stateMachine = useMachine(inAppTargetFlowMachine, {
    devTools: parseEnvBool(process.env.VUE_APP_DEBUG_XSTATE),
  });
  return stateMachine;
}

export type InAppTargetFlowVueMachine = ReturnType<
  typeof useInAppTargetFlowVueMachine
>;
export type InAppTargetFlowVueMachineState = InAppTargetFlowVueMachine["state"];
