import { useInAppDeleteDialog } from "@/components/inapps/dialogs/useInAppDeleteDialog";
import { InAppAction } from "@/components/inapps/typedefs";
import { useCloneDialog } from "@/components/shared/clonedialog/useCloneDialog";
import { ProductLine } from "@/lib/productLine";
import { assertNever, ReadonlyRef } from "@/lib/typing";

interface UseInAppActionsOptions {
  onSaveCallback: () => void;
  onValidateCallback: () => void;
  onActivateCallback: () => void;
  onPauseCallback: () => void;
  onDeleteCallback: (reason: string) => void;
  onReactivateCallback: () => void;
  onFinishCallback: () => void;
  onCloneCallback: (cloneIntoProductLineId: number) => void;
  defaultProductLine: ReadonlyRef<ProductLine | null>;
  productLinesUserCanCloneInto: ReadonlyRef<ProductLine[]>;
}
export function useInAppActions(options: UseInAppActionsOptions) {
  const { openDialog: openInAppDeleteDialog } = useInAppDeleteDialog({
    onOk: (reason) => options.onDeleteCallback(reason),
  });

  const { openDialog: openCloneDialog } = useCloneDialog({
    defaultProductLine: options.defaultProductLine,
    productLineOptions: options.productLinesUserCanCloneInto,
    onOk: async ({ productLine }) => {
      options.onCloneCallback(productLine);
    },
  });

  function onAction(action: Exclude<InAppAction, "Preview">) {
    switch (action) {
      case "Save": {
        options.onSaveCallback();
        break;
      }
      case "Delete": {
        openInAppDeleteDialog();
        break;
      }
      case "Validate": {
        options.onValidateCallback();
        break;
      }
      case "Activate": {
        options.onActivateCallback();
        break;
      }
      case "Pause": {
        options.onPauseCallback();
        break;
      }
      case "Reactivate": {
        options.onReactivateCallback();
        break;
      }
      case "Finish": {
        options.onFinishCallback();
        break;
      }
      case "Clone": {
        openCloneDialog();
        break;
      }
      default:
        assertNever(action);
    }
  }

  return { onAction };
}
