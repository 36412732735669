import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-right q-mb-md" }
const _hoisted_2 = { class: "flex items-center no-wrap" }
const _hoisted_3 = { class: "q-pa-xs" }
const _hoisted_4 = {
  key: 0,
  class: "q-mt-md text-negative"
}
const _hoisted_5 = { role: "alert" }
const _hoisted_6 = {
  key: 1,
  class: "q-mt-md"
}

import QBtn from 'quasar/src/components/btn/QBtn.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;
import type { QTableColumn } from "quasar";
import { computed, ComputedRef, toRef } from "vue";

import SubscriptionOffer from "@/components/inapps/detail/inappdata/SubscriptionOffer.vue";
import { useSubscriptionOffers } from "@/components/inapps/detail/inappdata/useSubscriptionOffers";
import { SubscriptionOfferData } from "@/lib/inapps/inAppDataTypes";

export type SubscriptionOffersProps = {
  modelValue: SubscriptionOfferData[];
  isDisabled: boolean;
  inAppEndDate: Date | null;
  isRequired: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SubscriptionOffers',
  props: {
    modelValue: {},
    isDisabled: { type: Boolean },
    inAppEndDate: {},
    isRequired: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const columns: QTableColumn[] = [
  { name: "id", label: "Offer ID", align: "left", field: "id", sortable: true },
  {
    name: "productId",
    label: "Product ID",
    align: "left",
    field: "productId",
    sortable: true,
  },
  {
    name: "expirationTime",
    label: "Expiration Time",
    align: "left",
    field: "expirationTime",
    sortable: true,
  },
  {
    name: "isPersonalized",
    label: "Is Personalized?",
    align: "left",
    field: "isPersonalized",
    sortable: true,
  },
  { name: "action", label: "Action", align: "left", field: "action" },
];

const {
  addOffer,
  editOffer,
  deleteOffer,
  onSubmit,
  onCancel,
  validate,
  resetValidation,
  currentOffer,
  currentOfferIndex,
  error,
} = useSubscriptionOffers({
  isDisabled: toRef(props, "isDisabled"),
  inAppEndDate: toRef(props, "inAppEndDate"),
  isRequired: toRef(props, "isRequired"),
  onOffersChange: (value: SubscriptionOfferData[]) => {
    emit("update:model-value", value);
  },
  offers: toRef(props, "modelValue"),
});

const otherProductIds: ComputedRef<string[]> = computed(() => {
  const otherOffers = [...props.modelValue];
  if (currentOfferIndex.value !== null) {
    otherOffers.splice(currentOfferIndex.value, 1);
  }

  return otherOffers.map((offer: SubscriptionOfferData) => offer.productId);
});

useFormChild({
  validate,
  resetValidation,
});

return (_ctx: any,_cache: any) => {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_unref(currentOffer) === null)
        ? (_openBlock(), _createBlock(_unref(QBtn), {
            key: 0,
            class: "q-ml-auto",
            label: "Add Offer",
            "data-test": "ia.offers.add",
            disable: _ctx.isDisabled,
            onClick: _unref(addOffer)
          }, null, 8, ["disable", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_q_table, {
      rows: props.modelValue,
      columns: columns,
      "hide-pagination": true,
      "row-key": "id",
      "data-test": "ia.offers.table",
      "no-data-label": "There are no offers yet"
    }, {
      "body-cell-isPersonalized": _withCtx((colProps) => [
        _createElementVNode("td", null, [
          _createVNode(_component_q_checkbox, {
            "data-test": "ia.offers.row.ispersonalized",
            disable: true,
            "model-value": colProps.row.isPersonalized
          }, null, 8, ["model-value"])
        ])
      ]),
      "body-cell-action": _withCtx((colProps) => [
        _createElementVNode("td", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              (!_ctx.isDisabled)
                ? (_openBlock(), _createBlock(_unref(QBtn), {
                    key: 0,
                    round: "",
                    icon: "edit",
                    size: "xs",
                    "data-test": "ia.offers.row.edit",
                    onClick: ($event: any) => (_unref(editOffer)(colProps.rowIndex))
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("span", null, [
              (!_ctx.isDisabled)
                ? (_openBlock(), _createBlock(_unref(QBtn), {
                    key: 0,
                    round: "",
                    icon: "delete",
                    size: "xs",
                    "data-test": "ia.offers.row.delete",
                    onClick: ($event: any) => (_unref(deleteOffer)(colProps.rowIndex))
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["rows"]),
    (_unref(error))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(error)), 1)
        ]))
      : _createCommentVNode("", true),
    (_unref(currentOffer) !== null && !_ctx.isDisabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(SubscriptionOffer, {
            "model-value": _unref(currentOffer),
            "in-app-end-date": _ctx.inAppEndDate,
            "other-product-ids": otherProductIds.value,
            "onUpdate:modelValue": _unref(onSubmit),
            onCancel: _unref(onCancel)
          }, null, 8, ["model-value", "in-app-end-date", "other-product-ids", "onUpdate:modelValue", "onCancel"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})