import {
  ExistingInApp,
  isExistingInApp,
  NewOrExistingInApp,
} from "./typedefs/inApp";

class InAppCastError extends Error {}

export function castExistingInApp(inApp: NewOrExistingInApp): ExistingInApp {
  if (!isExistingInApp(inApp)) {
    throw new InAppCastError(`Expected ${inApp} to be existing in app`);
  }
  return inApp;
}
