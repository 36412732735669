import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-start-page-card-data" }

import { computed } from "vue";

import { NewOrExistingInApp } from "@/api/inapps/typedefs/inApp";
import { InAppDataStartPageCard } from "@/api/inapps/typedefs/inAppData";
import ImageSelect from "@/components/shared/imageselect/ImageSelect.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import { nonEmptyStringOrNull } from "@/lib/converters";
import { bindPropToRef } from "@/lib/propToRef";
import { isStrCommaSeparatedListOfInts } from "@/lib/rules/idsValidator";
import { isImageSelected } from "@/lib/rules/imageValidator";
import {
  isIntegerOrEmpty,
  isNonEmptyIntegerField,
} from "@/lib/rules/integerValidator";
import {
  isNonEmptyString,
  isNonWhiteCharOnlyString,
} from "@/lib/rules/stringValidatior";
import { isValidActionUrl } from "@/lib/rules/urlValidator";

import InAppButtonActionInput from "../InAppButtonActionInput.vue";
import { buildInAppDataInputRules } from "./dataRules";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataStartPageCard',
  props: {
    inApp: {},
    modelValue: {},
    isAddImageButtonVisible: { type: Boolean },
    isDisabled: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const inAppLocal = bindPropToRef<InAppDataStartPageCard>(
  () => props.modelValue,
  (changedData: Partial<InAppDataStartPageCard>) => {
    emit("update:model-value", { ...props.modelValue, ...changedData });
  }
);

const messageRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const primaryButtonTextRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const buttonActionsRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString],
  [isValidActionUrl]
);

const orderRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyIntegerField],
  [isIntegerOrEmpty]
);

const imageInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isImageSelected]
);

function convertEvent(value: string | null | number): string {
  if (value === null) {
    return "";
  } else if (typeof value === "number") {
    return value.toString();
  }
  return value.replace(/\s/g, "");
}

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("div", {
          "data-test": "ia.startpagecarddata.header",
          class: "text-h6 q-mb-sm"
        }, " Start Page Card data ", -1)),
        _createVNode(ImageSelect, {
          modelValue: _unref(inAppLocal).image,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(inAppLocal).image) = $event)),
          "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
          "data-test": "ia.startpagecarddata.image",
          "image-constraint-names": ['start_page_card_image'],
          hint: "Image for your message",
          label: "Image",
          rules: _unref(imageInputRules),
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-image-button-visible", "rules", "disable"]),
        _createVNode(_component_q_input, {
          "model-value": _unref(inAppLocal).message,
          filled: "",
          label: "Message",
          hint: "Text inside your message",
          "data-test": "ia.startpagecarddata.message",
          disable: _ctx.isDisabled,
          rules: _unref(messageRules),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(inAppLocal).message = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(_component_q_input, {
          "model-value": _unref(inAppLocal).primaryButtonText,
          filled: "",
          label: "Primary text",
          hint: "Text of the primary button",
          "data-test": "ia.startpagecarddata.primarytext",
          disable: _ctx.isDisabled,
          rules: _unref(primaryButtonTextRules),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (
          _unref(inAppLocal).primaryButtonText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppLocal).primaryButtonAction,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(inAppLocal).primaryButtonAction) = $event)),
          label: "Primary Button action",
          "is-disabled": _ctx.isDisabled,
          "type-options": ['url'],
          "data-test": "ia.startpagecarddata.primaryaction",
          rules: _unref(buttonActionsRules)
        }, null, 8, ["modelValue", "is-disabled", "rules"]),
        _createVNode(NumberInput, {
          modelValue: _unref(inAppLocal).order,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(inAppLocal).order) = $event)),
          modelModifiers: { number: true },
          "data-test": "ia.startpagecarddata.order",
          disable: _ctx.isDisabled,
          type: "number",
          label: "Order",
          hint: "Client will determine which in-app should be shown based on this order.",
          filled: "",
          min: "0",
          "extra-rules": _unref(orderRules)
        }, null, 8, ["modelValue", "disable", "extra-rules"]),
        _createVNode(_component_q_input, {
          "model-value": _unref(inAppLocal).dismissIntervals,
          filled: "",
          label: "Dismiss Intervals",
          hint: "Comma separated hours between dismisses, i.e.: 1, 5, 20",
          disable: _ctx.isDisabled,
          "data-test": "ia.startpagecarddata.dismissintervals",
          rules: [_unref(isStrCommaSeparatedListOfInts)],
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_unref(inAppLocal).dismissIntervals = convertEvent($event)))
        }, null, 8, ["model-value", "disable", "rules"])
      ]),
      _: 1
    })
  ]))
}
}

})