import { ExistingInApp } from "@/api/inapps/typedefs/inApp";
import { httpClient, throwIfBadResponse } from "@/lib/http";

import { convertExistingInApp } from "./converters";

export async function fetchInApp(
  productLineId: number,
  inAppId: number
): Promise<ExistingInApp> {
  const url = `/api/inapps/product-lines/${productLineId}/inapps/${inAppId}/`;
  const response = await httpClient.get(url);

  await throwIfBadResponse(response);

  return convertExistingInApp(await response.json());
}
