import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "add-localization-button" }
const _hoisted_2 = {
  class: "text-weight-medium q-pr-sm",
  "data-test": "ia.localization.tabname"
}

import FileSaver from "file-saver";
import QFile from 'quasar/src/components/file/QFile.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ref, toRef } from "vue";

import { InAppType } from "@/api/inapps";
import { InAppData } from "@/api/inapps/typedefs/inApp";
import { InAppDataLocalizationUploadResult } from "@/api/inapps/typedefs/inAppData";
import { Localization } from "@/api/localizations/typedefs";
import InAppLocalizationList from "@/components/inapps/detail/InAppLocalizationList.vue";
import { useInAppDataLocalization } from "@/components/inapps/useInAppDataLocalization";
import {
  freeformLocalizationFromXlsx,
  localizationXlsxTemplateFromInapp,
} from "@/lib/xlsx";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataLocalization',
  props: {
    inAppData: {},
    inAppType: {},
    selectedLocalization: {},
    isDisabled: { type: Boolean, default: false },
    dataTest: { default: "ia.localization" }
  },
  emits: ["addLocalizationTab", "editLocalizationTab", "update:selectedLocalization", "deleteLocalizationTab", "acceptLocalizationUploadResult"],
  setup(__props: any, { emit: __emit }) {

const $q = useQuasar();

const props = __props;

const localizationQFileRef = ref<QFile | null>(null);
const localizationUploadedFile = ref<File | null>();
const localizationUploadOverrideConfirm = ref<boolean>(false);
const localizationUploadResult = ref<InAppDataLocalizationUploadResult>({});

const emit = __emit;

function addLocalizationTab(localization: Localization): void {
  emit("addLocalizationTab", localization.id, localization.text);
}

function triggerLocalizationUpload(): void {
  // note: clearing current file so we can trigger upload for the same file
  // multiple times. Seemingly this isn't necessary as processing the same
  // should yield the same result but user may do edits in the meantime
  // and may want to reset the form to earlier state by attempting the
  // same upload again.
  localizationUploadedFile.value = undefined;
  localizationQFileRef.value?.pickFiles();
}

function downloadLocalization(): void {
  const defaultInappData = props.inAppData[0];
  const fileName = `${defaultInappData.type}-localization-template.xlsx`;
  localizationXlsxTemplateFromInapp(props.inAppData).then((blob) =>
    FileSaver.saveAs(blob, fileName)
  );
}

function localizationFilePicked(e: Event): void {
  const target = e.target as HTMLInputElement;

  if (target?.files?.length) {
    freeformLocalizationFromXlsx(target.files[0])
      .then((result: InAppDataLocalizationUploadResult) => {
        localizationUploadResult.value = result;
        localizationUploadOverrideConfirm.value = true;
      })
      .catch((reason) => {
        $q.notify(`Could not process the localizations file: ${reason}`);
      });
  }
}

function changeLocalization(
  originalLocalization: string,
  clickedLocalization: Localization
) {
  emit(
    "editLocalizationTab",
    originalLocalization,
    clickedLocalization.id,
    clickedLocalization.text
  );
}

function deleteLocalization(localization: string): void {
  emit("deleteLocalizationTab", localization);
}

const { isDefaultLocalization, alreadyUsedLocalizations } =
  useInAppDataLocalization({
    inAppDatas: toRef(props, "inAppData"),
  });

return (_ctx: any,_cache: any) => {
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_card, {
      "data-test": `${_ctx.dataTest}`
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "localization-label" }, "Localization:", -1)),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_q_btn_dropdown, {
                icon: "add_circle_outline",
                flat: "",
                class: "full-height",
                "data-test": "ia.localization.add",
                disable: _ctx.isDisabled
              }, {
                default: _withCtx(() => [
                  _createVNode(InAppLocalizationList, {
                    "already-used-localizations": _unref(alreadyUsedLocalizations),
                    onLocalizationClicked: addLocalizationTab
                  }, null, 8, ["already-used-localizations"])
                ]),
                _: 1
              }, 8, ["disable"])
            ]),
            _createVNode(_component_q_tabs, {
              "model-value": _ctx.selectedLocalization,
              align: "left",
              "outside-arrows": "",
              dense: "",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('update:selectedLocalization', $event)))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.inAppData, (iteratedInAppData) => {
                  return (_openBlock(), _createBlock(_component_q_tab, {
                    key: iteratedInAppData.langCode,
                    "data-test": "ia.localization.tab",
                    name: iteratedInAppData.langCode
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(iteratedInAppData.langName), 1),
                        (_unref(isDefaultLocalization)(iteratedInAppData.langCode))
                          ? (_openBlock(), _createBlock(_component_q_btn_dropdown, {
                              key: 0,
                              class: "ia-localization-tab__button ia-localization-tab__button--edit",
                              "data-test": "ia.localization.tab.edit",
                              "dropdown-icon": "none",
                              icon: "edit",
                              flat: "",
                              size: "xs",
                              color: "primary",
                              unelevated: "",
                              disable: _ctx.isDisabled
                            }, {
                              default: _withCtx(() => [
                                _createVNode(InAppLocalizationList, {
                                  "already-used-localizations": _unref(alreadyUsedLocalizations),
                                  onLocalizationClicked: (localizationClicked) => changeLocalization(iteratedInAppData.langCode, localizationClicked)
                                }, null, 8, ["already-used-localizations", "onLocalizationClicked"])
                              ]),
                              _: 2
                            }, 1032, ["disable"]))
                          : _createCommentVNode("", true),
                        (
                _unref(isDefaultLocalization)(iteratedInAppData.langCode) && !_ctx.isDisabled
              )
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 1,
                              class: "ia-localization-tab__button",
                              "data-test": "ia.localization.tab.delete",
                              icon: "clear",
                              flat: "",
                              size: "xs",
                              color: "negative",
                              unelevated: "",
                              onClick: _withModifiers(($event: any) => (deleteLocalization(iteratedInAppData.langCode)), ["stop"])
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["name"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["model-value"]),
            _renderSlot(_ctx.$slots, "default"),
            _createVNode(_component_q_tabs, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  icon: "upload_file",
                  class: "full-height",
                  label: ".XLSX",
                  "data-test": "ia.localization.upload",
                  disable: _ctx.isDisabled,
                  onClick: triggerLocalizationUpload
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Upload .XLSX file with translations")
                      ])),
                      _: 1
                    }),
                    _withDirectives(_createVNode(_unref(QFile), {
                      ref_key: "localizationQFileRef",
                      ref: localizationQFileRef,
                      "model-value": localizationUploadedFile.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localizationUploadedFile).value = $event)),
                      disable: _ctx.isDisabled,
                      onInput: localizationFilePicked,
                      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                    }, null, 8, ["model-value", "disable"]), [
                      [_vShow, false]
                    ])
                  ]),
                  _: 1
                }, 8, ["disable"]),
                _createVNode(_component_q_btn, {
                  flat: "",
                  icon: "download",
                  class: "full-height",
                  label: ".XLSX template",
                  "data-test": "ia.localization.download",
                  onClick: downloadLocalization
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Download .XLSX file with translations template")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["data-test"]),
    _createVNode(_component_q_dialog, {
      "model-value": localizationUploadOverrideConfirm.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((localizationUploadOverrideConfirm).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { class: "dialog-card q-dialog-plugin" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("p", null, "Uploaded translations will overwrite existing localizations.", -1),
                _createElementVNode("p", null, "Do you want to proceed?", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_q_btn, {
                  flat: "",
                  label: "Cancel",
                  color: "secondary"
                }, null, 512), [
                  [_directive_close_popup]
                ]),
                _withDirectives(_createVNode(_component_q_btn, {
                  flat: "",
                  label: "Proceed",
                  color: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (
            emit('acceptLocalizationUploadResult', localizationUploadResult.value)
          ))
                }, null, 512), [
                  [_directive_close_popup]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model-value"])
  ], 64))
}
}

})