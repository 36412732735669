import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "in-app" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "title",
  "data-test": "ia.preview.title"
}
const _hoisted_4 = {
  class: "text",
  "data-test": "ia.sheetdata.message"
}
const _hoisted_5 = { class: "buttons-container" }
const _hoisted_6 = {
  class: "secondary-button",
  "data-test": "ia.preview.sheet.secondaryText"
}
const _hoisted_7 = {
  class: "primary-button",
  "data-test": "ia.preview.sheet.primaryText"
}

import { computed } from "vue";

import { ImageMetadata } from "@/api/image/typedefs";
import { LottieMetadata } from "@/api/lottie/typedefs";
import inAppPreviewBackgroundUrl from "@/assets/images/inapp-preview-background.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewSheet',
  props: {
    title: {},
    message: {},
    image: {},
    lottie: {},
    primaryText: {},
    secondaryText: {}
  },
  setup(__props: any) {

const props = __props;

const imageUrl = computed(() => props.lottie?.url ?? props.image?.url);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (imageUrl.value)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "image",
              src: imageUrl.value
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.secondaryText), 1),
          _createElementVNode("button", _hoisted_7, _toDisplayString(_ctx.primaryText), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})