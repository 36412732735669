import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 12 }
const _hoisted_2 = { key: 1 }

import { InAppData } from "@/api/inapps/typedefs/inApp";
import { InAppType, InAppTypeGuard } from "@/api/inapps/typedefs/inAppTypes";
import InAppVisualPreviewAlert from "@/components/inapps/detail/visualpreview/InAppVisualPreviewAlert.vue";
import InAppVisualPreviewBottomSheet from "@/components/inapps/detail/visualpreview/InAppVisualPreviewBottomSheet.vue";
import InAppVisualPreviewCenterDialog from "@/components/inapps/detail/visualpreview/InAppVisualPreviewCenterDialog.vue";
import InAppVisualPreviewConfigBundleConfirm from "@/components/inapps/detail/visualpreview/InAppVisualPreviewConfigBundleConfirm.vue";
import InAppVisualPreviewConfigBundleSheet from "@/components/inapps/detail/visualpreview/InAppVisualPreviewConfigBundleSheet.vue";
import InAppVisualPreviewConfirm from "@/components/inapps/detail/visualpreview/InAppVisualPreviewConfirm.vue";
import InAppVisualPreviewRateDialog from "@/components/inapps/detail/visualpreview/InAppVisualPreviewRateDialog.vue";
import InAppVisualPreviewSheet from "@/components/inapps/detail/visualpreview/InAppVisualPreviewSheet.vue";
import InAppVisualPreviewStartPageCard from "@/components/inapps/detail/visualpreview/InAppVisualPreviewStartPageCard.vue";
import InAppVisualPreviewWallpaperSheet from "@/components/inapps/detail/visualpreview/InAppVisualPreviewWallpaperSheet.vue";
import InAppVisualPreviewWallpaperSheetBasic from "@/components/inapps/detail/visualpreview/InAppVisualPreviewWallpaperSheetBasic.vue";
import InAppVisualPreviewWebviewPanel from "@/components/inapps/detail/visualpreview/InAppVisualPreviewWebviewPanel.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreview',
  props: {
    inAppData: {},
    inAppType: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (props.inAppData)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (props.inAppData.type === _unref(InAppTypeGuard).keys.Alert.value)
          ? (_openBlock(), _createBlock(InAppVisualPreviewAlert, {
              key: 0,
              title: props.inAppData.title,
              message: props.inAppData.message,
              "dismiss-text": props.inAppData.dismissText
            }, null, 8, ["title", "message", "dismiss-text"]))
          : (props.inAppData.type === _unref(InAppTypeGuard).keys.Confirm.value)
            ? (_openBlock(), _createBlock(InAppVisualPreviewConfirm, {
                key: 1,
                title: props.inAppData.title,
                message: props.inAppData.message,
                "accept-text": props.inAppData.acceptText,
                "cancel-text": props.inAppData.cancelText
              }, null, 8, ["title", "message", "accept-text", "cancel-text"]))
            : (props.inAppData.type === _unref(InAppTypeGuard).keys.Sheet.value)
              ? (_openBlock(), _createBlock(InAppVisualPreviewSheet, {
                  key: 2,
                  title: props.inAppData.title,
                  message: props.inAppData.message,
                  "primary-text": props.inAppData.primaryButtonText,
                  "secondary-text": props.inAppData.secondaryButtonText,
                  image: props.inAppData.image,
                  lottie: props.inAppData.lottieAnimationFile
                }, null, 8, ["title", "message", "primary-text", "secondary-text", "image", "lottie"]))
              : (
        props.inAppData.type === _unref(InAppTypeGuard).keys['Bottom Sheet'].value
      )
                ? (_openBlock(), _createBlock(InAppVisualPreviewBottomSheet, {
                    key: 3,
                    title: props.inAppData.title,
                    message: props.inAppData.message,
                    "primary-text": props.inAppData.primaryButtonText,
                    "secondary-text": props.inAppData.secondaryButtonText,
                    image: props.inAppData.image,
                    lottie: props.inAppData.lottieAnimationFile
                  }, null, 8, ["title", "message", "primary-text", "secondary-text", "image", "lottie"]))
                : (
        props.inAppData.type === _unref(InAppTypeGuard).keys['WebView Panel'].value
      )
                  ? (_openBlock(), _createBlock(InAppVisualPreviewWebviewPanel, {
                      key: 4,
                      url: props.inAppData.url
                    }, null, 8, ["url"]))
                  : (
        props.inAppData.type === _unref(InAppTypeGuard).keys['Wallpaper Sheet'].value
      )
                    ? (_openBlock(), _createBlock(InAppVisualPreviewWallpaperSheet, {
                        key: 5,
                        title: props.inAppData.title,
                        message: props.inAppData.message,
                        "primary-text": props.inAppData.primaryButtonText,
                        "secondary-text": props.inAppData.secondaryButtonText,
                        "avatar-image": 
        props.inAppData.avatarImage || props.inAppData.avatarImageDark
      ,
                        "center-text": props.inAppData.centerText,
                        image: props.inAppData.image
                      }, null, 8, ["title", "message", "primary-text", "secondary-text", "avatar-image", "center-text", "image"]))
                    : (
        props.inAppData.type ===
        _unref(InAppTypeGuard).keys['Wallpaper Sheet Basic'].value
      )
                      ? (_openBlock(), _createBlock(InAppVisualPreviewWallpaperSheetBasic, {
                          key: 6,
                          title: props.inAppData.title || 'Set wallpaper',
                          message: props.inAppData.message || 'Personalize your browser',
                          "primary-text": props.inAppData.primaryButtonText || 'Done',
                          "secondary-text": props.inAppData.secondaryButtonText || 'View all'
                        }, null, 8, ["title", "message", "primary-text", "secondary-text"]))
                      : (
        props.inAppData.type ===
        _unref(InAppTypeGuard).keys['Config Bundle Sheet'].value
      )
                        ? (_openBlock(), _createBlock(InAppVisualPreviewConfigBundleSheet, {
                            key: 7,
                            title: props.inAppData.title,
                            message: props.inAppData.message,
                            "primary-text": props.inAppData.primaryButtonText,
                            "avatar-image": props.inAppData.avatarImage
                          }, null, 8, ["title", "message", "primary-text", "avatar-image"]))
                        : (
        props.inAppData.type ===
        _unref(InAppTypeGuard).keys['Config Bundle Confirm'].value
      )
                          ? (_openBlock(), _createBlock(InAppVisualPreviewConfigBundleConfirm, {
                              key: 8,
                              title: props.inAppData.title,
                              message: props.inAppData.message,
                              subtitle: props.inAppData.subtitle,
                              submessage: props.inAppData.submessage,
                              "confirm-text": props.inAppData.confirmButtonText,
                              "cancel-text": props.inAppData.cancelButtonText,
                              "avatar-image": props.inAppData.avatarImage
                            }, null, 8, ["title", "message", "subtitle", "submessage", "confirm-text", "cancel-text", "avatar-image"]))
                          : (
        props.inAppData.type === _unref(InAppTypeGuard).keys['Center Dialog'].value
      )
                            ? (_openBlock(), _createBlock(InAppVisualPreviewCenterDialog, {
                                key: 9,
                                title: props.inAppData.title,
                                message: props.inAppData.message,
                                "primary-text": props.inAppData.primaryButtonText,
                                "secondary-text": props.inAppData.secondaryButtonText,
                                "badge-image": props.inAppData.badgeImage,
                                "center-image": props.inAppData.centerImage,
                                lottie: props.inAppData.lottieAnimationFile
                              }, null, 8, ["title", "message", "primary-text", "secondary-text", "badge-image", "center-image", "lottie"]))
                            : (
        props.inAppData.type === _unref(InAppTypeGuard).keys['Subscription Sheet'].value
      )
                              ? (_openBlock(), _createBlock(InAppVisualPreviewSheet, {
                                  key: 10,
                                  title: props.inAppData.title,
                                  message: props.inAppData.message,
                                  "primary-text": props.inAppData.primaryButtonText,
                                  "secondary-text": props.inAppData.secondaryButtonText,
                                  image: props.inAppData.image,
                                  lottie: null
                                }, null, 8, ["title", "message", "primary-text", "secondary-text", "image"]))
                              : (
        props.inAppData.type === _unref(InAppTypeGuard).keys['Start Page Card'].value
      )
                                ? (_openBlock(), _createBlock(InAppVisualPreviewStartPageCard, {
                                    key: 11,
                                    message: props.inAppData.message,
                                    "primary-text": props.inAppData.primaryButtonText,
                                    image: props.inAppData.image
                                  }, null, 8, ["message", "primary-text", "image"]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_1, "This InAppType has no visual preview"))
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (props.inAppType === _unref(InAppTypeGuard).keys['Rate Dialog'].value)
          ? (_openBlock(), _createBlock(InAppVisualPreviewRateDialog, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, "This InAppType has no visual preview"))
      ], 64))
}
}

})