import { computed } from "vue";

import {
  isExistingInApp,
  NewOrExistingInApp,
} from "@/api/inapps/typedefs/inApp";
import { ReadonlyRef } from "@/lib/typing";

import { InAppMachineStates } from "./useInAppFlowMachine";
import { InAppPermissions } from "./useInAppPermissions";

export interface UseInAppFormDisabledOptions {
  inAppMachineStateMatch: (stateToMatch: InAppMachineStates) => boolean;
  inApp: ReadonlyRef<NewOrExistingInApp>;
  permissions: ReadonlyRef<InAppPermissions>;
}

export function useInAppFormDisabled({
  inAppMachineStateMatch,
  inApp,
  permissions,
}: UseInAppFormDisabledOptions) {
  const isDisabled = computed(() => {
    const isStateDraft = inAppMachineStateMatch("draft");
    const isStateNew = inAppMachineStateMatch("new");
    const isStatePaused = inAppMachineStateMatch("paused");

    if (!(isStateDraft || isStateNew || isStatePaused)) {
      return true;
    }

    const isNewAndUserCannotAdd =
      isExistingInApp(inApp.value) && !permissions.value.hasEditInAppPermission;

    if (isNewAndUserCannotAdd) {
      return true;
    }

    const isExistingAndUserCannotEdit =
      !isExistingInApp(inApp.value) && !permissions.value.hasAddInAppPermission;

    return isExistingAndUserCannotEdit;
  });

  return { isDisabled };
}
