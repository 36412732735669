import { SegmentRawConfig } from "./segmentRawConfig";
import {
  SelectTypeId,
  selectTypeIdToLabels,
  selectTypeIdToOptions,
} from "./selectType";

export function buildSegmentMatchingConfig(
  config: SegmentRawConfig
): SegmentMatchingConfig | undefined {
  if (config.matching === undefined) {
    return undefined;
  }

  const matchingOptions = selectTypeIdToOptions[config.matching];

  const optionToLabels = selectTypeIdToLabels[config.matching];

  return {
    type: config.matching,
    matchingOptions,
    optionToLabels,
  };
}

export interface SegmentMatchingConfig {
  readonly type: SelectTypeId;
  readonly matchingOptions: string[];
  readonly optionToLabels: Record<string, string>;
}
