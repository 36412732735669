import { SegmentType } from "./segmentType";

export enum ExprOperatorType {
  OR = "or",
  AND = "and",
}

export const ExprSegmentType = "value" as const;

export type ExprOperator = {
  readonly type: ExprOperatorType;
};

export interface ExprSegmentData {
  readonly value?: string | number;
  readonly matching?: string;
}

export interface ExprSegment extends ExprSegmentData {
  readonly type: typeof ExprSegmentType;
  readonly segmentType: SegmentType;
}

export type ExprSegmentError = string;

export type ExprItem = ExprSegment | ExprOperator;

/**
 * Our internal structure that represents expression.
 * It is a flat structure that can consist segments connected using operators.
 * When expression contains both AND and OR operators, OR operator takes
 * precedence over AND and forms a flat structure
 * (only single level of OR nesting), for example:
 *
 *   A OR B OR C AND D OR E AND F -> (A OR B OR C) AND (D OR E) AND F
 *
 * Expression consist of items. Possible expression items are:
 *  1. segments - repesent single expression's conditions like
 *                language, country, etc.
 *  2. operators - placed in between segments, possible options are AND / OR
 */
export type Expr = ExprItem[];
