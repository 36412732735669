import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-alert-data" }

import QInput from 'quasar/src/components/input/QInput.js';;
import { computed } from "vue";

import { InAppDataBottomSheet } from "@/api/inapps/typedefs/inAppData";
import ImageSelect from "@/components/shared/imageselect/ImageSelect.vue";
import LottieSelectWrapper from "@/components/shared/lottieselect/LottieSelectWrapper.vue";
import { nonEmptyStringOrNull } from "@/lib/converters";
import { bindPropToRef } from "@/lib/propToRef";
import {
  isNonEmptyString,
  isNonWhiteCharOnlyString,
} from "@/lib/rules/stringValidatior";

import InAppButtonActionInput from "../InAppButtonActionInput.vue";
import { buildInAppDataInputRules } from "./dataRules";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataBottomSheet',
  props: {
    isAddImageButtonVisible: { type: Boolean },
    isAddLottieButtonVisible: { type: Boolean },
    isDisabled: { type: Boolean },
    modelValue: {}
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

function onInAppUpdate(changedData: Partial<InAppDataBottomSheet>) {
  emit("update:model-value", { ...props.modelValue, ...changedData });
}

const inAppLocal = bindPropToRef<InAppDataBottomSheet>(
  () => props.modelValue,
  onInAppUpdate
);

const messageInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const titleInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

const primaryButtonTextInputRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString]
);

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[8] || (_cache[8] = _createElementVNode("div", {
          "data-test": "ia.bottomsheetdata.header",
          class: "text-h6 q-mb-sm"
        }, " Bottom Sheet data ", -1)),
        _createVNode(ImageSelect, {
          modelValue: _unref(inAppLocal).image,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(inAppLocal).image) = $event)),
          "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
          "data-test": "ia.bottomsheetdata.image",
          "image-constraint-names": ['bottom_sheet_image'],
          hint: "Image for your message",
          label: "Image",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-image-button-visible", "disable"]),
        _createVNode(_unref(QInput), {
          ref: "titleInputRef",
          "model-value": _unref(inAppLocal).title,
          filled: "",
          label: "Title",
          hint: "Title of your message",
          "data-test": "ia.bottomsheetdata.title",
          disable: _ctx.isDisabled,
          rules: _unref(titleInputRules),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(inAppLocal).title = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(_unref(QInput), {
          ref: "messageInputRef",
          "model-value": _unref(inAppLocal).message,
          filled: "",
          label: "Message",
          hint: "Text inside your message",
          "data-test": "ia.bottomsheetdata.message",
          disable: _ctx.isDisabled,
          rules: _unref(messageInputRules),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_unref(inAppLocal).message = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(LottieSelectWrapper, {
          modelValue: _unref(inAppLocal).lottieAnimationFile,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(inAppLocal).lottieAnimationFile) = $event)),
          "is-add-lottie-button-visible": _ctx.isAddLottieButtonVisible,
          "data-test": "ia.bottomsheetdata.lottie",
          hint: "Lottie for your message",
          label: "Lottie",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-lottie-button-visible", "disable"]),
        _createVNode(_unref(QInput), {
          ref: "primaryButtonTextInputRef",
          "model-value": _unref(inAppLocal).primaryButtonText,
          filled: "",
          label: "Primary text",
          hint: "Text of the primary button",
          "data-test": "ia.bottomsheetdata.primarytext",
          disable: _ctx.isDisabled,
          rules: _unref(primaryButtonTextInputRules),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (
          _unref(inAppLocal).primaryButtonText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable", "rules"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).secondaryButtonText,
          filled: "",
          label: "Secondary text",
          hint: "Text of the secondary button",
          "data-test": "ia.bottomsheetdata.secondarytext",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (
          _unref(inAppLocal).secondaryButtonText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppLocal).primaryButtonAction,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(inAppLocal).primaryButtonAction) = $event)),
          "data-test": "ia.bottomsheetdata.primaryaction",
          label: "Primary Action",
          "is-disabled": _ctx.isDisabled,
          "type-options": ['url', 'delete_sd', 'report_sd']
        }, null, 8, ["modelValue", "is-disabled"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppLocal).secondaryButtonAction,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(inAppLocal).secondaryButtonAction) = $event)),
          "data-test": "ia.bottomsheetdata.secondaryaction",
          label: "Secondary Action",
          "is-disabled": _ctx.isDisabled,
          "type-options": ['url', 'delete_sd', 'report_sd']
        }, null, 8, ["modelValue", "is-disabled"])
      ]),
      _: 1
    })
  ]))
}
}

})