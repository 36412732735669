import { computed } from "vue";

import { InAppAction } from "@/components/inapps/typedefs";
import { ActionConfiguration } from "@/components/shared/formaction/typedefs";
import { ReadonlyRef } from "@/lib/typing";
import {
  ACTIVATE_VISIBLE_STATES,
  CLONE_VISIBLE_STATES,
  DELETE_VISIBLE_STATES,
  FINISH_VISIBLE_STATES,
  PAUSE_VISIBLE_STATES,
  PREVIEW_VISIBLE_STATES,
  REACTIVATE_VISIBLE_STATES,
  SAVE_VISIBLE_STATES,
  VALIDATE_VISIBLE_STATES,
} from "@/views/inapps/useInAppActionButtons.states";
import { InAppMachineStates } from "@/views/inapps/useInAppFlowMachine";

import { InAppPermissions } from "./useInAppPermissions";
import { PreviewConfig } from "./useInAppPreview";
import { InAppTargetMachineStates } from "./useInAppTargetFlowMachine";

interface UseInAppActionButtonsOptions {
  inAppMachineStateMatch: (stateToMatch: InAppMachineStates) => boolean;
  inAppTargetMachineStateMatch: (
    stateToMatch: InAppTargetMachineStates
  ) => boolean;
  previewConfig: ReadonlyRef<PreviewConfig>;
  permissions: ReadonlyRef<InAppPermissions>;
  canCloneIntoAnyProductLine: ReadonlyRef<boolean>;
  isInAppDisplayWhenSet: ReadonlyRef<boolean>;
}

export const useInAppActionButtons = (
  options: UseInAppActionButtonsOptions
) => {
  function isInAppMachineStateMatching(
    expectedStates: InAppMachineStates[]
  ): boolean {
    return expectedStates.some((state) =>
      options.inAppMachineStateMatch(state)
    );
  }

  function isInAppTargetMachineStateMatching(
    expectedStates: InAppTargetMachineStates[]
  ): boolean {
    return expectedStates.some((state) =>
      options.inAppTargetMachineStateMatch(state)
    );
  }

  const actionButtonsConfig = computed<
    Record<InAppAction, ActionConfiguration>
  >(() => {
    const isNewInApp = isInAppMachineStateMatching(["new"]);
    const isExistingInApp = !isNewInApp;

    const isNewAndUserCanAdd =
      isNewInApp && options.permissions.value.hasAddInAppPermission;

    const isExistingAndUserCanEdit =
      isExistingInApp && options.permissions.value.hasEditInAppPermission;

    return {
      Preview: {
        visible: isInAppMachineStateMatching(PREVIEW_VISIBLE_STATES),
        color: "secondary",
        loading: options.previewConfig.value.isLoading,
        tooltip: options.previewConfig.value.hasUserTestDevices
          ? undefined
          : "User needs to register a test device for this product line",
        disabled: !options.previewConfig.value.hasUserTestDevices,
      },
      Validate: {
        visible:
          options.permissions.value.hasEditInAppPermission &&
          isInAppMachineStateMatching(VALIDATE_VISIBLE_STATES) &&
          isInAppTargetMachineStateMatching([
            "notValidated",
            "validating.inProgress",
          ]),
        color: "accent",
        loading: isInAppTargetMachineStateMatching(["validating.inProgress"]),
      },
      Clone: {
        visible:
          options.canCloneIntoAnyProductLine.value &&
          options.permissions.value.hasAddInAppPermission &&
          isInAppMachineStateMatching(CLONE_VISIBLE_STATES),
        color: "secondary",
      },
      Activate: {
        visible:
          options.permissions.value.hasEditInAppPermission &&
          isInAppMachineStateMatching(ACTIVATE_VISIBLE_STATES),
        color: "positive",
        disabled:
          !options.isInAppDisplayWhenSet.value ||
          !isInAppTargetMachineStateMatching(["validated"]),
        tooltip: !options.isInAppDisplayWhenSet.value
          ? "You need to set trigger & limit to be able to activate this in app."
          : isInAppTargetMachineStateMatching(["notSelected"])
          ? "You need to set a target to be able to activate this in app."
          : !isInAppTargetMachineStateMatching(["validated"])
          ? "You need to validate target to be able to activate this in app."
          : undefined,
      },
      Pause: {
        visible:
          options.permissions.value.hasEditInAppPermission &&
          isInAppMachineStateMatching(PAUSE_VISIBLE_STATES),
        color: "accent",
      },
      Reactivate: {
        visible:
          options.permissions.value.hasEditInAppPermission &&
          isInAppMachineStateMatching(REACTIVATE_VISIBLE_STATES),
        color: "accent",
      },
      Finish: {
        visible:
          options.permissions.value.hasEditInAppPermission &&
          isInAppMachineStateMatching(FINISH_VISIBLE_STATES),
        color: "primary",
      },
      Delete: {
        visible:
          options.permissions.value.hasDeleteInAppPermission &&
          isInAppMachineStateMatching(DELETE_VISIBLE_STATES),
        color: "negative",
      },
      Save: {
        visible:
          (isNewAndUserCanAdd || isExistingAndUserCanEdit) &&
          isInAppMachineStateMatching(SAVE_VISIBLE_STATES),
        color: "info",
      },
    };
  });

  return { actionButtonsConfig };
};
