import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/inapp-ratedialog.svg'


import inAppPreviewBackgroundUrl from "@/assets/images/inapp-preview-background.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewRateDialog',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "in-app" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Rate Dialog"
        })
      ], -1)
    ])),
    _: 1
  }, 8, ["background-url"]))
}
}

})