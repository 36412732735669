import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { "data-test": "ia.offer.id" }
const _hoisted_2 = { "data-test": "ia.offer.productid" }
const _hoisted_3 = { "data-test": "ia.offer.expirationtime" }
const _hoisted_4 = { "data-test": "ia.offer.ispersonalized" }
const _hoisted_5 = { class: "flex q-gutter-sm" }
const _hoisted_6 = { class: "q-gutter-sm" }

import QBtn from 'quasar/src/components/btn/QBtn.js';import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import QForm from 'quasar/src/components/form/QForm.js';import QInput from 'quasar/src/components/input/QInput.js';;
import { Ref, ref, watch } from "vue";

import DateTimeInput from "@/components/shared/DateTimeInput.vue";
import { isNonEmptyString } from "@/lib/rules/stringValidatior";


import { SubscriptionOfferData } from "@/lib/inapps/inAppDataTypes";

export type SubscriptionOfferProps = {
  modelValue: SubscriptionOfferData;
  inAppEndDate: Date | null;
  otherProductIds: string[];
};

const __default__ = {
  name: "SubscriptionOffer",
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    modelValue: {},
    inAppEndDate: {},
    otherProductIds: {}
  },
  emits: ["update:model-value", "cancel"],
  setup(__props: any, { emit: __emit }) {

const props: SubscriptionOfferProps = __props;

const idRule = (value: string) => {
  const regex = /^[a-zA-Z0-9_-]*$/;
  if (!regex.test(value)) {
    return "Only alphanumeric characters, underscores, and hyphens are allowed.";
  }
  return true;
};

const uniqueProductRule = (value: string) => {
  if (props.otherProductIds.includes(value)) {
    return "Product ID must be unique.";
  }
  return true;
};

const emit = __emit;

const offerForm = ref<QForm | null>(null);

async function onSubmit() {
  const isValid = await offerForm.value?.validate();
  if (!isValid) {
    return;
  }
  emit("update:model-value", offer.value);
}

function onCancel() {
  emit("cancel");
}

const offer: Ref<SubscriptionOfferData> = ref({
  ...props.modelValue,
});

watch(
  props.modelValue,
  (newValue: SubscriptionOfferData) => {
    offer.value = newValue;
  },
  { deep: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(QForm), {
    ref_key: "offerForm",
    ref: offerForm,
    class: "q-gutter-y-md column"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(QInput), {
          modelValue: offer.value.id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((offer.value.id) = $event)),
          filled: "",
          label: "Offer ID",
          hint: "ID of the offer",
          rules: [_unref(isNonEmptyString), idRule]
        }, null, 8, ["modelValue", "rules"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(QInput), {
          modelValue: offer.value.productId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((offer.value.productId) = $event)),
          filled: "",
          label: "Product ID",
          hint: "ID of the product",
          rules: [_unref(isNonEmptyString), idRule, uniqueProductRule]
        }, null, 8, ["modelValue", "rules"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(DateTimeInput, {
          modelValue: offer.value.expirationTime,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((offer.value.expirationTime) = $event)),
          label: "Expiration Time",
          hint: "Expiration time of the offer",
          "extra-rules": [
          (val) => (val !== null && val !== '') || 'The field cannot be empty',
          (val) =>
            (val && (!_ctx.inAppEndDate || new Date(val) > _ctx.inAppEndDate)) ||
            'Expiration time must be later than the end date of the inApp',
        ]
        }, null, 8, ["modelValue", "extra-rules"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(QCheckbox), {
          modelValue: offer.value.isPersonalized,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((offer.value.isPersonalized) = $event)),
          label: "Is personalized?"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(QBtn), {
            label: "Submit",
            class: "q-mt-md bg-primary text-white",
            "data-test": "ia.offer.submit",
            onClick: onSubmit
          })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_unref(QBtn), {
            label: "Cancel",
            class: "q-mt-md",
            "data-test": "ia.offer.cancel",
            onClick: onCancel
          })
        ])
      ])
    ]),
    _: 1
  }, 512))
}
}

})