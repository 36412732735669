import { computed, ComputedRef } from "vue";

import { ValidationRule } from "@/lib/rules/typedefs";
import { ReadonlyRef } from "@/lib/typing";

/**
 * Shorthand for defining rules for inputs in in app data depending
 * on the localization.
 */
export function buildInAppDataInputRules<T>(
  localization: ReadonlyRef<string>,
  defaultOnlyRules: ValidationRule<T>[],
  anyRules: ValidationRule<T>[] = []
): ComputedRef<ValidationRule<T>[]> {
  return computed(() => {
    if (localization.value === "default") {
      return [...defaultOnlyRules, ...anyRules];
    } else {
      return [...anyRules];
    }
  });
}
