import { ref, watch } from "vue";

import { fetchInAppSegmentTypes } from "@/api/inapps/segmentTypes";
import { SegmentType } from "@/components/shared/expression/segmentType";
import { ProductLine } from "@/lib/productLine";
import { ReadonlyRef } from "@/lib/typing";

interface InAppSegmentTypesOptions {
  productLine: ReadonlyRef<ProductLine>;
  kind: "limit" | "trigger";
}

export function useInAppSegmentTypes(opts: InAppSegmentTypesOptions) {
  const segmentTypes = ref<SegmentType[]>([]);

  watch(
    [opts.productLine.value],
    async () => {
      try {
        segmentTypes.value = await fetchInAppSegmentTypes(
          opts.productLine.value.id,
          opts.kind
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    { immediate: true }
  );

  return {
    segmentTypes,
  };
}
