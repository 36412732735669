import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-alert-data" }

import { InAppDataMaintenanceAction } from "@/api/inapps/typedefs/inAppData";
import { bindPropToRef } from "@/lib/propToRef";

import InAppButtonActionInput from "../InAppButtonActionInput.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataMaintenanceAction',
  props: {
    modelValue: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

function onInAppUpdate(changedData: Partial<InAppDataMaintenanceAction>) {
  emit("update:model-value", { ...props.modelValue, ...changedData });
}

const inAppLocal = bindPropToRef<InAppDataMaintenanceAction>(
  () => props.modelValue,
  onInAppUpdate
);

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[1] || (_cache[1] = _createElementVNode("div", {
          "data-test": "ia.maintenanceactiondata.header",
          class: "text-h6 q-mb-sm"
        }, " Maintenance Action data ", -1)),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppLocal).mainAction,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(inAppLocal).mainAction) = $event)),
          "data-test": "ia.maintenanceactiondata.mainaction",
          label: "Main action",
          "is-disabled": _ctx.isDisabled,
          "type-options": ['url', 'delete_sd', 'report_sd']
        }, null, 8, ["modelValue", "is-disabled"])
      ]),
      _: 1
    })
  ]))
}
}

})