import { ShallowReadonlyRef, WithId } from "@/lib/typing";

import {
  Expr,
  ExprOperatorType,
  ExprSegment,
  ExprSegmentData,
  ExprSegmentType,
} from "./typedefs";
import { ExprWithIds } from "./useExpressionInput";

export interface UseExpressionInputEditOptions {
  modelValue: ShallowReadonlyRef<Expr>;
  onModelValueUpdate: (arg0: Expr) => void;
  exprWithIds: ShallowReadonlyRef<ExprWithIds>;
  // Possible operators (AND/OR) between segments.
  availableOperators: ShallowReadonlyRef<ExprOperatorType[]>;
}

export function useExpressionInputEdit({
  availableOperators,
  modelValue,
  exprWithIds,
  onModelValueUpdate,
}: UseExpressionInputEditOptions) {
  const addExprSegment = (item: ExprSegment) => {
    if (availableOperators.value.length < 1) {
      throw new Error(
        "Need at least one item in operatorOptions to addExprItem."
      );
    }

    const newExpr = [...modelValue.value];

    if (newExpr.length > 0) {
      // Add operator between end of expr and currently added expr item.
      newExpr.push({
        type: availableOperators.value[0],
      });
    }

    newExpr.push({
      ...item,
    });

    onModelValueUpdate(newExpr);
  };

  /**
   * Between any pair of segments we need an operator. That is why when we delete
   * a segment, one of adjacent operators needs to be also deleted.
   * This method, for given segment, finds adjacent operator.
   *
   * NOTE: for segments list with only one segment there are no operators.
   */
  const getItemIdsToDelete = (segmentItemId: string): Set<string> => {
    const idsToDelete = new Set([segmentItemId]);
    if (exprWithIds.value.length === 1) {
      return idsToDelete;
    }

    for (let i = 0; i < exprWithIds.value.length; i++) {
      if (exprWithIds.value[i].id === segmentItemId) {
        if (i === exprWithIds.value.length - 1) {
          idsToDelete.add(exprWithIds.value[i - 1].id);
        } else {
          idsToDelete.add(exprWithIds.value[i + 1].id);
        }
        break;
      }
    }

    return idsToDelete;
  };

  const deleteExprSegment = (segmentExprId: string) => {
    const idsToDelete = getItemIdsToDelete(segmentExprId);
    const newExprWithIds = exprWithIds.value.filter(
      (item) => !idsToDelete.has(item.id)
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newExpr: Expr = newExprWithIds.map(({ id, ...item }) => item);

    onModelValueUpdate(newExpr);
  };

  const updateExprSegment = (
    segmentExpr: WithId<ExprSegment>,
    data: ExprSegmentData
  ) => {
    const newExprWithIds = exprWithIds.value.map((item) =>
      item.id === segmentExpr.id && item.type === ExprSegmentType
        ? { ...item, ...data }
        : item
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newExpr: Expr = newExprWithIds.map(({ id, ...item }) => item);

    onModelValueUpdate(newExpr);
  };

  return {
    addExprSegment,
    deleteExprSegment,
    updateExprSegment,
  };
}
