import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-wallpaper-sheet-basic-data" }

import { computed } from "vue";

import { InAppDataWallpaperSheetBasic } from "@/api/inapps/typedefs/inAppData";
import { nonEmptyStringOrNull } from "@/lib/converters";
import { bindPropToRef } from "@/lib/propToRef";
import { isStrCommaSeparatedListOfInts } from "@/lib/rules/idsValidator";
import {
  isNonEmptyString,
  isNonWhiteCharOnlyString,
} from "@/lib/rules/stringValidatior";
import { isValidActionUrl } from "@/lib/rules/urlValidator";

import InAppButtonActionInput from "../InAppButtonActionInput.vue";
import { buildInAppDataInputRules } from "./dataRules";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataWallpaperSheetBasic',
  props: {
    modelValue: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function onInAppUpdate(changedData: Partial<InAppDataWallpaperSheetBasic>) {
  emit("update:model-value", { ...props.modelValue, ...changedData });
}
const inAppLocal = bindPropToRef<InAppDataWallpaperSheetBasic>(
  () => props.modelValue,
  onInAppUpdate
);

const wallpaperIdsRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString],
  [isStrCommaSeparatedListOfInts]
);

const buttonActionsRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [],
  [isValidActionUrl]
);

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("div", {
          "data-test": "ia.wallpapersheetbasicdata.header",
          class: "text-h6 q-mb-sm"
        }, " Wallpaper Sheet data ", -1)),
        _createVNode(_component_q_input, {
          "model-value": _unref(inAppLocal).title,
          filled: "",
          label: "Title",
          hint: "Title of your message",
          "data-test": "ia.wallpapersheetbasicdata.title",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(inAppLocal).title = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(_component_q_input, {
          "model-value": _unref(inAppLocal).message,
          filled: "",
          label: "Message",
          hint: "Text inside your message",
          "data-test": "ia.wallpapersheetbasicdata.message",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(inAppLocal).message = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(_component_q_input, {
          "model-value": _unref(inAppLocal).primaryButtonText,
          filled: "",
          label: "Primary text",
          hint: "Text of the primary button",
          "data-test": "ia.wallpapersheetbasicdata.primarytext",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (
          _unref(inAppLocal).primaryButtonText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(_component_q_input, {
          "model-value": _unref(inAppLocal).secondaryButtonText,
          filled: "",
          label: "Secondary text",
          hint: "Text of the secondary button",
          "data-test": "ia.wallpapersheetbasicdata.secondarytext",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (
          _unref(inAppLocal).secondaryButtonText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppLocal).secondaryButtonAction,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(inAppLocal).secondaryButtonAction) = $event)),
          "data-test": "ia.wallpapersheetbasicdata.secondaryaction",
          label: "Secondary action",
          "is-disabled": _ctx.isDisabled,
          "type-options": ['url'],
          rules: _unref(buttonActionsRules)
        }, null, 8, ["modelValue", "is-disabled", "rules"]),
        _createVNode(_component_q_input, {
          "model-value": _unref(inAppLocal).wallpaperIds,
          filled: "",
          label: "Wallpaper IDs",
          hint: "Comma separated IDs of wallpapers.",
          "data-test": "ia.wallpapersheetbasicdata.wallpaperids",
          rules: _unref(wallpaperIdsRules),
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (
          _unref(inAppLocal).wallpaperIds = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "rules", "disable"])
      ]),
      _: 1
    })
  ]))
}
}

})