import camelcaseKeys from "camelcase-keys";

import { buildErrorMessage } from "@/lib/errors";
import { httpClient } from "@/lib/http";
import { cast } from "@/lib/typing";

import { LottieMetadata, LottieMetadataGuard } from "./typedefs";

function convertLottie(data: unknown): LottieMetadata {
  const lottieData = data as {
    id?: unknown;
    name?: unknown;
    url?: unknown;
    snapshot_url?: unknown;
    status?: unknown;
    message?: unknown;
  };

  if (typeof lottieData !== "object") {
    throw new Error(`Invalid Lottie data type to convert - ${data}`);
  }

  // TODO(PNS-1738): Drop when BE will start sending camelCase.
  const camelCasedData = camelcaseKeys(lottieData);

  return cast(LottieMetadataGuard, camelCasedData);
}

export async function uploadLottie(file: File): Promise<LottieMetadata> {
  const formData = new FormData();
  formData.set("file", file);
  const response = await httpClient.post(`/api/lottie-upload`, formData);
  const body = await response.json();

  if (!response.ok) {
    throw new Error(
      buildErrorMessage(response.status, response.statusText, body)
    );
  }

  return convertLottie(body);
}
