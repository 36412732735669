import { computed } from "vue";

import { InAppPriority } from "@/api/inapps/typedefs/inApp";
import { InAppType } from "@/api/inapps/typedefs/inAppTypes";
import { Config } from "@/composables/typedefs";
import { SelectOption } from "@/lib/select";
import { ReadonlyRef } from "@/lib/typing";

interface Options {
  config: ReadonlyRef<Config>;
  inAppType: ReadonlyRef<InAppType | null>;
}

export interface InAppPriorityOption {
  label: string;
  value: InAppPriority;
}

const TAG_WITH_PRIORITY_HIGH = "Commercial";

export const DEFAULT_PRIORITY_OPTIONS = [
  {
    label: "High",
    value: InAppPriority.HIGH,
  },
  {
    label: "Low",
    value: InAppPriority.LOW,
  },
];

export function useInAppPriority({ config, inAppType }: Options) {
  const priorityOptions = computed<SelectOption<InAppPriority>[]>(() => {
    if (
      inAppType.value === "Maintenance Action" ||
      inAppType.value === "Start Page Card"
    ) {
      return [
        {
          label: "Zero",
          value: InAppPriority.ZERO,
        },
      ];
    }

    return DEFAULT_PRIORITY_OPTIONS;
  });

  const priorityValues = computed(() =>
    priorityOptions.value.map((p) => p.value)
  );

  const defaultTagLabels = computed(() =>
    config.value.defaultTags.map((t) => t.label)
  );

  const initialPriority = computed(() => {
    const priorityTagBased = defaultTagLabels.value.includes(
      TAG_WITH_PRIORITY_HIGH
    )
      ? InAppPriority.HIGH
      : InAppPriority.LOW;

    if (priorityValues.value.includes(priorityTagBased)) {
      return priorityTagBased;
    }

    return priorityOptions.value[0].value;
  });

  return {
    priorityOptions,
    initialPriority,
  };
}
