import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/wallpapers/wallpaper1.png'
import _imports_1 from '@/assets/images/wallpapers/wallpaper2.png'
import _imports_2 from '@/assets/images/wallpapers/wallpaper3.png'


const _hoisted_1 = { class: "in-app" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content" }
const _hoisted_4 = {
  class: "title",
  "data-test": "ia.preview.configbundlesheet.title"
}
const _hoisted_5 = {
  key: 0,
  class: "message",
  "data-test": "ia.preview.configbundlesheet.message"
}
const _hoisted_6 = { class: "buttons-container" }
const _hoisted_7 = {
  key: 0,
  class: "primary-button",
  "data-test": "ia.preview.configbundlesheet.primarytext"
}

import { ImageMetadata } from "@/api/image/typedefs";
import inAppPreviewBackgroundUrl from "@/assets/images/inapp-preview-background.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewConfigBundleSheet',
  props: {
    title: {},
    message: {},
    avatarImage: {},
    primaryText: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.avatarImage !== null)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "avatar",
              src: _ctx.avatarImage.url
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
          (_ctx.message !== null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.message), 1))
            : _createCommentVNode("", true)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", {
          class: "wallpapers",
          "data-test": "ia.preview.configbundlesheet.wallpapers"
        }, [
          _createElementVNode("img", { src: _imports_0 }),
          _createElementVNode("img", { src: _imports_1 }),
          _createElementVNode("img", { src: _imports_2 })
        ], -1)),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.primaryText)
            ? (_openBlock(), _createElementBlock("button", _hoisted_7, _toDisplayString(_ctx.primaryText), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})