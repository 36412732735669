import { Localization, LocalizationGuard } from "@/api/localizations/typedefs";
import { httpClient } from "@/lib/http";
import { cast, TypeCastError } from "@/lib/typing";

function convertLocalizations(fetchedData: {
  matches: unknown;
}): Localization[] {
  const languagesArray = fetchedData?.matches;
  if (!Array.isArray(languagesArray)) {
    throw new TypeCastError(
      `Invalid Localization data type to convert - ${fetchedData}`
    );
  }
  return languagesArray.map((fetchedLocalization) =>
    cast(LocalizationGuard, fetchedLocalization)
  );
}

export const LOCALIZATIONS_PER_PAGE = 10;

export async function fetchLocalizations(
  pageNumber = 1,
  filterString = ""
): Promise<Localization[]> {
  const queryParams: Record<string, string> = {
    page: pageNumber.toString(),
    per_page: LOCALIZATIONS_PER_PAGE.toString(),
  };
  if (filterString) {
    queryParams.q = filterString;
  }
  const queryParamsString = new URLSearchParams(queryParams).toString();
  // TODO(PNS-2194): Add ticket to remove available_languages from inapps.api_urls
  const url = `/api/language-list?${queryParamsString}`;
  const response = await httpClient.get(url);
  const responseBody = await response.json();
  return convertLocalizations(responseBody);
}
