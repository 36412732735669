import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-wallpaper-sheet-data" }

import QInput from 'quasar/src/components/input/QInput.js';;
import { computed } from "vue";

import { ImageMetadata } from "@/api/image/typedefs";
import { InAppDataWallpaperSheet } from "@/api/inapps/typedefs/inAppData";
import InAppButtonActionInput from "@/components/inapps/detail/InAppButtonActionInput.vue";
import ImageSelect from "@/components/shared/imageselect/ImageSelect.vue";
import { nonEmptyStringOrNull } from "@/lib/converters";
import { bindPropToRef } from "@/lib/propToRef";
import {
  isStrCommaSeparatedListOfInts,
  isStrCommaSeparatedListOfStrings,
} from "@/lib/rules/idsValidator";
import {
  isNonEmptyString,
  isNonWhiteCharOnlyString,
} from "@/lib/rules/stringValidatior";
import { ValidationRule } from "@/lib/rules/typedefs";
import { isValidActionUrl } from "@/lib/rules/urlValidator";

import { buildInAppDataInputRules } from "./dataRules";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataWallpaperSheet',
  props: {
    modelValue: {},
    isDisabled: { type: Boolean },
    isAddAvatarImageButtonVisible: { type: Boolean },
    isAddAvatarImageDarkButtonVisible: { type: Boolean },
    isAddImageButtonVisible: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function onInAppUpdate(changedData: Partial<InAppDataWallpaperSheet>) {
  emit("update:model-value", { ...props.modelValue, ...changedData });
}
const inAppLocal = bindPropToRef<InAppDataWallpaperSheet>(
  () => props.modelValue,
  onInAppUpdate
);

const wallpaperIDsRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonEmptyString, isNonWhiteCharOnlyString],
  [isStrCommaSeparatedListOfInts]
);

const themeIDsRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [isNonWhiteCharOnlyString],
  [isStrCommaSeparatedListOfStrings]
);

const avatarImageRules: ValidationRule<ImageMetadata | null>[] = [
  (val: ImageMetadata | null) =>
    val !== null ||
    props.modelValue.avatarImageDark === null ||
    "Avatar image is required if dark avatar image is used",
];

const buttonActionsRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [],
  [isValidActionUrl]
);

return (_ctx: any,_cache: any) => {
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[11] || (_cache[11] = _createElementVNode("div", {
          "data-test": "ia.wallpapersheetdata.header",
          class: "text-h6 q-mb-sm"
        }, " Wallpaper Sheet data ", -1)),
        _createVNode(ImageSelect, {
          modelValue: _unref(inAppLocal).image,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(inAppLocal).image) = $event)),
          "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
          "data-test": "ia.wallpapersheetdata.image",
          "image-constraint-names": ['sheet'],
          hint: "Image for your message",
          label: "Image",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-image-button-visible", "disable"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).title,
          filled: "",
          label: "Title",
          hint: "Title of your message",
          "data-test": "ia.wallpapersheetdata.title",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(inAppLocal).title = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).message,
          filled: "",
          label: "Message",
          hint: "Text inside your message",
          "data-test": "ia.wallpapersheetdata.message",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_unref(inAppLocal).message = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).primaryButtonText,
          filled: "",
          label: "Primary text",
          hint: "Text of the primary button",
          "data-test": "ia.wallpapersheetdata.primarytext",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (
          _unref(inAppLocal).primaryButtonText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).secondaryButtonText,
          filled: "",
          label: "Secondary text",
          hint: "Text of the secondary button",
          "data-test": "ia.wallpapersheetdata.secondarytext",
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (
          _unref(inAppLocal).secondaryButtonText = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "disable"]),
        _createVNode(InAppButtonActionInput, {
          modelValue: _unref(inAppLocal).secondaryButtonAction,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(inAppLocal).secondaryButtonAction) = $event)),
          "data-test": "ia.wallpapersheetdata.secondaryaction",
          label: "Secondary action",
          hint: "Action of the secondary button",
          "is-disabled": _ctx.isDisabled,
          "type-options": ['url'],
          rules: _unref(buttonActionsRules)
        }, null, 8, ["modelValue", "is-disabled", "rules"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).wallpaperIds,
          filled: "",
          label: "Wallpaper IDs",
          hint: "Comma separated IDs of wallpapers.",
          "data-test": "ia.wallpapersheetdata.wallpaperids",
          rules: _unref(wallpaperIDsRules),
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (
          _unref(inAppLocal).wallpaperIds = _unref(nonEmptyStringOrNull)($event)
        ))
        }, null, 8, ["model-value", "rules", "disable"]),
        _createVNode(_unref(QInput), {
          "model-value": _unref(inAppLocal).themeIds,
          filled: "",
          label: "Theme IDs",
          hint: "Comma separated IDs of themes.",
          "data-test": "ia.wallpapersheetdata.themeids",
          rules: _unref(themeIDsRules),
          disable: _ctx.isDisabled,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_unref(inAppLocal).themeIds = _unref(nonEmptyStringOrNull)($event)))
        }, null, 8, ["model-value", "rules", "disable"]),
        _createVNode(ImageSelect, {
          modelValue: _unref(inAppLocal).avatarImage,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(inAppLocal).avatarImage) = $event)),
          "is-add-image-button-visible": _ctx.isAddAvatarImageButtonVisible,
          "data-test": "ia.wallpapersheetdata.avatarimage",
          "image-constraint-names": ['config_bundle_sheet_avatar'],
          hint: "Avatar image for your message",
          rules: avatarImageRules,
          label: "Avatar image",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-image-button-visible", "disable"]),
        _createVNode(ImageSelect, {
          modelValue: _unref(inAppLocal).avatarImageDark,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(inAppLocal).avatarImageDark) = $event)),
          "is-add-image-button-visible": _ctx.isAddAvatarImageDarkButtonVisible,
          "data-test": "ia.wallpapersheetdata.avatarimagedark",
          "image-constraint-names": ['config_bundle_sheet_avatar'],
          hint: "Dark avatar image for your message",
          label: "Dark avatar image",
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "is-add-image-button-visible", "disable"]),
        _createVNode(_component_q_field, {
          square: "",
          filled: "",
          hint: "Text alignment"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn_toggle, {
              modelValue: _unref(inAppLocal).centerText,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(inAppLocal).centerText) = $event)),
              disable: _ctx.isDisabled,
              "data-test": "ia.wallpapersheetdata.centertext",
              "toggle-color": "primary",
              clearable: "",
              unelevated: "",
              options: [
            { label: 'Left align', value: false },
            { label: 'Center align', value: true },
          ]
            }, null, 8, ["modelValue", "disable"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})