import { InAppType } from "@/api/inapps";
import { InAppData } from "@/api/inapps/typedefs/inApp";
import { InAppTypeGuard } from "@/api/inapps/typedefs/inAppTypes";
import { buildInAppData } from "@/views/inapps/inAppBuilder";

export const INAPP_TYPES: InAppType[] = [];
for (const key in InAppTypeGuard.keys) {
  INAPP_TYPES.push(key as InAppType);
}

export const INAPP_TYPES_WITH_DATA = INAPP_TYPES.filter((el) => {
  return el !== "Rate Dialog";
});

export function getNewInAppLocalizationData(
  type: InAppType,
  langCode: string,
  langName: string
): InAppData {
  const template = buildInAppData(type);
  return {
    ...template,
    langCode: langCode,
    langName: langName,
  };
}

export type SubscriptionOfferData = {
  id: string;
  productId: string;
  expirationTime: string;
  isPersonalized: boolean;
};

export type SubscriptionOfferProps = {
  modelValue: SubscriptionOfferData;
  inAppEndDate: Date | null;
  offers: SubscriptionOfferData[];
};
