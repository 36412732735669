import { computed } from "vue";

import { ShallowReadonlyRef } from "@/lib/typing";

import { buildSegmentConfig, SegmentConfig } from "./segmentConfig";
import { SegmentType } from "./segmentType";

export interface UseExpressionInputConfigOptions {
  availableSegmentTypes: ShallowReadonlyRef<SegmentType[]>;
}

export function useExpressionInputConfig({
  availableSegmentTypes,
}: UseExpressionInputConfigOptions) {
  const segmentConfigOptions = computed(() =>
    availableSegmentTypes.value.map((id) => buildSegmentConfig(id))
  );

  const segmentTypeToConfigs = computed(() => {
    const typeToConfig = new Map<SegmentType, SegmentConfig>();
    for (const option of segmentConfigOptions.value) {
      typeToConfig.set(option.segmentType, option);
    }
    return typeToConfig;
  });

  // Methods.
  const getSegmentConfig = (id: SegmentType) => {
    return segmentTypeToConfigs.value.get(id);
  };

  return {
    segmentConfigOptions,
    segmentTypeToConfigs,
    getSegmentConfig,
  };
}
