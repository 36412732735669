import { assign, createMachine, DoneInvokeEvent } from "xstate";

import { ExistingInApp, InAppStatus, InAppType } from "@/api/inapps";
import {
  InAppPriority,
  isExistingInApp,
  NewInApp,
  NewOrExistingInApp,
} from "@/api/inapps/typedefs/inApp";
import { Tag } from "@/api/tags/typedefs";
import { APIError, StateMachineError } from "@/lib/errors";
import {
  buildEmptyInApp,
  DEFAULT_NEW_INAPP_TYPE,
} from "@/views/inapps/inAppBuilder";

type LoadInAppEvent = {
  type: "LOAD_INAPP";
  productLineId: number;
  inAppId: number;
};

type CreateNewEvent = {
  type: "CREATE_NEW";
  inAppType: InAppType;
  initialPriority: InAppPriority;
  defaultTags: Tag[];
};

type CreateNewClonedEvent = {
  type: "CREATE_NEW_CLONED";
  inApp: NewInApp;
};

type ModifyEvent = {
  type: "MODIFY";
  inApp: NewOrExistingInApp;
};

type DeleteEvent = {
  type: "DELETE";
  reason: string;
};

type SaveEvent = {
  type: "SAVE";
  isSavingPausedModifiedInApp?: boolean;
};

type ActivateEvent = {
  type: "ACTIVATE";
};

type PauseEvent = {
  type: "PAUSE";
};

type ReactivateEvent = {
  type: "REACTIVATE";
};

type FinishEvent = {
  type: "FINISH";
};

// TODO(PNS-2012): These should not be external events - it is only result
// of action. We should instead have events: validate, activate, delete.
type BackendEvent =
  | { type: "BACKEND_SET_DRAFT"; inApp: ExistingInApp }
  | { type: "BACKEND_SET_ACTIVE"; inApp: ExistingInApp }
  | { type: "BACKEND_SET_PENDING"; inApp: ExistingInApp }
  | { type: "BACKEND_SET_PAUSED"; inApp: ExistingInApp }
  | { type: "BACKEND_SET_FINISHED"; inApp: ExistingInApp }
  | { type: "BACKEND_SET_DELETED"; inApp: ExistingInApp };

type InAppFlowEvent =
  | BackendEvent
  | LoadInAppEvent
  | CreateNewEvent
  | CreateNewClonedEvent
  | ModifyEvent
  | DeleteEvent
  | SaveEvent
  | ActivateEvent
  | PauseEvent
  | FinishEvent
  | ReactivateEvent;

type InAppContext = {
  inApp: NewInApp | ExistingInApp;
  error: Error | null;
};

type NewInAppContext = {
  inApp: NewInApp;
  error: Error | null;
};

type ExistingInAppContext = {
  inApp: ExistingInApp;
  error: Error | null;
  isSavingPausedModifiedInApp: boolean;
};

type InAppTypeState =
  | { value: "start"; context: NewInAppContext }
  | { value: "new"; context: NewInAppContext }
  | { value: "new.unmodified"; context: NewInAppContext }
  | { value: "new.modified"; context: NewInAppContext }
  | { value: "fetched"; context: ExistingInAppContext }
  | { value: "fetched.loading"; context: ExistingInAppContext }
  | { value: "fetched.failure"; context: ExistingInAppContext }
  | { value: "fetched.success"; context: ExistingInAppContext }
  | { value: "save"; context: InAppContext }
  | { value: "save.loading"; context: InAppContext }
  | { value: "save.failure"; context: InAppContext }
  | { value: "save.success"; context: ExistingInAppContext }
  | { value: "draft"; context: ExistingInAppContext }
  | { value: "draft.unmodified"; context: ExistingInAppContext }
  | { value: "draft.modified"; context: ExistingInAppContext }
  | { value: "pending"; context: ExistingInAppContext }
  | { value: "active"; context: ExistingInAppContext }
  | { value: "paused"; context: ExistingInAppContext }
  | { value: "paused.unmodified"; context: ExistingInAppContext }
  | { value: "paused.modified"; context: ExistingInAppContext }
  | { value: "finished"; context: ExistingInAppContext }
  | { value: "deleting"; context: ExistingInAppContext }
  | { value: "deleted"; context: ExistingInAppContext };

export type InAppMachineStates = InAppTypeState["value"];

export type InAppFlowMachine = ReturnType<
  typeof useInAppFlowMachine
>["inAppFlowMachine"];

interface UseInAppFlowMachineOptions {
  fetchInAppCallback: (
    productLineId: number,
    inAppId: number
  ) => Promise<ExistingInApp>;
  saveInAppCallback: (
    inApp: NewInApp | ExistingInApp
  ) => Promise<ExistingInApp>;
  deleteInAppCallback: (inAppId: number, reason: string) => Promise<void>;
  activateInAppCallback: (inApp: ExistingInApp) => Promise<ExistingInApp>;
  pauseInAppCallback: (inApp: ExistingInApp) => Promise<ExistingInApp>;
  reactivateInAppCallback: (inApp: ExistingInApp) => Promise<ExistingInApp>;
  finishInAppCallback: (inApp: ExistingInApp) => Promise<ExistingInApp>;
}

export const useInAppFlowMachine = (options: UseInAppFlowMachineOptions) => {
  async function invokeLoadInApp(
    _context: InAppContext,
    event: InAppFlowEvent
  ) {
    if (event.type !== "LOAD_INAPP") {
      throw new Error(`invokeLoadInApp got unexpected event ${event}`);
    }

    return options.fetchInAppCallback(event.productLineId, event.inAppId);
  }

  async function invokeSaveInApp(context: InAppContext, event: InAppFlowEvent) {
    if (event.type !== "SAVE") {
      throw new Error(`invokeSaveInApp got unexpected event ${event}`);
    }

    return options.saveInAppCallback(context.inApp);
  }

  async function invokeDeleteInApp(
    context: InAppContext,
    event: InAppFlowEvent
  ) {
    if (event.type !== "DELETE" || !isExistingInApp(context.inApp)) {
      throw new Error(
        `invokeDeleteInApp got unexpected event ${event} / context ${context}`
      );
    }

    return options.deleteInAppCallback(context.inApp.id, event.reason);
  }

  async function invokeActivateInApp(
    context: InAppContext,
    event: InAppFlowEvent
  ) {
    if (event.type !== "ACTIVATE" || !isExistingInApp(context.inApp)) {
      throw new Error(
        `invokeActivateInApp got unexpected event ${event} / context ${context}`
      );
    }

    return options.activateInAppCallback(context.inApp);
  }

  async function invokePauseInApp(
    context: InAppContext,
    event: InAppFlowEvent
  ) {
    if (event.type !== "PAUSE" || !isExistingInApp(context.inApp)) {
      throw new Error(`got unexpected event ${event} / context ${context}`);
    }

    return options.pauseInAppCallback(context.inApp);
  }

  async function invokeReactivateInApp(
    context: InAppContext,
    event: InAppFlowEvent
  ) {
    if (event.type !== "REACTIVATE" || !isExistingInApp(context.inApp)) {
      throw new Error(`got unexpected event ${event} / context ${context}`);
    }

    return options.reactivateInAppCallback(context.inApp);
  }

  async function invokeFinishInApp(
    context: InAppContext,
    event: InAppFlowEvent
  ) {
    if (event.type !== "FINISH" || !isExistingInApp(context.inApp)) {
      throw new Error(`got unexpected event ${event} / context ${context}`);
    }

    return options.finishInAppCallback(context.inApp);
  }

  function statusMatches(targetStatus: InAppStatus) {
    return (context: InAppContext) => {
      if (targetStatus === "Draft" && !isExistingInApp(context.inApp)) {
        return true;
      }

      return (
        isExistingInApp(context.inApp) && context.inApp.status === targetStatus
      );
    };
  }

  function checkIsSavingPausedModifiedInApp(context: InAppContext): boolean {
    if ("isSavingPausedModifiedInApp" in context) {
      const result = Boolean(context.isSavingPausedModifiedInApp);
      context.isSavingPausedModifiedInApp = false;
      return result;
    }
    return false;
  }

  const InAppFlowMachine = createMachine<
    InAppContext,
    InAppFlowEvent,
    InAppTypeState
  >({
    /**
     * The general idea for the flow:
     * - initial state is the state of new, non-existing inapp;
     * - from the initial state one can load existing inapp (via one of the LoadedEvents)...
     * - ...or save new inapp with BACKEND_SET_DRAFT;
     * - InApp object is retrieved from BE and passed with events, this is much simpler
     *   than calling again for new state.
     */
    id: "InAppFlow",
    initial: "start",
    context: () => ({
      inApp: buildEmptyInApp(DEFAULT_NEW_INAPP_TYPE),
      error: new StateMachineError("Failed to initialize in app."),
      isSavingPausedModifiedInApp: false,
    }),
    on: {
      CREATE_NEW_CLONED: {
        // NOTE: targeting `modified` as cloned in app can be immediately saved.
        target: "#new.modified",
        actions: [
          assign<InAppContext, CreateNewClonedEvent>({
            error: null,
            inApp: (_, event) => event.inApp,
          }),
        ],
      },
    },
    states: {
      start: {
        id: "start",
        on: {
          // TODO(PNS-2511): Rename to just "LOAD"
          LOAD_INAPP: {
            target: "#fetched",
            actions: [
              assign<InAppContext, LoadInAppEvent>({
                error: null,
              }),
            ],
          },
          CREATE_NEW: {
            target: "#new.unmodified",
            actions: [
              assign<InAppContext, CreateNewEvent>({
                error: null,
                inApp: (_, event) => ({
                  ...buildEmptyInApp(event.inAppType),
                  priority: event.initialPriority,
                  tags: event.defaultTags,
                }),
              }),
            ],
          },
        },
      },
      new: {
        id: "new",
        initial: "unmodified",
        states: {
          modified: {
            on: {
              SAVE: {
                target: "#save",
              },
            },
          },
          unmodified: {},
        },
        on: {
          MODIFY: {
            target: ".modified",
            actions: assign({ inApp: (_, event) => event.inApp }),
          },
        },
      },
      deleting: {
        id: "deleting",
        initial: "loading",
        states: {
          loading: {},
          success: {
            always: [{ target: "#deleted", cond: statusMatches("Deleted") }],
          },
          failure: {
            always: [{ target: "#draft", cond: statusMatches("Draft") }],
          },
        },
        invoke: {
          id: "delete-inapp",
          src: (context, event) => invokeDeleteInApp(context, event),
          onDone: {
            target: ".success",
            actions: [
              // NOTE: xstate cannot infer typing here.
              assign<InAppContext, DoneInvokeEvent<void>>({
                inApp: (context) => ({
                  ...context.inApp,
                  status: "Deleted",
                }),
              }),
            ],
          },
          onError: {
            target: ".failure",
            // NOTE: xstate cannot infer typing here.
            actions: assign<InAppContext, DoneInvokeEvent<Error>>({
              error: (_, event) => new APIError(event.data.message),
            }),
          },
        },
      },
      save: {
        id: "save",
        initial: "loading",
        states: {
          loading: {},
          success: {
            always: [
              { target: "#draft", cond: statusMatches("Draft") },
              { target: "#pending", cond: statusMatches("Pending") },
              { target: "#active", cond: statusMatches("Active") },
              { target: "#paused", cond: statusMatches("Paused") },
              { target: "#finished", cond: statusMatches("Finished") },
              { target: "#deleted", cond: statusMatches("Deleted") },
            ],
          },
          failure: {
            always: [
              {
                target: "#new.modified",
                cond: (context: InAppContext) =>
                  !isExistingInApp(context.inApp),
              },
              { target: "#draft", cond: statusMatches("Draft") },
              { target: "#pending", cond: statusMatches("Pending") },
              { target: "#active", cond: statusMatches("Active") },
              {
                target: "#paused.modified",
                cond: (context: InAppContext) =>
                  checkIsSavingPausedModifiedInApp(context) &&
                  statusMatches("Paused")(context),
              },
              { target: "#paused", cond: statusMatches("Paused") },
              { target: "#finished", cond: statusMatches("Finished") },
              { target: "#deleted", cond: statusMatches("Deleted") },
            ],
          },
        },
        invoke: {
          id: "save-inapp",
          src: (context, event) => invokeSaveInApp(context, event),
          onDone: {
            target: ".success",
            actions: [
              // NOTE: xstate cannot infer typing here.
              assign<InAppContext, DoneInvokeEvent<ExistingInApp>>({
                inApp: (_, event) => event.data,
              }),
            ],
          },
          onError: {
            target: ".failure",
            // NOTE: xstate cannot infer typing here.
            actions: assign<InAppContext, DoneInvokeEvent<Error>>({
              error: (_, event) => new APIError(event.data.message),
            }),
          },
        },
      },
      fetched: {
        id: "fetched",
        initial: "loading",
        states: {
          loading: {},
          success: {
            always: [
              { target: "#draft", cond: statusMatches("Draft") },
              { target: "#pending", cond: statusMatches("Pending") },
              { target: "#active", cond: statusMatches("Active") },
              { target: "#paused", cond: statusMatches("Paused") },
              { target: "#finished", cond: statusMatches("Finished") },
              { target: "#deleted", cond: statusMatches("Deleted") },
            ],
          },
          failure: {},
        },
        invoke: {
          id: "load-inapp",
          src: (context, event) => invokeLoadInApp(context, event),
          onDone: {
            target: ".success",
            actions: [
              // NOTE: xstate cannot infer typing here.
              assign<InAppContext, DoneInvokeEvent<ExistingInApp>>({
                inApp: (_, event) => event.data,
              }),
            ],
          },
          onError: {
            target: ".failure",
            // NOTE: xstate cannot infer typing here.
            actions: assign<InAppContext, DoneInvokeEvent<Error>>({
              error: (_, event) => new StateMachineError(event.data.message),
            }),
          },
        },
      },
      draft: {
        id: "draft",
        initial: "unmodified",
        states: {
          modified: {
            on: {
              SAVE: {
                target: "#save",
              },
            },
          },
          unmodified: {
            on: {
              ACTIVATE: {
                target: "#activating",
              },
            },
          },
        },
        on: {
          LOAD_INAPP: {
            target: "#fetched",
          },
          MODIFY: {
            target: ".modified",
            actions: assign({ inApp: (_, event) => event.inApp }),
          },
          DELETE: {
            target: "#deleting",
          },
        },
      },
      activating: {
        id: "activating",
        initial: "loading",
        states: {
          loading: {},
          success: {
            always: [
              { target: "#pending", cond: statusMatches("Pending") },
              { target: "#active", cond: statusMatches("Active") },
            ],
          },
          failure: {
            always: [{ target: "#draft", cond: statusMatches("Draft") }],
          },
        },
        invoke: {
          id: "activate-inapp",
          src: (context, event) => invokeActivateInApp(context, event),
          onDone: {
            target: ".success",
            actions: [
              // NOTE: xstate cannot infer typing here.
              assign<InAppContext, DoneInvokeEvent<ExistingInApp>>({
                inApp: (context, event) => event.data,
              }),
            ],
          },
          onError: {
            target: ".failure",
            // NOTE: xstate cannot infer typing here.
            actions: assign<InAppContext, DoneInvokeEvent<Error>>({
              error: (_, event) => new APIError(event.data.message),
            }),
          },
        },
      },
      pausing: {
        id: "pausing",
        initial: "loading",
        states: {
          loading: {},
          success: {
            always: [{ target: "#paused", cond: statusMatches("Paused") }],
          },
          failure: {
            always: [
              { target: "#pending", cond: statusMatches("Pending") },
              { target: "#active", cond: statusMatches("Active") },
            ],
          },
        },
        invoke: {
          id: "pause-inapp",
          src: (context, event) => invokePauseInApp(context, event),
          onDone: {
            target: ".success",
            actions: [
              // NOTE: xstate cannot infer typing here.
              assign<InAppContext, DoneInvokeEvent<ExistingInApp>>({
                inApp: (context, event) => event.data,
              }),
            ],
          },
          onError: {
            target: ".failure",
            // NOTE: xstate cannot infer typing here.
            actions: assign<InAppContext, DoneInvokeEvent<Error>>({
              error: (_, event) => new APIError(event.data.message),
            }),
          },
        },
      },
      reactivating: {
        id: "reactivating",
        initial: "loading",
        states: {
          loading: {},
          success: {
            always: [
              { target: "#active", cond: statusMatches("Active") },
              { target: "#pending", cond: statusMatches("Pending") },
            ],
          },
          failure: {
            // Either pending or active can be paused so in case of failure try to match one of these.
            always: [{ target: "#paused", cond: statusMatches("Paused") }],
          },
        },
        invoke: {
          id: "reactivate-inapp",
          src: (context, event) => invokeReactivateInApp(context, event),
          onDone: {
            target: ".success",
            actions: [
              // NOTE: xstate cannot infer typing here.
              assign<InAppContext, DoneInvokeEvent<ExistingInApp>>({
                inApp: (context, event) => event.data,
              }),
            ],
          },
          onError: {
            target: ".failure",
            // NOTE: xstate cannot infer typing here.
            actions: assign<InAppContext, DoneInvokeEvent<Error>>({
              error: (context, event) => new APIError(event.data.message),
            }),
          },
        },
      },
      finishing: {
        id: "finishing",
        initial: "loading",
        states: {
          loading: {},
          success: {
            always: [{ target: "#finished", cond: statusMatches("Finished") }],
          },
          failure: {
            always: [{ target: "#paused", cond: statusMatches("Paused") }],
          },
        },
        invoke: {
          id: "finish-inapp",
          src: (context, event) => invokeFinishInApp(context, event),
          onDone: {
            target: ".success",
            actions: [
              // NOTE: xstate cannot infer typing here.
              assign<InAppContext, DoneInvokeEvent<ExistingInApp>>({
                inApp: (context, event) => event.data,
              }),
            ],
          },
          onError: {
            target: ".failure",
            // NOTE: xstate cannot infer typing here.
            actions: assign<InAppContext, DoneInvokeEvent<Error>>({
              error: (_, event) => new APIError(event.data.message),
            }),
          },
        },
      },
      pending: {
        id: "pending",
        on: {
          PAUSE: {
            target: "#pausing",
          },
        },
      },
      active: {
        id: "active",
        on: {
          PAUSE: {
            target: "#pausing",
          },
        },
      },
      paused: {
        id: "paused",
        initial: "unmodified",
        states: {
          modified: {
            on: {
              SAVE: {
                target: "#save",
                // NOTE: xstate cannot infer typing here.
                actions: assign<InAppContext, SaveEvent>({
                  isSavingPausedModifiedInApp: true,
                } as ExistingInAppContext),
              },
            },
          },
          unmodified: {
            on: {
              REACTIVATE: {
                target: "#reactivating",
              },
            },
          },
        },
        on: {
          FINISH: {
            target: "#finishing",
          },
          MODIFY: {
            target: ".modified",
            actions: assign({ inApp: (_, event) => event.inApp }),
          },
        },
      },
      finished: {
        // NOTE: This state cannot by "final" as user should be able to clone from it.
        id: "finished",
      },
      deleted: {
        // NOTE: This state cannot by "final" as user should be able to clone from it.
        id: "deleted",
      },
    },
  });
  return {
    inAppFlowMachine: InAppFlowMachine,
  };
};
