import { SegmentRawConfig } from "./segmentRawConfig";

export function buildSegmentInputConfig(
  config: SegmentRawConfig
): SegmentInputConfig | undefined {
  if (config.type === "") return undefined;
  return {
    inputType: config.type,
    limiterMin: config.limiter_min,
  };
}

export interface SegmentInputConfig {
  readonly inputType: "number" | "text";
  readonly limiterMin: number | undefined;
}
