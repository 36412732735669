import * as t from "io-ts";

export enum SegmentType {
  LIMIT_FREQUENCY = "limit_frequency",
  USER_APPLIED_CONFIG_BUNDLE = "user_applied_config_bundle",
  USER_ENTERED_START_PAGE = "user_entered_start_page",
  USER_ENTERED_START_PAGE_CLASSIC_NEWSFEED = "user_entered_start_page_in_classic_mode_with_newsfeed",
  USER_READY_FOR_CONFIG_BUNDLE = "user_ready_for_config_bundle",
  USER_RESUMED_APP_ON_WEB_PAGE = "user_resumed_app_on_web_page",
  USER_STARTS_APP = "starts_app",
  USER_STARTS_RESUMES_APP = "starts_resumes_app",
}

export const SegmentTypeGuard = t.keyof({
  [SegmentType.LIMIT_FREQUENCY]: t.null,
  [SegmentType.USER_APPLIED_CONFIG_BUNDLE]: t.null,
  [SegmentType.USER_ENTERED_START_PAGE]: t.null,
  [SegmentType.USER_ENTERED_START_PAGE_CLASSIC_NEWSFEED]: t.null,
  [SegmentType.USER_READY_FOR_CONFIG_BUNDLE]: t.null,
  [SegmentType.USER_RESUMED_APP_ON_WEB_PAGE]: t.null,
  [SegmentType.USER_STARTS_APP]: t.null,
  [SegmentType.USER_STARTS_RESUMES_APP]: t.null,
});

export const SegmentTypeRawGuard = t.type({
  id: SegmentTypeGuard,
});
