import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

import QCard from 'quasar/src/components/card/QCard.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import QInput from 'quasar/src/components/input/QInput.js';import QSelect from 'quasar/src/components/select/QSelect.js';;
import { computed } from "vue";

import {
  InAppPriority,
  isExistingInApp,
  NewOrExistingInApp,
} from "@/api/inapps/typedefs/inApp";
import { InAppTypeGuard } from "@/api/inapps/typedefs/inAppTypes";
import { fetchTags } from "@/api/tags/list";
import DateTimeInput from "@/components/shared/DateTimeInput.vue";
import ReadonlyField from "@/components/shared/ReadonlyField.vue";
import TagsCategories from "@/components/shared/tags/TagsCategories.vue";
import TagSelect from "@/components/shared/tags/TagSelect.vue";
import { isEndDateAfterStartDate } from "@/lib/dateTime/dateTime";
import { ProductName } from "@/lib/productLine";
import { bindPropToRef } from "@/lib/propToRef";
import { isNotEmptyArray } from "@/lib/rules/arrayValidator";
import { SelectOption } from "@/lib/select";
import { getStatusColor } from "@/lib/status";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppInternalData',
  props: {
    inApp: {},
    isDisabled: { type: Boolean },
    isTagEditable: { type: Boolean },
    campaignAmgUrl: {},
    priorityOptions: {},
    isSuperuser: { type: Boolean },
    productName: {}
  },
  emits: ["update:inApp"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function onInAppUpdate(newValue: NewOrExistingInApp) {
  emit("update:inApp", newValue);
}
const inAppLocal = bindPropToRef<NewOrExistingInApp>(
  () => props.inApp,
  onInAppUpdate
);

const isRuntimeText = computed((): string => {
  if (inAppLocal.value.isRuntime === true) {
    return "Yes";
  } else if (inAppLocal.value.isRuntime === false) {
    return "No";
  } else {
    return "N/A";
  }
});

/**
 * Returns true if already defined state of the inapp suggests that campaing ID
 * is applicable.
 *
 * Campaign ID has any sense only if theres any non-null action of URL type.
 */
const acceptsCampaignId = function () {
  const inappData = inAppLocal.value.inAppData;

  if (inappData === undefined) {
    return false;
  }

  // note: contents of inAppData will be different depending on the type of the inapp.
  // Technically, we could probably somehow digest the type definition of the inAppData
  // for given type to tell if it actually can have action of URL type. On the other
  // hand, there are inapp types which have actions that can be of many types or have
  // optional URL actions. Checking type definition would be then only a hint but one
  // that is hard to pull off.
  //
  // Also, inapp with campaign ID for which all URL actions are optional isn't valid
  // as it will cause troubles down the line. That's why we need to check actual values.
  // Positive side of this is that we can use this function for both enabling campain
  // id field but also for validating it.
  for (const item of inappData) {
    for (const prop of Object.values(item)) {
      // note: we don't care if value is a valid URL because it will be
      // eventually validated elsewhere
      if (prop && Object.hasOwn(prop, "type") && prop.type === "url") {
        return true;
      }
    }
  }

  return false;
};

const isConformingCampaignId = (val: number | null): string | boolean =>
  val === null ||
  (Number.isInteger(Number(val)) && val >= 0) ||
  "Campaign ID must be a number higher or equal 0";

const isCampaignIdApplicable = (): string | boolean =>
  acceptsCampaignId() ||
  "Campaign ID can be set only for inapps with valid URL action(s)";

const tagsHint = computed((): string => {
  if (props.productName === "OfA") {
    return "Tags help categorize your message and impact targeting";
  }
  return "Tags help categorize your message";
});

const displayFilteringMessage = computed((): boolean => {
  return (
    props.productName === "OfA" &&
    props.inApp.inAppType === InAppTypeGuard.keys["Config Bundle Sheet"].value
  );
});

return (_ctx: any,_cache: any) => {
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!

  return (_openBlock(), _createBlock(_unref(QCard), null, {
    default: _withCtx(() => [
      (_unref(isExistingInApp)(_unref(inAppLocal)))
        ? (_openBlock(), _createBlock(_unref(QCardSection), {
            key: 0,
            class: "col row"
          }, {
            default: _withCtx(() => [
              _createVNode(ReadonlyField, {
                "data-test": "ia.form.field.inappid",
                class: "col",
                label: "InApp ID",
                value: String(_unref(inAppLocal).id)
              }, null, 8, ["value"]),
              _createVNode(ReadonlyField, {
                "data-test": "ia.form.field.createdby",
                class: "col",
                label: "Created by",
                value: _unref(inAppLocal).createdBy
              }, null, 8, ["value"]),
              _createVNode(ReadonlyField, {
                "data-test": "ia.form.field.createdat",
                class: "col",
                label: "Created at",
                value: _unref(inAppLocal).createdAt
              }, null, 8, ["value"]),
              _createVNode(ReadonlyField, {
                "data-test": "ia.form.field.modifiedby",
                class: "col",
                label: "Modified by",
                value: _unref(inAppLocal).modifiedBy
              }, null, 8, ["value"]),
              _createVNode(ReadonlyField, {
                "data-test": "ia.form.field.modifiedat",
                class: "col",
                label: "Modified at",
                value: _unref(inAppLocal).modifiedAt
              }, null, 8, ["value"]),
              _createVNode(ReadonlyField, {
                "data-test": "ia.form.field.status",
                class: "col",
                label: "Status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_badge, {
                    color: _unref(getStatusColor)(_unref(inAppLocal).status),
                    label: _unref(inAppLocal).status
                  }, null, 8, ["color", "label"])
                ]),
                _: 1
              }),
              _createVNode(ReadonlyField, {
                "data-test": "ia.form.field.isruntime",
                class: "col",
                label: "Runtime",
                value: isRuntimeText.value
              }, null, 8, ["value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(QCardSection), { class: "q-col-gutter-sm q-pb-sm" }, {
        default: _withCtx(() => [
          _createVNode(_unref(QInput), {
            modelValue: _unref(inAppLocal).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(inAppLocal).name) = $event)),
            filled: "",
            label: "Name",
            hint: "Name of your InApp",
            rules: [
          (val) => (val && val.length > 0) || 'InApp name cannot be empty',
        ],
            "data-test": "ia.form.field.name",
            disable: _ctx.isDisabled
          }, null, 8, ["modelValue", "rules", "disable"]),
          _createVNode(TagSelect, {
            modelValue: _unref(inAppLocal).tags,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(inAppLocal).tags) = $event)),
            disable: _ctx.isDisabled && !_ctx.isTagEditable,
            label: "Tags",
            hint: tagsHint.value,
            rules: [_unref(isNotEmptyArray)],
            "data-test": "ia.form.field.tags",
            "fetch-options": _unref(fetchTags)
          }, null, 8, ["modelValue", "disable", "hint", "rules", "fetch-options"]),
          (props.productName === 'OfA')
            ? (_openBlock(), _createBlock(TagsCategories, {
                key: 0,
                modelValue: _unref(inAppLocal).tags,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(inAppLocal).tags) = $event)),
                "data-test": "ia.form.field.categories"
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (displayFilteringMessage.value)
            ? (_openBlock(), _createBlock(_component_q_badge, {
                key: 1,
                color: "red",
                "data-test": "ia.form.field.omitfiltering"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Because of the inapp type, this message will be always delivered")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_unref(QInput), {
            modelValue: _unref(inAppLocal).inAppType,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(inAppLocal).inAppType) = $event)),
            filled: "",
            readonly: "",
            label: "InApp Type",
            hint: "Type of your message",
            "data-test": "ia.form.field.iatype"
          }, null, 8, ["modelValue"]),
          _createVNode(_unref(QSelect), {
            modelValue: _unref(inAppLocal).priority,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(inAppLocal).priority) = $event)),
            options: props.priorityOptions,
            filled: "",
            label: "InApp Priority",
            hint: "Priority of your message",
            "data-test": "ia.form.field.priority",
            disable: _ctx.isDisabled,
            "emit-value": ""
          }, null, 8, ["modelValue", "options", "disable"]),
          _createVNode(DateTimeInput, {
            modelValue: _unref(inAppLocal).startTime,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(inAppLocal).startTime) = $event)),
            label: "InApp start time",
            hint: "When to show InApp to targeted user",
            "data-test": "ia.form.field.starttime",
            disable: _ctx.isDisabled
          }, null, 8, ["modelValue", "disable"]),
          _createVNode(DateTimeInput, {
            modelValue: _unref(inAppLocal).endTime,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(inAppLocal).endTime) = $event)),
            label: "InApp end time",
            hint: "When to stop showing InApp to targeted user",
            "data-test": "ia.form.field.endtime",
            disable: _ctx.isDisabled,
            "extra-rules": [
          ($event) => _unref(isEndDateAfterStartDate)(_unref(inAppLocal).startTime, $event),
        ]
          }, null, 8, ["modelValue", "disable", "extra-rules"]),
          _createVNode(_unref(QInput), {
            "model-value": _unref(inAppLocal).campaignId,
            type: "number",
            "data-test": "ia.form.field.campaignid",
            filled: "",
            label: "Campaign ID",
            hint: "ID of AMG campaign your in app will be assigned to.\n              Can be empty, in which case it is automatically populated\n              each hour from the rid value of url. Can be set only if there's\n              at least one URL action defined.",
            disable: 
          _ctx.isDisabled || (!acceptsCampaignId() && !_unref(inAppLocal).campaignId)
        ,
            rules: [isConformingCampaignId, isCampaignIdApplicable],
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (
          _unref(inAppLocal).campaignId = $event ? Number($event) : null
        ))
          }, null, 8, ["model-value", "disable", "rules"]),
          _createVNode(_unref(QCardSection), { class: "row justify-end q-pt-none" }, {
            default: _withCtx(() => [
              (_ctx.campaignAmgUrl !== null)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    "data-test": "ia.form.button.campaignid",
                    outline: "",
                    color: "primary",
                    size: "sm",
                    label: "Go to campaign page",
                    type: "a",
                    target: "_blank",
                    href: _ctx.campaignAmgUrl
                  }, null, 8, ["href"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (props.isSuperuser)
            ? (_openBlock(), _createBlock(_component_q_item, {
                key: 2,
                tag: "label"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(QCheckbox), {
                        modelValue: _unref(inAppLocal).forceNonRuntime,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(inAppLocal).forceNonRuntime) = $event)),
                        "data-test": "ia.form.field.forcenonruntime"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("Force non-runtime")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_q_item_label, { caption: "" }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("Force InApp to be sent normally, with BigQuery, not with runtime mechanism")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})