import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, normalizeProps as _normalizeProps, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "inapp-form row" }
const _hoisted_2 = { class: "col-12 col-md-5 col-lg-4 col-xl-3 q-py-md preview" }
const _hoisted_3 = { class: "row justify-end q-gutter-sm" }

import QForm from 'quasar/src/components/form/QForm.js';;
import { Component, computed, ComputedRef, ref } from "vue";

import {
  ExistingInApp,
  InAppPriority,
  InAppTarget as InAppTargetType,
  isExistingInApp,
  NewOrExistingInApp,
} from "@/api/inapps/typedefs/inApp";
import InAppDataLocalization from "@/components/inapps/detail/InAppDataLocalization.vue";
import { useInAppForm } from "@/components/inapps/useInAppForm";
import ConversionReport from "@/components/shared/conversionreport/ConversionReport.vue";
import { MessageTypeEnum } from "@/components/shared/conversionreport/useConversionReport";
import { castExists } from "@/lib/casts";
import { ProductLine } from "@/lib/productLine";
import { SelectOption } from "@/lib/select";

import { InAppAction, InAppActionsConfig } from "../typedefs";
import InAppData from "./inappdata/InAppData.vue";
import InAppDisplayWhen from "./InAppDisplayWhen.vue";
import InAppFormActions from "./InAppFormActions.vue";
import InAppInternalData from "./InAppInternalData.vue";
import InAppRolloutStrategy from "./InAppRolloutStrategy.vue";
import InAppTarget from "./InAppTarget.vue";
import InAppVisualPreview from "./visualpreview/InAppVisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppForm',
  props: {
    productLine: {},
    target: {},
    isDisabled: { type: Boolean },
    isSuperuser: { type: Boolean },
    isAddTargetButtonVisible: { type: Boolean },
    isAddImageButtonVisible: { type: Boolean },
    isAddLottieButtonVisible: { type: Boolean },
    isTagEditable: { type: Boolean },
    actionButtonsConfig: {},
    inApp: {},
    priorityOptions: {}
  },
  emits: ["update:target", "action", "preview", "update:inApp", "validation-error"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const inApp: ComputedRef<NewOrExistingInApp> = computed(() => props.inApp);
const inAppForm = ref<QForm | null>(null);

function handleInAppUpdate(newInApp: NewOrExistingInApp) {
  emit("update:inApp", newInApp);
}

async function onAction(action: InAppAction) {
  if (action === "Save") {
    const isValid = await inAppForm.value?.validate(); // This should validate and show errors if not valid.
    if (!isValid) return;
  } else if (action === "Preview") {
    emit("preview", castExists(selectedLocalization.value || "default"));
    return;
  }

  emit("action", action);
}

const onValidationError = async (componentWithError: Component) => {
  await maybeSelectLocalizationWithError(componentWithError);
  emit("validation-error", componentWithError);
};

const allowedStatuses = ["Active", "Finished", "Failed", "Paused", "Pending"];

const isReportVisible = computed<boolean>((): boolean => {
  return (
    isExistingInApp(props.inApp) && allowedStatuses.includes(props.inApp.status)
  );
});
const formatMessageId = computed<string>((): string => {
  return `"ia_${(props.inApp as ExistingInApp).id.toString()}"`;
});

const {
  addInAppLocalization,
  updateInAppLocalization,
  updateInAppData,
  deleteInAppLocalization,
  acceptLocalizationUploadResult,
  selectedInAppData,
  inAppDataForPreview,
  selectedLocalization,
  maybeSelectLocalizationWithError,
  dataLocalizationAttribute,
  sortedInAppData,
  campaignAmgUrl,
} = useInAppForm({
  inApp,
  handleInAppUpdate,
});

return (_ctx: any,_cache: any) => {
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(InAppVisualPreview, {
        "in-app-data": _unref(inAppDataForPreview),
        "in-app-type": inApp.value.inAppType
      }, null, 8, ["in-app-data", "in-app-type"])
    ]),
    _createVNode(_unref(QForm), {
      ref_key: "inAppForm",
      ref: inAppForm,
      class: "col-12 col-md-7 col-lg-7 col-xl-8 q-py-md form q-gutter-y-md q-px form-max-width",
      greedy: "",
      onValidationError: onValidationError
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(InAppFormActions, {
            "actions-config": _ctx.actionButtonsConfig,
            onAction: onAction
          }, null, 8, ["actions-config"])
        ]),
        _createVNode(InAppInternalData, {
          "in-app": inApp.value,
          "is-tag-editable": _ctx.isTagEditable,
          "is-disabled": _ctx.isDisabled,
          "campaign-amg-url": _unref(campaignAmgUrl),
          "priority-options": _ctx.priorityOptions,
          "is-superuser": _ctx.isSuperuser,
          "product-name": _ctx.productLine.productName,
          "onUpdate:inApp": handleInAppUpdate
        }, null, 8, ["in-app", "is-tag-editable", "is-disabled", "campaign-amg-url", "priority-options", "is-superuser", "product-name"]),
        _createVNode(InAppDisplayWhen, {
          "data-test": "ia.displaywhen",
          "in-app": inApp.value,
          "is-disabled": _ctx.isDisabled,
          "product-line": _ctx.productLine,
          "onUpdate:inApp": handleInAppUpdate
        }, null, 8, ["in-app", "is-disabled", "product-line"]),
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            (isReportVisible.value)
              ? (_openBlock(), _createBlock(ConversionReport, {
                  key: 0,
                  "data-test": "ia.card.conversion_report",
                  "message-id": formatMessageId.value,
                  "product-line": _ctx.productLine,
                  "message-type": _unref(MessageTypeEnum).inApp
                }, null, 8, ["message-id", "product-line", "message-type"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(InAppRolloutStrategy, {
          "in-app": inApp.value,
          "is-disabled": _ctx.isDisabled,
          "onUpdate:inApp": handleInAppUpdate
        }, null, 8, ["in-app", "is-disabled"]),
        _createVNode(InAppTarget, {
          "model-value": _ctx.target,
          "product-line": _ctx.productLine,
          "is-disabled": _ctx.isDisabled,
          "is-add-target-button-visible": _ctx.isAddTargetButtonVisible,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('update:target', $event)))
        }, null, 8, ["model-value", "product-line", "is-disabled", "is-add-target-button-visible"]),
        (_unref(sortedInAppData) !== undefined)
          ? (_openBlock(), _createBlock(InAppDataLocalization, {
              key: 0,
              "selected-localization": _unref(selectedLocalization),
              "onUpdate:selectedLocalization": _cache[1] || (_cache[1] = ($event: any) => (_isRef(selectedLocalization) ? (selectedLocalization).value = $event : null)),
              "data-test": "ia.localization",
              "in-app-data": _unref(sortedInAppData),
              "in-app-type": props.inApp.inAppType,
              "is-disabled": _ctx.isDisabled,
              onAddLocalizationTab: _unref(addInAppLocalization),
              onEditLocalizationTab: _unref(updateInAppLocalization),
              onDeleteLocalizationTab: _unref(deleteInAppLocalization),
              onAcceptLocalizationUploadResult: _unref(acceptLocalizationUploadResult)
            }, {
              default: _withCtx(() => [
                (_unref(selectedInAppData))
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(sortedInAppData), (item) => {
                      return _withDirectives((_openBlock(), _createBlock(InAppData, _normalizeProps({
                        key: item.langCode,
                        [_unref(dataLocalizationAttribute) || ""]: item.langCode,
                        "is-disabled": _ctx.isDisabled,
                        "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
                        "is-add-lottie-button-visible": _ctx.isAddLottieButtonVisible,
                        "in-app-data": item,
                        "in-app": inApp.value,
                        onUpdateInAppData: _unref(updateInAppData)
                      }), null, 16, ["is-disabled", "is-add-image-button-visible", "is-add-lottie-button-visible", "in-app-data", "in-app", "onUpdateInAppData"])), [
                        [_vShow, item.langCode === _unref(selectedInAppData).langCode]
                      ])
                    }), 128))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["selected-localization", "in-app-data", "in-app-type", "is-disabled", "onAddLocalizationTab", "onEditLocalizationTab", "onDeleteLocalizationTab", "onAcceptLocalizationUploadResult"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512)
  ]))
}
}

})