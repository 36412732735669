import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createSlots as _createSlots, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "fileUploadRef",
  type: "file",
  accept: "application/json"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_select, _mergeProps(_ctx.$attrs, {
      ref: "selectRef",
      "model-value": _ctx.modelValue,
      filled: "",
      clearable: "",
      "use-input": "",
      label: _ctx.label ?? undefined,
      hint: _ctx.hint ?? undefined,
      options: _ctx.options,
      disable: _ctx.disable,
      rules: _ctx.rules,
      "option-label": _ctx.lottieOptionLabel,
      onFilter: _ctx.onFilter,
      onVirtualScroll: _ctx.onScroll,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }), _createSlots({
      "selected-item": _withCtx((scope) => [
        _createVNode(_component_q_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { avatar: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_img, {
                  src: scope.opt.url
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_item_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { "data-test": "lottieselect.selecteditem.label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(scope.opt.name), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      option: _withCtx((scope) => [
        _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { avatar: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_img, {
                  src: scope.opt.url,
                  "data-test": "lottieselect.option.img"
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_item_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { "data-test": "lottieselect.option.label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(scope.opt.name), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1040)
      ]),
      _: 2
    }, [
      (_ctx.isAddLottieButtonVisible)
        ? {
            name: "append",
            fn: _withCtx(() => [
              _createVNode(_component_q_btn, {
                round: "",
                dense: "",
                flat: "",
                icon: "add",
                "data-test": "lottieselect.button.addfile",
                onClick: _ctx.openFilePicker
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Click here to add a new lottie")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["model-value", "label", "hint", "options", "disable", "rules", "option-label", "onFilter", "onVirtualScroll"]),
    _createElementVNode("input", _hoisted_1, null, 512)
  ], 64))
}