import { assertNever } from "@/lib/typing";

import { SelectTypePerFrequency } from "./matching/perFrequency";
import { SegmentType } from "./segmentType";
import { splitByOperator } from "./splitByOperator";
import {
  Expr,
  ExprItem,
  ExprOperatorType,
  ExprSegment,
  ExprSegmentType,
} from "./typedefs";

export type JsonLogicSegmentValueVar = {
  var: SegmentType;
};

export type JsonLogicSegmentValue =
  | [JsonLogicSegmentValueVar, string | number]
  | [JsonLogicSegmentValueVar];

export type JsonLogicSegment = {
  // i.e.  { per_month: [...]}
  [key in string]: JsonLogicSegmentValue;
};

export type JsonLogicOr = {
  or: JsonLogicSegment[];
};

export type JsonLogicAnd = {
  and: JsonLogicSegment[];
};

export type JsonLogic = JsonLogicAnd | JsonLogicOr | JsonLogicSegment;

function isJsonLogicAnd(value: JsonLogic): value is JsonLogicAnd {
  return "and" in value;
}
function isJsonLogicOr(value: JsonLogic): value is JsonLogicOr {
  return "or" in value;
}

function exprSegmentToJsonLogic(segment: ExprSegment): JsonLogicSegment {
  switch (segment.segmentType) {
    case SegmentType.LIMIT_FREQUENCY: {
      if (segment.matching === undefined || segment.value === undefined) {
        throw new Error("matching and value expected");
      }

      const value = Number.isInteger(Number(segment.value))
        ? Number(segment.value)
        : segment.value;

      return {
        [segment.matching]: [{ var: segment.segmentType }, value],
      };
    }
    case SegmentType.USER_STARTS_APP:
    case SegmentType.USER_APPLIED_CONFIG_BUNDLE:
    case SegmentType.USER_READY_FOR_CONFIG_BUNDLE:
    case SegmentType.USER_RESUMED_APP_ON_WEB_PAGE:
    case SegmentType.USER_STARTS_RESUMES_APP:
    case SegmentType.USER_ENTERED_START_PAGE_CLASSIC_NEWSFEED:
    case SegmentType.USER_ENTERED_START_PAGE: {
      return { "==": [{ var: segment.segmentType }] };
    }
    default: {
      assertNever(segment.segmentType);
    }
  }
}

function orExprToJsonLogic(
  orExpr: Array<ExprItem>
): JsonLogicSegment | JsonLogicOr {
  const orItems = [];
  for (const item of orExpr) {
    if (item.type === ExprOperatorType.AND) {
      throw new Error("Unsupported AND expression.");
    } else if (item.type === ExprOperatorType.OR) {
      // Skip OR operator.
      continue;
    } else if (item.type === ExprSegmentType) {
      orItems.push(exprSegmentToJsonLogic(item));
    } else {
      assertNever(item.type);
    }
  }

  if (orItems.length === 1) {
    return orItems[0];
  }

  return {
    or: orItems,
  };
}

function andExprToJsonLogic(
  groupedByAndExpr: Array<Expr>
): JsonLogicAnd | JsonLogicSegment {
  // Array of OR expressions.
  const andSegments: JsonLogicSegment[] = [];

  for (const orGroup of groupedByAndExpr) {
    const jsonLogic = orExprToJsonLogic(orGroup);
    if (isJsonLogicOr(jsonLogic)) {
      throw new Error(
        "Unexpected AND EXPR with nested ORs - this is not yet supported."
      );
    }
    andSegments.push(jsonLogic);
  }

  if (andSegments.length === 1) {
    return andSegments[0];
  }

  return { and: andSegments };
}

export function convertExprToJsonLogic(expr: Expr): JsonLogic {
  if (expr.length === 0) {
    return {};
  }

  if (expr.length === 1) {
    const [item] = expr;
    if (item.type !== ExprSegmentType) {
      throw new Error(
        `Unexpected Expr with one item - item.type should be ${ExprSegmentType}`
      );
    }
    return exprSegmentToJsonLogic(item);
  }

  const groupedExpr = splitByOperator(expr, ExprOperatorType.AND);
  if (groupedExpr.length === 0) {
    return {};
  }

  if (groupedExpr.length === 1) {
    return orExprToJsonLogic(groupedExpr[0]);
  }

  return andExprToJsonLogic(groupedExpr);
}

function convertJsonLogicAndToExpr(jsonLogic: JsonLogicAnd): Expr {
  const andValues = jsonLogic.and;
  const expr: Expr = [];
  for (const andValue of andValues) {
    const andValueExpr = convertJsonLogicToExpr(andValue);
    if (andValueExpr.length !== 1) {
      throw new Error(`Unsupported jsonLogic: ${jsonLogic}`);
    }
    expr.push(andValueExpr[0]);
    expr.push({ type: ExprOperatorType.AND });
  }

  return expr.slice(0, -1); // Drop trailing AND.
}

function convertJsonLogicOrToExpr(jsonLogic: JsonLogicOr): Expr {
  const orValues = jsonLogic["or"]; // Add strict typings.
  const expr: Expr = [];
  for (const andValue of orValues) {
    const orValueExpr = convertJsonLogicToExpr(andValue);
    if (orValueExpr.length !== 1) {
      throw new Error(`Unsupported jsonLogic: ${jsonLogic}`);
    }
    expr.push(orValueExpr[0]);
    expr.push({ type: ExprOperatorType.OR });
  }

  return expr.slice(0, -1); // Drop trailing OR.
}

function convertJsonLogicEqualToExpr(jsonLogic: JsonLogic): Expr {
  if (!("==" in jsonLogic)) {
    throw new Error(`Unable to convert jsonLogic without '=='`);
  }

  const segment = jsonLogic["=="];
  if (segment.length !== 1) {
    throw new Error(`Unsupported jsonLogic: ${jsonLogic}`);
  }

  return [
    {
      type: ExprSegmentType,
      segmentType: segment[0].var,
    },
  ];
}

function convertJsonLogicVarToExpr(jsonLogic: JsonLogic): Expr {
  const entries = Object.entries(jsonLogic);
  if (entries.length !== 1) {
    throw new Error(`Unsupported jsonLogic: ${jsonLogic}`);
  }

  const [[key, value]] = entries;

  if (value.length !== 2) {
    throw new Error(`Unsupported jsonLogic: ${jsonLogic}`);
  }

  if (!("var" in value[0])) {
    throw new Error(`Unsupported jsonLogic: ${jsonLogic}`);
  }

  const valueVar = value[0]["var"];
  const valueValue = value[1];

  if (valueVar === SegmentType.LIMIT_FREQUENCY) {
    return [
      {
        type: ExprSegmentType,
        segmentType: valueVar,
        value: valueValue,
        // TODO(PNS-1994): Add save casting.
        matching: key as SelectTypePerFrequency,
      },
    ];
  }

  throw new Error(`Unsupported jsonLogic: ${jsonLogic}`);
}

export function convertJsonLogicToExpr(jsonLogic: JsonLogic): Expr {
  if (Object.keys(jsonLogic).length === 0) {
    return [];
  } else if (isJsonLogicAnd(jsonLogic)) {
    return convertJsonLogicAndToExpr(jsonLogic);
  } else if (isJsonLogicOr(jsonLogic)) {
    return convertJsonLogicOrToExpr(jsonLogic);
  } else if ("==" in jsonLogic) {
    return convertJsonLogicEqualToExpr(jsonLogic);
  } else {
    return convertJsonLogicVarToExpr(jsonLogic);
  }
}
