import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "in-app" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  class: "text",
  "data-test": "ia.startpagecard.message"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "buttons-container" }
const _hoisted_6 = {
  class: "primary-button",
  "data-test": "ia.preview.startpagecard.primaryText"
}

import { ImageMetadata } from "@/api/image/typedefs";
import inAppPreviewBackgroundUrl from "@/assets/images/inapp-startpagecard.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewStartPageCard',
  props: {
    message: {},
    image: {},
    primaryText: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message), 1),
          (_ctx.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "image",
                src: _ctx.image.url
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.primaryText), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})