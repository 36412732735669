import { SegmentConfig } from "./segmentConfig";
import { SegmentInputConfig } from "./segmentInputConfig";
import { ExprSegmentError } from "./typedefs";

function segmentValidateNumber(
  inputConfig: SegmentInputConfig,
  rawValue: unknown
): ExprSegmentError[] {
  const value = Number(rawValue);

  if (rawValue === "") {
    return ["Value cannot be empty."];
  }

  if (!Number.isInteger(value)) {
    return ["Value must be an integer."];
  }

  if (inputConfig.limiterMin !== undefined) {
    if (value < inputConfig.limiterMin) {
      return [`Value must be >= ${inputConfig.limiterMin}`];
    }
  }

  return [];
}

export function segmentValidate(
  config: SegmentConfig,
  rawValue: unknown
): ExprSegmentError[] {
  const inputConfig = config.inputConfig;
  if (inputConfig === undefined) {
    return [];
  }

  if (inputConfig.inputType === "text") {
    return [];
  } else if (inputConfig.inputType === "number") {
    return segmentValidateNumber(inputConfig, rawValue);
  }

  return [];
}
