import * as t from "io-ts";

import {
  SegmentType,
  SegmentTypeRawGuard,
} from "@/components/shared/expression/segmentType";
import { httpClient } from "@/lib/http";
import { cast } from "@/lib/typing";

export async function fetchInAppSegmentTypes(
  productLine: number,
  segmentTypesKind: "trigger" | "limit"
): Promise<SegmentType[]> {
  const response = await httpClient.get(
    `/api/inapps/available-${segmentTypesKind}-segments/${productLine}`
  );

  const data = await response.json();

  const segmentTypesRaw = cast(t.array(SegmentTypeRawGuard), data.results);

  return segmentTypesRaw.map((item) => item.id);
}
