import { assign, createMachine } from "xstate";

import { InAppTarget } from "@/api/inapps/typedefs/inApp";
import { ValidationStatus } from "@/api/target/typedefs";
import { castExists } from "@/lib/casts";

type InAppTargetLoadEvent = {
  type: "LOAD_TARGET";
  target: InAppTarget;
};

type InAppTargetClearEvent = {
  type: "CLEAR_TARGET";
};

type InAppValidationRequestEvent = {
  type: "REQUEST_VALIDATION";
};

type InAppTargetFlowEvent =
  | InAppTargetLoadEvent
  | InAppTargetClearEvent
  | InAppValidationRequestEvent;

type InAppTargetContext = {
  target: InAppTarget | null;
  errorMessage: string | null;
};

type NoInAppTargetContext = {
  target: null;
  errorMessage: string | null;
};

type InAppTargetSelectedContext = {
  target: InAppTarget;
  errorMessage: string | null;
};

type InAppTargetState =
  | { value: "loading"; context: InAppTargetContext }
  | { value: "notSelected"; context: NoInAppTargetContext }
  | { value: "validated"; context: InAppTargetSelectedContext }
  | { value: "notValidated"; context: InAppTargetSelectedContext }
  | { value: "validating.inProgress"; context: InAppTargetSelectedContext }
  | { value: "validating.success"; context: InAppTargetSelectedContext }
  | { value: "validating.failure"; context: InAppTargetSelectedContext };

export type InAppTargetMachineStates = InAppTargetState["value"];

export type InAppTargetFlowMachine = ReturnType<
  typeof useInAppTargetFlowMachine
>["inAppTargetFlowMachine"];

interface UseInAppTargetFlowMachineOptions {
  validateTargetCallback: (target: InAppTarget) => Promise<InAppTarget>;
}

export const useInAppTargetFlowMachine = (
  options: UseInAppTargetFlowMachineOptions
) => {
  const inAppTargetFlowMachine = createMachine<
    InAppTargetContext,
    InAppTargetFlowEvent,
    InAppTargetState
  >({
    initial: "notSelected",
    id: "InAppTargetFlow",
    context: () => ({
      errorMessage: null,
      target: null,
    }),
    states: {
      loading: {
        id: "loading",
        always: [
          {
            target: "#validated",
            cond: (context) =>
              context.target?.status === ValidationStatus.VALIDATED,
          },
          {
            target: "#validating.inProgress",
            cond: (context) =>
              context.target?.status === ValidationStatus.VALIDATING,
          },
          {
            target: "notValidated",
            cond: (context) =>
              context.target?.status === ValidationStatus.NOT_VALIDATED ||
              context.target?.status === null,
          },
          {
            target: "notSelected",
            cond: (context) => context.target === null,
          },
        ],
      },
      notSelected: {
        id: "notSelected",
      },
      validating: {
        id: "validating",
        states: {
          inProgress: {
            invoke: {
              src: (context) =>
                options.validateTargetCallback(castExists(context.target)),
              onDone: {
                target: "#validating.success",
                actions: assign({ target: (context, event) => event.data }),
              },
              onError: {
                target: "#validating.failure",
                actions: assign({
                  errorMessage: (context, event) => event.data.message,
                }),
              },
            },
          },
          success: { always: [{ target: "#loading" }] },
          failure: { always: [{ target: "#loading" }] },
        },
      },
      notValidated: {
        id: "notValidated",
        on: {
          REQUEST_VALIDATION: {
            target: "#validating.inProgress",
          },
        },
      },
      validated: {
        id: "validated",
      },
    },
    on: {
      LOAD_TARGET: {
        target: "loading",
        actions: [
          assign({
            target: (event, context) => context.target,
          }),
        ],
      },
      CLEAR_TARGET: {
        target: "loading",
        actions: [
          assign<InAppTargetContext, InAppTargetClearEvent>({
            target: () => null,
          }),
        ],
      },
    },
  });
  return {
    inAppTargetFlowMachine,
  };
};
