import { computed, ComputedRef, Ref, ref, watch } from "vue";

import { SubscriptionOfferData } from "@/lib/inapps/inAppDataTypes";
import { ReadonlyRef } from "@/lib/typing";

export interface SubscriptionOffersOpts {
  offers: ReadonlyRef<SubscriptionOfferData[]>;
  isDisabled: ReadonlyRef<boolean>;
  isRequired: ReadonlyRef<boolean>;
  inAppEndDate: ReadonlyRef<Date | null>;
  onOffersChange: (arg: SubscriptionOfferData[]) => void;
}

export function useSubscriptionOffers(opts: SubscriptionOffersOpts) {
  const currentOffer: Ref<SubscriptionOfferData | null> = ref(null);
  const currentOfferIndex: Ref<number | null> = ref(null);

  function addOffer() {
    currentOffer.value = {
      id: "",
      productId: "",
      expirationTime: "",
      isPersonalized: false,
    };
    currentOfferIndex.value = null;
  }

  function editOffer(index: number) {
    currentOfferIndex.value = index;
    currentOffer.value = opts.offers.value[index];
  }

  function deleteOffer(index: number) {
    opts.onOffersChange(
      opts.offers.value
        .slice(0, index)
        .concat(opts.offers.value.slice(index + 1))
    );
  }

  function onCancel() {
    currentOffer.value = null;
    currentOfferIndex.value = null;
  }

  function onSubmit(value: SubscriptionOfferData) {
    currentOffer.value = value;
    if (currentOffer.value === null) {
      return;
    }
    if (currentOfferIndex.value === null) {
      opts.onOffersChange([...opts.offers.value, currentOffer.value]);
    } else {
      const updatedOffers = [...opts.offers.value];
      updatedOffers[currentOfferIndex.value] = currentOffer.value;
      opts.onOffersChange(updatedOffers);
    }
    currentOffer.value = null;
  }

  const error: Ref<string | null> = ref(null);

  const smallestOfferExpirationTime: ComputedRef<Date | null> = computed(() => {
    if (!opts.offers.value || opts.offers.value.length === 0) {
      return null;
    }

    return opts.offers.value.reduce(
      (minDate: Date, offer: SubscriptionOfferData) => {
        const offerDate = new Date(offer.expirationTime);
        return offerDate < minDate ? offerDate : minDate;
      },
      new Date(opts.offers.value[0].expirationTime)
    );
  });

  function validate(): boolean {
    resetValidation();
    if (opts.offers.value.length === 0 && opts.isRequired.value) {
      error.value = "Must contain at least 1 offer";
      return false;
    }
    if (
      smallestOfferExpirationTime.value &&
      opts.inAppEndDate.value &&
      opts.inAppEndDate.value > smallestOfferExpirationTime.value
    ) {
      error.value = "Offer expiration times must be bigger than end time";
      return false;
    }
    return true;
  }

  function resetValidation() {
    error.value = null;
  }

  watch(
    [opts.offers],
    () => {
      validate();
    },
    { deep: true }
  );

  return {
    addOffer,
    editOffer,
    deleteOffer,
    onCancel,
    onSubmit,
    validate,
    resetValidation,
    currentOffer,
    currentOfferIndex,
    error,
  };
}
