import { ref, watch } from "vue";

import { ExistingInApp, NewInApp } from "@/api/inapps";
import { getErrorMessage } from "@/lib/errors";
import { QuasarNotify } from "@/lib/quasar";
import { ReadonlyRef } from "@/lib/typing";

interface useInAppCloningOptions {
  productLineId: ReadonlyRef<number | null>;
  cloneFromProductLineId: ReadonlyRef<number | null>;
  cloneFromInAppId: ReadonlyRef<number | null>;
  onFetchInAppCallback: (
    productLineId: number,
    inAppId: number
  ) => Promise<ExistingInApp>;
  onLoadNewInApp: (inApp: NewInApp) => void;
  $q: QuasarNotify;
}

export function useInAppCloning({
  productLineId,
  cloneFromInAppId,
  cloneFromProductLineId,
  onFetchInAppCallback,
  onLoadNewInApp,
  $q,
}: useInAppCloningOptions) {
  const isLoading = ref(false);

  watch(
    [cloneFromInAppId, cloneFromProductLineId],
    async () => {
      if (
        cloneFromInAppId.value === null ||
        cloneFromProductLineId.value === null
      ) {
        return;
      }

      let existingInApp: ExistingInApp;

      isLoading.value = true;
      try {
        existingInApp = await onFetchInAppCallback(
          cloneFromProductLineId.value,
          cloneFromInAppId.value
        );
      } catch (error) {
        $q.notify({
          type: "negative",
          message: getErrorMessage(error),
        });
        throw error;
      } finally {
        isLoading.value = false;
      }

      const clonedFromId = existingInApp.id;

      const target =
        cloneFromProductLineId.value === productLineId.value
          ? existingInApp.target
          : null;

      const newInAppCloned: NewInApp = {
        name: `${existingInApp.name} (cloned from InApp with ID ${clonedFromId})`,
        isRuntime: existingInApp.isRuntime,
        forceNonRuntime: existingInApp.forceNonRuntime,
        inAppType: existingInApp.inAppType,
        priority: existingInApp.priority,
        startTime: existingInApp.startTime,
        endTime: existingInApp.endTime,
        inAppData: existingInApp.inAppData,
        displayWhen: existingInApp.displayWhen,
        campaignId: null,
        target,
        tags: existingInApp.tags,
        rolloutStrategyType: existingInApp.rolloutStrategyType,
        rolloutStrategyWindowSize: existingInApp.rolloutStrategyWindowSize,
      };

      onLoadNewInApp(newInAppCloned);
    },
    { immediate: true }
  );

  return { isLoading };
}
