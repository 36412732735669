import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-app-webview-panel-data" }

import { computed } from "vue";

import { InAppDataWebviewPanel } from "@/api/inapps/typedefs/inAppData";
import { bindPropToRef } from "@/lib/propToRef";
import { isValidHttpsUrl } from "@/lib/rules/urlValidator";

import { buildInAppDataInputRules } from "./dataRules";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppDataWebviewPanel',
  props: {
    modelValue: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const PANEL_SIZE_OPTIONS = ["S", "M", "L"];

const props = __props;
const emit = __emit;

function onInAppUpdate(changedData: Partial<InAppDataWebviewPanel>) {
  emit("update:model-value", { ...props.modelValue, ...changedData });
}
const inAppLocal = bindPropToRef<InAppDataWebviewPanel>(
  () => props.modelValue,
  onInAppUpdate
);

const panelSizeRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [(val: unknown) => val !== null || "Panel size must be set"]
);

const urlRules = buildInAppDataInputRules(
  computed(() => props.modelValue.langCode),
  [(val: string | null) => (val && val.length > 0) || "URL is required"],
  [(val: string | null) => isValidHttpsUrl(val)]
);

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
      default: _withCtx(() => [
        _cache[2] || (_cache[2] = _createElementVNode("div", {
          "data-test": "ia.webviewpaneldata.header",
          class: "text-h6 q-mb-sm"
        }, " Webview Panel data ", -1)),
        _createVNode(_component_q_input, {
          modelValue: _unref(inAppLocal).url,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(inAppLocal).url) = $event)),
          filled: "",
          label: "Message URL",
          hint: "URL of your message",
          "data-test": "ia.webviewpaneldata.url",
          "lazy-rules": "",
          rules: _unref(urlRules),
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "rules", "disable"]),
        _createVNode(_component_q_select, {
          modelValue: _unref(inAppLocal).panelSize,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(inAppLocal).panelSize) = $event)),
          options: PANEL_SIZE_OPTIONS,
          filled: "",
          label: "Panel size",
          hint: "Size of your webview panel",
          "data-test": "ia.webviewpaneldata.panelsize",
          rules: _unref(panelSizeRules),
          disable: _ctx.isDisabled
        }, null, 8, ["modelValue", "rules", "disable"])
      ]),
      _: 1
    })
  ]))
}
}

})