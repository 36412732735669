import script from "./InAppLocalizationList.vue?vue&type=script&lang=ts&setup=true"
export * from "./InAppLocalizationList.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QList from 'quasar/src/components/item/QList.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpinnerTail from 'quasar/src/components/spinner/QSpinnerTail.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QList,QInfiniteScroll,QItem,QItemSection,QItemLabel,QSpinnerTail});
