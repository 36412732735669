import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from "vue";

import { SegmentMatchingConfig } from "./segmentMatchingConfig";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpressionSegmentMatching',
  props: {
    config: {},
    modelValue: {},
    isDisabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const options = computed(() => {
  return props.config.matchingOptions;
});

const onUpdate = (value: unknown) => emit("update:modelValue", String(value));

const optionLabel = (option: string): string =>
  props.config.optionToLabels[option] ?? "";

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    "model-value": props.modelValue,
    dense: "",
    borderless: "",
    options: options.value,
    "option-label": optionLabel,
    disable: _ctx.isDisabled,
    "onUpdate:modelValue": onUpdate
  }, null, 8, ["model-value", "options", "disable"]))
}
}

})