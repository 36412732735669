import { TargetUnifiedApi } from "@/api/target/targetUnifiedApi";
import { FetchMultipleTargetFunction } from "@/components/shared/target/typedefs";
import { httpClient } from "@/lib/http";

const targetApi = new TargetUnifiedApi(
  httpClient,
  (productLine: number, targetId: number) =>
    `/api/inapps/product-lines/${productLine}/inapp-targets/${targetId}/`,
  (productLine: number) => `/inapps/target-list/${productLine}`
);

export const fetchInAppTarget: typeof targetApi.fetchSingleTarget = (...args) =>
  targetApi.fetchSingleTarget(...args);

export const fetchInAppTargets: FetchMultipleTargetFunction = (...args) =>
  targetApi.fetchMultipleTargets(...args, false);
