import { computed } from "vue";

import { InAppData } from "@/api/inapps/typedefs/inApp";
import { Localization } from "@/api/localizations/typedefs";
import { ShallowReadonlyRef } from "@/lib/typing";

function isDefaultLocalization(localization: string): boolean {
  return localization.toLocaleLowerCase() !== "default";
}

interface UseInAppDataLocalizationOptions {
  inAppDatas: ShallowReadonlyRef<InAppData[]>;
}

export function useInAppDataLocalization({
  inAppDatas,
}: UseInAppDataLocalizationOptions) {
  const alreadyUsedLocalizations = computed<Localization[]>(() => {
    return inAppDatas.value.map((inAppData) => {
      return { id: inAppData.langCode, text: inAppData.langName };
    });
  });

  return {
    isDefaultLocalization,
    alreadyUsedLocalizations,
  };
}
