import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "in-app" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = {
  class: "title",
  "data-test": "ia.preview.title"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "message",
  "data-test": "ia.preview.message"
}
const _hoisted_7 = { class: "buttons" }

import { ImageMetadata } from "@/api/image/typedefs";
import { LottieMetadata } from "@/api/lottie/typedefs";
import inAppPreviewBackgroundUrl from "@/assets/images/inapp-preview-background.png";
import VisualPreview from "@/components/shared/visualpreview/VisualPreview.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InAppVisualPreviewCenterDialog',
  props: {
    title: {},
    message: {},
    primaryText: {},
    secondaryText: {},
    badgeImage: {},
    centerImage: {},
    lottie: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "background-url": _unref(inAppPreviewBackgroundUrl),
    "is-expandable": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(props.title), 1),
          (props.badgeImage)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                "data-test": "ia.preview.badgeimage",
                class: "image badge-image",
                src: props.badgeImage.url
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        (props.lottie || props.centerImage)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "image center-image",
              "data-test": "ia.preview.centerimage",
              src: props.lottie?.url ?? props.centerImage?.url
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, _toDisplayString(props.message), 1),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", null, _toDisplayString(_ctx.secondaryText), 1),
          _createElementVNode("button", null, _toDisplayString(_ctx.primaryText), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})