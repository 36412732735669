/**
 * This function is stable with respect to firstList ordering.
 */
export function listsIntersection<T>(firstList: T[], secondList: T[]): T[] {
  const secondAsSet = new Set(secondList);

  const intersectionList: T[] = [];
  for (const item of firstList) {
    if (secondAsSet.has(item)) {
      intersectionList.push(item);
    }
  }

  return intersectionList;
}
